export const campCardActivities = [
  { title: "Radfahren", name: "cycle" },
  { title: "Schwimmen", name: "swim" },
  { title: "Schwimmbad", source: "schwimmbad", name: "poolswim", alt: "Schwimmbad" },
  { title: "Wandern", name: "hiking", alt: "Wandern" },
  { title: "Angein", name: "fishing", alt: "Angeln" },
  { title: "Golf", name: "golf", alt: "Golf" },
  { title: "Tennis", name: "tennis", alt: "Tennis" },
  { title: "Strand", source: "strand", name: "beach", alt: "Strand" },
  { title: "Ski", name: "snowski", alt: "Ski" },
  { title: "Schneewanderung", name: "snowhike", alt: "Langlauf" },
  { title: "Klettern", name: "climbing", alt: "Klettern" },
  { title: "Zahnradbahn", source: "zahn", name: "railway", alt: "Zahnradbahn" },
  { title: "Schiffahrt", source: "schiff", name: "ship", alt: "Schiffahrt" },
  { name: "br" },
  { title: "Aussichtspunkt", source: "aussicht", name: "view", alt: "Aussichtspunkt" },
  { title: "Burg / Festung", source: "burg", name: "castle", alt: "Burg / Festung" },
  { title: "Historische Gebäude", source: "hist", name: "history", alt: "Historische Gebäude" },
  { title: "Museum", name: "museum", alt: "Museum" },
  { title: "Kirche", source: "kirche", name: "church", alt: "Kirche" },
  { title: "Moschee", source: "moschee", name: "mosque", alt: "Moschee" },
  { title: "Synagoge", name: "synagoge", alt: "Synagoge" },
  { title: "Fußgängerzone", name: "zone", alt: "Fußgängerzone" },
  { title: "Einkaufszentrum", source: "einkauf", name: "shop", alt: "Einkaufszentrum" },
  { title: "Kino / Thater", source: "kino", name: "movietheater", alt: "Kino / Thater" },
  { title: "Zoo", name: "zoo", alt: "Zoo" },
  { title: "Stadion / Arena", name: "stadion", alt: "Stadion / Arena" },
];

export const campCardServices = [
  {
    name: "electricity",
    title: "Strom",
  },
  {
    name: "water",
    title: "Wasser",
  },
  {
    name: "shower",
    title: "Dusche",
  },
  {
    name: "toilet",
    title: "WC",
  },
  {
    name: "handicappedAccessible",
    title: "Behindertengerecht",
    source: "wheelchair",
  },
  {
    name: "playground",
    title: "Kinderspielplatz",
  },
  {
    name: "chemistryWC",
    title: "Chemie-WC",
    source: "chemistrywc",
  },
  {
    name: "automat",
    title: "Chemie-WC Automat",
  },
  {
    name: "disposal",
    title: "Bodenentsorgung",
  },
  {
    name: "rubbish",
    title: "Müll",
  },
  {
    name: "wifi",
    title: "WLAN",
  },
  {
    name: "laundry",
    title: "Wasch-Trockenmöglichkeit",
    source: "machine",
  },
  {
    name: "pets",
    title: "Hund",
  },
];
