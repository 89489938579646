import React from "react";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
  CardActions,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useVehicle } from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      padding: 16,
    },
  })
);

export const VehicleForm = ({ saveVehicles, firstName, lastName }) => {
  const classes = useStyles();

  const { vehicles, setVehicles, savedVehicle, trigger, setTrigger, setVehicleForm, setCard1, prev } =
    useVehicle();

  const handleChangeVehicle = (e: any) => {
    vehicles[e.target.id] = e.target.value;
    setTrigger(!trigger);
  };

  const closeVehicles = () => {
    setVehicleForm(false);
    setCard1(1);
    setVehicles(prev);
  };

  return (
    <Card style={{ marginTop: 18 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"numberplate"}
                defaultValue={vehicles.numberplate}
                onChange={handleChangeVehicle}
                variant="outlined"
                label="Kennzeichen"
                required
                helperText={vehicles.numberplate === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.numberplate === "" && savedVehicle === true ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"lengthInMM"}
                defaultValue={vehicles.lengthInMM}
                onChange={handleChangeVehicle}
                variant="outlined"
                label="Länge in Meter"
                required
                helperText={vehicles.lengthInMM === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.lengthInMM === "" && savedVehicle === true ? true : false}
                type="number"
                onInput={(e: any) => {
                  e.target.value = e.target.value.includes("-")
                    ? ""
                    : e.target.value === "0"
                    ? ""
                    : e.target.value.includes(".")
                    ? e.target.value.indexOf(".") === 2
                      ? e.target.value.toString().slice(0, 5)
                      : e.target.value.toString().slice(0, 4)
                    : Number(parseFloat(e.target.value).toString().slice(0, 2)) < 20
                    ? e.target.value.toString().slice(0, 2)
                    : e.target.value.toString().slice(0, 1);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"vehicleOwner"}
                defaultValue={
                  vehicles.vehicleOwner === "" ? firstName + " " + lastName : vehicles.vehicleOwner
                }
                helperText={vehicles.vehicleOwner === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.vehicleOwner === "" && savedVehicle === true ? true : false}
                onChange={handleChangeVehicle}
                variant="outlined"
                label="Fahrzeughalter"
                required
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button onClick={saveVehicles} variant="contained" disableElevation color="primary">
          Fahrzeug Speichern
        </Button>
        <Button onClick={closeVehicles} variant="contained" disableElevation>
          Abbrechen
        </Button>
      </CardActions>
    </Card>
  );
};
