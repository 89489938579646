// Ausstattung
export const facilitiesArray = [
  { name: "electricity", title: "Strom", alt: "Strom" },
  { name: "water", title: "Wasser", alt: "Wasser" },
  { name: "shower", title: "Dusche", alt: "Dusche" },
  { name: "toilet", title: "WC", alt: "WC" },
  { name: "handicappedAccessible", title: "Behindertengerecht", source: "wheelchair", alt: "Barrierefrei" },
  { name: "playground", title: "Kinderspielplatz", alt: "Kinderspielplatz" },
  { name: "chemistryWC", title: "Chemie-WC", source: "chemistrywc", alt: "Entsorgung" },
  { name: "automat", title: "Chemie-WC Automat", alt: "Entsorgungsautomat" },
  { name: "disposal", title: "Bodenentsorgung", alt: "Bodenentsorgung" },
  { name: "rubbish", title: "Müll", alt: "Müllentsorgung" },
  { name: "wifi", title: "WLAN", alt: "WLAN" },
  { name: "laundry", title: "Wasch-Trockenmöglichkeit", source: "machine", alt: "Wasch-Trockenmöglichkeit" },
  { name: "pets", title: "Hund", alt: "Hunde Erlaubt" },
];

// Aktivitäten
export const activitiesArray = [
  { title: "Radfahren", name: "cycle", alt: "Radfahren" },
  { title: "Schwimmen", name: "swim", alt: "Schwimmen" },
  { title: "Schwimmen", name: "schwimmbad", alt: "Schwimmen" },
  { title: "Wandern", source: "hiking", name: "schwimmbad", alt: "Wandern" },
  { title: "Angein", name: "fishing", alt: "Angeln" },
  { title: "Golf", name: "golf", alt: "Golf" },
  { title: "Tennis", name: "tennis", alt: "Tennis" },
  { title: "Strand", name: "strand", alt: "Strand" },
  { title: "Ski", name: "snowski", alt: "Ski" },
  { title: "Schneewanderung", name: "snowhike", alt: "Langlauf" },
  { title: "Klettern", name: "climbing", alt: "Klettern" },
  { title: "Zahnradbahn", name: "zahn", alt: "Zahnradbahn" },
  { title: "Schiffahrt", name: "schiff", alt: "Schiffahrt" },
];

// Sehenswürdigkeiten
export const showplacesArray = [
  { title: "Aussichtspunkt", name: "aussicht", alt: "Aussichtspunkt" },
  { title: "Burg / Festung", name: "burg", alt: "Burg / Festung" },
  { title: "Historische Gebäude", name: "hist", alt: "Historische Gebäude" },
  { title: "Museum", name: "museum", alt: "Museum" },
  { title: "Kirche", name: "kirche", alt: "Kirche" },
  { title: "Moschee", name: "moschee", alt: "Moschee" },
  { title: "Synagoge", name: "synagoge", alt: "Synagoge" },
  { title: "Fußgängerzone", name: "zone", alt: "Fußgängerzone" },
  { title: "Einkaufszentrum", name: "einkaufen", alt: "Einkaufszentrum" },
  { title: "Kino / Thater", name: "kino", alt: "Kino / Thater" },
  { title: "Zoo", name: "zoo", alt: "Zoo" },
  { title: "Stadion / Arena", name: "stadion", alt: "Stadion / Arena" },
];
