import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useVehicle } from "context";
const wv = "/assets/images/wv.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    media: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
    },
  })
);

export const PendantVehicleCard = ({ openPendantVehicleForm, saveVehicles }) => {
  const classes = useStyles();

  const { card1, card2, prev, vehicles } = useVehicle();

  const removePendantVehicle = () => {
    vehicles.pendant.numberplate = "";
    vehicles.pendant.lengthInMM = "";

    saveVehicles();
  };

  return (
    <Card className={card1 === 5 ? classes.disabledDiv : ""} elevation={card2}>
      <div className={classes.details}>
        <CardMedia className={classes.media} image={wv} title="Pendant" />
        <CardContent className={classes.content}>
          <Typography className={classes.title} color="primary" gutterBottom>
            {prev?.pendant.numberplate}
          </Typography>
          <Typography className={classes.description} color="primary" gutterBottom>
            {prev?.pendant.lengthInMM}
          </Typography>
          <Grid container spacing={2} className={card2 === 5 ? classes.disabledDiv : ""}>
            <Grid item md={6}>
              <Button
                onClick={openPendantVehicleForm}
                size="small"
                variant="contained"
                disableElevation
                color="primary"
                style={{ width: "100%" }}
              >
                Bearbeiten
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                onClick={removePendantVehicle}
                size="small"
                variant="contained"
                disableElevation
                style={{ width: "100%" }}
              >
                Löschen
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </Card>
  );
};
