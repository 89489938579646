import { SvgIcon } from "@material-ui/core";

export const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="googleIconA"
        d="M19.64,10.26a11.69,11.69,0,0,0-.16-1.9H10v3.77h5.41a4.62,4.62,0,0,1-2,3v2.51h3.23A9.81,9.81,0,0,0,19.64,10.26Z"
      />
      <path
        className="googleIconB"
        d="M10,20.06a9.54,9.54,0,0,0,6.63-2.43l-3.23-2.51a6,6,0,0,1-9-3.17H1.08v2.58A10,10,0,0,0,10,20.06Z"
      />
      <path
        className="googleIconC"
        d="M4.41,12A5.86,5.86,0,0,1,4.09,10a6,6,0,0,1,.32-1.91V5.53H1.08a9.91,9.91,0,0,0,0,9Z"
      />
      <path
        className="googleIconD"
        d="M10,4a5.44,5.44,0,0,1,3.85,1.51l2.86-2.86A9.57,9.57,0,0,0,10,0,10,10,0,0,0,1.08,5.53L4.41,8.12A6,6,0,0,1,10,4Z"
      />
    </SvgIcon>
  );
};
