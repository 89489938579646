import React from "react";
import { Link } from "react-router-dom";
import { Card, CardActionArea, CardContent, List, ListItemText } from "@material-ui/core";

export const MapCard = (item: any) => {
  return (
    <Card style={{ marginBottom: 18 }} elevation={0}>
      <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.parkingArealId}` }}>
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <List dense={true}>
            <ListItemText primary="Stellplätze" secondary={item.parkingArealName} />
            <ListItemText primary="Ausgewählter Parkplatz" secondary={item.parkingSpotName} />
            <ListItemText
              primary="Buchungsdatum"
              secondary={`${new Date(item.startDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })} - ${new Date(item.endDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}`}
            />
            <ListItemText
              primary="Adresse"
              secondary={
                item.address &&
                `${item.address.streetAndHouseNumber} ${item.address.areaCode} / ${item.address.area}`
              }
            />
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
