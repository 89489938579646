import { Grid, IconButton, Button } from "@material-ui/core";
import { vehicleSizes } from "./DemoDummyData";

export const DemoBillPage: React.FC<any> = ({
  setActivePage,
  selectedArea,
  selectedSize,
  amountOfDays,
  numberplate,
}) => {
  const totalValue = parseFloat(
    (vehicleSizes?.[selectedSize]?.priceValue * amountOfDays).toFixed(2)
  );
  const netValue = ((totalValue / 119) * 100).toFixed(2);

  const getDate = (customDate) => {
    const today = customDate || new Date();
    const yyyy = today.getFullYear();
    let mm = (today.getMonth() + 1).toString();
    let dd = today.getDate().toString();

    if (Number(dd) < 10) {
      dd = "0" + dd;
    }
    if (Number(mm) < 10) {
      mm = "0" + mm;
    }

    const formattedToday = dd + "." + mm + "." + yyyy;
    return formattedToday;
  };

  function addDays(days) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return getDate(result);
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        marginTop: "30px",
        textAlign: "left",
        fontSize: "20px",
        fontWeight: 500,
      }}
    >
      <Grid item>
        <h3 style={{ marginBottom: "-5px" }}>Buchungsinformation:</h3>
        <p>camp24 - Singener Strabe 25</p>
        <p>78239 rielasingen-Worblingen</p>

        <p style={{ paddingTop: "20px" }}>
          {"StellPlatznummer: " + selectedArea?.item?.name}
        </p>
        <p>{"Kennzeichen: " + numberplate} </p>
        <p>{"Buchungsdatum: " + getDate(false)}</p>

        <p style={{ paddingTop: "30px" }}>
          {"Datum: " + getDate(false) + " - " + addDays(amountOfDays)}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <b>{amountOfDays + " Tage"}</b>
          <b>{vehicleSizes?.[selectedSize]?.price + " €"}</b>
          <b>{totalValue.toFixed(2).replace(".", ",") + " €"}</b>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "50px",
          }}
        >
          <div>Netto: </div>
          <div>{netValue.replace(".", ",") + " €"}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "50px",
          }}
        >
          <div>MwSt: 19%</div>
          <div>
            {(Number(netValue) * 0.19).toFixed(2).replace(".", ",") + " €"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "50px",
          }}
        >
          <div>Gesamtbetrag:</div>
          <div>{totalValue.toFixed(2).replace(".", ",") + " €"}</div>
        </div>
      </Grid>

      <Button
        onClick={() => setActivePage(5)}
        style={{
          marginTop: "60px",
          width: "100px",
          height: "98px",
          backgroundSize: "contain",
          backgroundImage: "url(/assets/images/paypal-logo.png)",
        }}
      ></Button>

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "30px",
          marginBottom: "20px",
          justifyContent: "space-around",
        }}
      >
        <IconButton aria-label="next" onClick={() => setActivePage(3)}>
          <img
            alt="Back Button"
            src="/assets/images/BackButton.svg"
            height="50px"
            width="50px"
          />
        </IconButton>
        <IconButton aria-label="next" onClick={() => setActivePage(5)}>
          <img
            alt="Next Button"
            src="/assets/images/NextButton.svg"
            height="50px"
            width="50px"
          />
        </IconButton>
      </div>
    </Grid>
  );
};
