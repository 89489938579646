import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { IUploadUserImageParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useUploadUserImage = (options?: UseMutationOptions<any, any, IUploadUserImageParams>) => {
  const { uploadApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: IUploadUserImageParams) =>
      uploadApiService.createCancellableRequest((cancelToken) =>
        uploadApiService.uploadUserImage(payload, cancelToken)
      ),
    [uploadApiService]
  );

  const mutation = useMutation<any, any, IUploadUserImageParams>(mutationFn, options);

  return mutation;
};
