import React, { useState, useContext } from "react";
import { FirebaseAuth } from "../../services/firebase/Firebase";
import { GoogleMap, Marker, InfoWindow, Circle } from "@react-google-maps/api";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { AppBarMain } from "components/AppBar";
import {
  useUserRole,
  useUserFilters,
  useUserOwn,
  useUpdateUserFilters,
  usePublicSearch,
  usePrivateSearch,
} from "rest";
import { AuthContext } from "../../context/Auth";
import { useTranslation, withTranslation } from "react-i18next";
import "moment/locale/de";
import { useHistory } from "react-router-dom";
import { localStorageConstants } from "constants/localStorage";
import { Cookies } from "components";
import { Filters, SearchForm, CampCard } from "components/Home";
import { useHomeState } from "context/Home";

import {
  makeStyles,
  Switch,
  Theme,
  createStyles,
  useMediaQuery,
  CssBaseline,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      height: 510,
      margin: "64px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    searchMobile: {
      minHeight: 340,
      margin: "64px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    mainTitle: {
      marginTop: 0,
      marginBottom: 40,
      fontSize: 44,
      color: "#fff",
    },
    mainTitleMobile: {
      marginTop: 0,
      marginBottom: 30,
      fontSize: 22,
      color: "#fff",
    },
    content: {
      marginBottom: 24,
    },

    switchContainer: {
      margin: "0px -8px",
      position: "absolute",
      right: 24,
      bottom: 18,
      display: "flex",
    },
    switchContainerMobile: {
      width: "100%",
      position: "absolute",
      left: 0,
      bottom: 24,
      display: "flex",
      justifyContent: "center",
    },
    switch: {
      margin: "0px 8px",
      paddingLeft: 18,
      backgroundColor: "#fff",
      borderRadius: 8,
    },
  })
);

function MapImp() {
  const { setAddress, isFiltersOpen, setFiltersOpen, getStartDate, getEndDate, infoOpen, setInfoOpen } =
    useHomeState();

  const [mapRef, setMapRef] = useState();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const { t } = useTranslation();
  const [centerlng, setCenterlng] = useState(17.3547264);
  const [centerlat, setCenterlat] = useState(38.1681664);
  const [zoom, setZoom] = useState(5);

  //newStuff
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:959px)");
  // const [getFocusedInput, setFocusedInput] = useState<any>(null);
  const [getParkingData, setParkingData] = useState<any>([]);
  const [getLocation, setLocation] = useState<any>(null);
  const [getViewBox, setViewBox] = useState<any>(null);
  const [isMapOpen, setMapOpen] = useState<any>(false);
  const [moveBox, setMoveBox] = useState<any>(false);
  const [own, setOwn] = useState<any>(false);
  const [getFilters, setFilters] = useState<any>({
    electricity: false,
    chemistryWC: false,
    disposal: false,
    toilet: false,
    rubbish: false,
    shower: false,
    wifi: false,
    pets: false,
    flushIt: false,
    playground: false,
    handicappedAccessible: false,
    water: false,
    laundry: false,
  });
  const [searchParams, setSearchParams] = useState<any>({});
  //usin for filters
  const myStateRef = React.useRef(getFilters);
  const setMyState = (data: any) => {
    myStateRef.current = data;
    setFilters(data);
  };

  const [sort, setSort] = useState<any>("DIS");

  const { currentUser } = useContext<any>(AuthContext);
  const history = useHistory();

  const { refetch: getPrivateSearch } = usePrivateSearch(
    {
      lowerLon: getViewBox !== null ? getViewBox[0] : undefined,
      lowerLat: getViewBox !== null ? getViewBox[1] : undefined,
      upperLon: getViewBox !== null ? getViewBox[2] : undefined,
      upperLat: getViewBox !== null ? getViewBox[3] : undefined,
      limit: 500,
      startDate: getStartDate,
      endDate: getEndDate,
      ...getFilters,
    },
    {
      enabled: false,
    }
  );

  const { refetch: getPrivateSearch2 } = usePrivateSearch(searchParams, {
    enabled: false,
  });

  const { refetch: getPublicSearch } = usePublicSearch(
    {
      lowerLon: getViewBox !== null ? getViewBox[0] : undefined,
      lowerLat: getViewBox !== null ? getViewBox[1] : undefined,
      upperLon: getViewBox !== null ? getViewBox[2] : undefined,
      upperLat: getViewBox !== null ? getViewBox[3] : undefined,
      limit: 500,
      ...getFilters,
    },
    {
      enabled: false,
    }
  );

  const { mutate: updateUserFilters } = useUpdateUserFilters();

  const { refetch: getUserOwn } = useUserOwn({
    enabled: false,
    onSuccess: (data) => {
      let check = data.vehicle_valid && data.address_valid && data.person_valid;
      setOwn(check);
    },
  });

  const { refetch: getUserFilters } = useUserFilters({
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      setFilters(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { refetch: getUserRole } = useUserRole({
    retry: false,
    onSuccess: () => {
      getUserFilters();
      getUserOwn();
    },
    onError: (error) => {
      FirebaseAuth.signOut()
        .then((_) => console.log("user failed"))
        .catch((reason) => console.log(reason));
      console.info(error);
    },
  });

  React.useEffect(() => {
    if (currentUser != null) {
      if (currentUser.emailVerified === false) {
        history.push("/user/verify");
      } else {
        getUserRole();
      }
    }

    const storedStateTemp: any = localStorage.getItem(localStorageConstants.parkingAreal.SearchLocalStorage);

    const storedStateFilterTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage
    );
    const storedStateAddressTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage
    );

    if (storedStateTemp) {
      setCenterlat(JSON.parse(storedStateTemp).centerLat);
      setCenterlng(JSON.parse(storedStateTemp).centerLng);
      setLocation({ lat: JSON.parse(storedStateTemp).centerLat, lng: JSON.parse(storedStateTemp).centerLng });
      if (storedStateFilterTemp) {
        setMyState(JSON.parse(storedStateFilterTemp).filters);
      }
      if (storedStateAddressTemp) {
        setAddress(JSON.parse(storedStateAddressTemp).address);
      }
    } else {
      getPositionOf();
    }
  }, []);

  const distance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344;
    return dist.toFixed(2);
  };

  const getBoundingBox = (centerPoint: any, distance: any) => {
    let MIN_LAT,
      MAX_LAT,
      MIN_LON,
      MAX_LON,
      R,
      radDist,
      degLat,
      degLon,
      radLat,
      radLon,
      minLat,
      maxLat,
      minLon,
      maxLon,
      deltaLon;
    if (distance < 0) {
      return "Illegal arguments";
    }

    MIN_LAT = -90 * (Math.PI / 180);
    MAX_LAT = 90 * (Math.PI / 180);
    MIN_LON = -180 * (Math.PI / 180);
    MAX_LON = 180 * (Math.PI / 180);
    R = 6378.1;
    radDist = distance / R;
    degLat = centerPoint[0];
    degLon = centerPoint[1];
    radLat = degLat * (Math.PI / 180);
    radLon = degLon * (Math.PI / 180);
    minLat = radLat - radDist;
    maxLat = radLat + radDist;
    minLon = void 0;
    maxLon = void 0;
    deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
    if (minLat > MIN_LAT && maxLat < MAX_LAT) {
      minLon = radLon - deltaLon;
      maxLon = radLon + deltaLon;
      if (minLon < MIN_LON) {
        minLon = minLon + 2 * Math.PI;
      }
      if (maxLon > MAX_LON) {
        maxLon = maxLon - 2 * Math.PI;
      }
    } else {
      minLat = Math.max(minLat, MIN_LAT);
      maxLat = Math.min(maxLat, MAX_LAT);
      minLon = MIN_LON;
      maxLon = MAX_LON;
    }
    return [
      (180 * minLon) / Math.PI,
      (180 * minLat) / Math.PI,
      (180 * maxLon) / Math.PI,
      (180 * maxLat) / Math.PI,
    ];
  };

  const handleAddressChange = (address: any) => {
    setAddress(address);
  };

  const handleFilterChange = (event: any) => {
    if (!event.target.checked) {
      const { [event.target.name]: tmp, ...newFilters } = getFilters;
      setMyState({ ...getFilters, [event.target.name]: event.target.checked });
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchFilterLocalStorage,
        JSON.stringify({
          filters: newFilters,
        })
      );
    } else {
      setMyState({ ...getFilters, [event.target.name]: event.target.checked });
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchFilterLocalStorage,
        JSON.stringify({
          filters: { ...getFilters, [event.target.name]: event.target.checked },
        })
      );
    }
  };

  const saveFilters = () => {
    updateUserFilters(getFilters);
  };

  const resetFilters = () => {
    const filtering = {
      electricity: false,
      chemistryWC: false,
      disposal: false,
      toilet: false,
      rubbish: false,
      shower: false,
      wifi: false,
      pets: false,
      flushIt: false,
      playground: false,
      handicappedAccessible: false,
      water: false,
      laundry: false,
    };
    setFilters(filtering);
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage,
      JSON.stringify({
        filters: filtering,
      })
    );
  };

  const handleSortChange = (event: any) => {
    setSort(event.target.value);
    switch (event.target.value) {
      case "ASC":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return first.price - second.price;
          })
        );
        break;
      case "DSC":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return second.price - first.price;
          })
        );
        break;
      case "DIS":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return first.distance - second.distance;
          })
        );
    }
  };

  const handleAddressSelect = (address: any) => {
    setAddress(address);
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage,
      JSON.stringify({
        address: address,
      })
    );
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLocation(latLng);
        setViewBox(getBoundingBox([latLng.lat, latLng.lng], 25));
        localStorage.setItem(
          localStorageConstants.parkingAreal.SearchLocationLocalStorage,
          JSON.stringify({
            location: getBoundingBox([latLng.lat, latLng.lng], 25),
          })
        );
      })
      .catch((error) => console.error(error));
  };

  const openMapAndCard = (event: any, item: any) => {
    setMapOpen(true);
    setMoveBox(true);
    setTimeout(function () {
      setSelectedPlace(item);
      setInfoOpen(true);
      setZoom(10);
    }, 1250);
  };

  const openMap = (event: any) => {
    setMapOpen(event.target.checked);
  };

  const openFilters = (event: any) => {
    setFiltersOpen(event.target.checked);
    const storedStateFilterTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage
    );
    if (storedStateFilterTemp !== null) {
      //@ts-ignore
      setMyState(JSON.parse(storedStateFilterTemp).filters);
      //@ts-ignore
      console.log(storedStateFilterTemp);
    }
  };

  const getData = () => {
    const storedLocationTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchLocationLocalStorage
    );

    if (getViewBox !== null) {
      if (currentUser) {
        getPrivateSearch()
          .then((response) => {
            console.log("Response :", response);
            let result = response.data.map((doc: any) => {
              let item = Object.assign({}, doc);
              item.distance = distance(
                item.location.lat,
                item.location.lon,
                getLocation.lat,
                getLocation.lng
              );
              setCenterlat(getLocation.lat);
              setCenterlng(getLocation.lng);
              return item;
            });

            setParkingData(
              result.sort((first: any, second: any) => {
                return first.distance - second.distance;
              })
            );
            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocalStorage,
              JSON.stringify({
                parkingSpots: result.sort((first: any, second: any) => {
                  return first.distance - second.distance;
                }),
                centerLat: getLocation.lat,
                centerLng: getLocation.lng,
              })
            );
          })
          .catch((error) => console.error(error));
      } else {
        getPublicSearch()
          .then((response) => {
            let result = response.data.map((doc: any) => {
              let item = Object.assign({}, doc);
              item.distance = distance(
                item.location.lat,
                item.location.lon,
                getLocation.lat,
                getLocation.lng
              );
              setCenterlat(getLocation.lat);
              setCenterlng(getLocation.lng);
              return item;
            });

            setParkingData(
              result.sort((first: any, second: any) => {
                return first.distance - second.distance;
              })
            );

            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocalStorage,
              JSON.stringify({
                parkingSpots: result.sort((first: any, second: any) => {
                  return first.distance - second.distance;
                }),
                centerLat: getLocation.lat,
                centerLng: getLocation.lng,
              })
            );
          })
          .catch((error) => console.error(error));
      }
    } else {
      if (storedLocationTemp) {
        setViewBox(JSON.parse(storedLocationTemp).location);
      }
    }
  };

  const mapGetData = (mapRef: any) => {
    // @ts-ignore
    const lt = mapRef.getCenter().lat();
    // @ts-ignore
    const ln = mapRef.getCenter().lng();

    setLocation({ lat: lt, lng: ln });
    setCenterlng(ln);
    setCenterlat(lt);

    let x = getBoundingBox([lt, ln], 25);

    if (currentUser) {
      setSearchParams({
        lowerLon: x[0],
        lowerLat: x[1],
        upperLon: x[2],
        upperLat: x[3],
        startDate: getStartDate,
        endDate: getEndDate,
        limit: 200,
        ...myStateRef.current,
      });
      getPrivateSearch2()
        .then((response) => {
          let result = response.data.map((doc: any) => {
            let item = Object.assign({}, doc);
            item.distance = distance(item.location.lat, item.location.lon, lt, ln);

            return item;
          });

          setParkingData(
            result.sort((first: any, second: any) => {
              return first.distance - second.distance;
            })
          );
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchLocalStorage,
            JSON.stringify({
              parkingSpots: result.sort((first: any, second: any) => {
                return first.distance - second.distance;
              }),
              centerLat: lt,
              centerLng: ln,
            })
          );
          setAddress("");
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchLocationLocalStorage,
            JSON.stringify({
              location: x,
            })
          );
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchAddressLocalStorage,
            JSON.stringify({
              address: "",
            })
          );
        })
        .catch((error) => console.error(error));
    } else {
      getPublicSearch()
        .then((response) => {
          let result = response.data.map((doc: any) => {
            let item = Object.assign({}, doc);
            item.distance = distance(item.location.lat, item.location.lon, lt, ln);
            return item;
          });
          setParkingData(
            result.sort((first: any, second: any) => {
              return first.distance - second.distance;
            })
          );
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchLocalStorage,
            JSON.stringify({
              parkingSpots: result.sort((first: any, second: any) => {
                return first.distance - second.distance;
              }),
              centerLat: lt,
              centerLng: ln,
            })
          );
          setAddress("");
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchLocationLocalStorage,
            JSON.stringify({
              location: x,
            })
          );
          localStorage.setItem(
            localStorageConstants.parkingAreal.SearchAddressLocalStorage,
            JSON.stringify({
              address: "",
            })
          );
        })
        .catch((error) => console.error(error));
    }
  };

  const getPosition = (position: any) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    if (lat && lng) {
      setLocation({ lat, lng });

      setViewBox(getBoundingBox([lat, lng], 25));

      setCenterlat(lat);
      setCenterlng(lng);
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchLocationLocalStorage,
        JSON.stringify({
          location: getBoundingBox([lat, lng], 25),
        })
      );
    }
  };

  React.useEffect(() => {
    getData();
  }, [getViewBox]);

  const positionError = (error: any) => {
    console.log(error);
  };

  const geolocationOptions = {
    enableHighAccuracy: true,
  };

  const getPositionOf = () => {
    setAddress("");
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage,
      JSON.stringify({
        address: "",
      })
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, positionError, geolocationOptions);
    }
  };

  const centerChanged = (mapRef: any) => {
    if (moveBox === false) {
      //@ts-ignore
      setCenterlat(mapRef.getCenter().toJSON().lat);
      //@ts-ignore
      setCenterlng(mapRef.getCenter().toJSON().lng);
    }
  };

  const loadHandler = (map: any) => {
    setMapRef(map);
    setZoom(10);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker: any, item: any) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [item.id]: marker };
    });
  };

  const markerClickHandler = (event: any, item: any) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    if (zoom < 13) {
      setZoom(10);
    }
  };
  //options for cirle
  const options = {
    strokeColor: "#34ff1a",
    strokeOpacity: 0.3,
    strokeWeight: 2,
    fillColor: "#34ff1a",
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 34000,
    zIndex: 1,
  };

  const renderMap = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBarMain />
        <Cookies />
        <div className={matches ? classes.search : classes.searchMobile}>
          <Container>
            <h1 className={matches ? classes.mainTitle : classes.mainTitleMobile}>
              Starten Sie hier Ihre Reiseplanung
            </h1>
            <SearchForm
              handleAddressChange={handleAddressChange}
              handleAddressSelect={handleAddressSelect}
              getPositionOf={getPositionOf}
              isFiltersOpen={isFiltersOpen}
              getData={getData}
              own={own}
            />
            {!!isFiltersOpen && (
              <Filters
                getFilters={getFilters}
                handleFilterChange={handleFilterChange}
                currentUser={currentUser}
                resetFilters={resetFilters}
                saveFilters={saveFilters}
              />
            )}
            <div className={matches ? classes.switchContainer : classes.switchContainerMobile}>
              <div className={classes.switch}>
                <FormControlLabel
                  control={<Switch checked={isFiltersOpen} onChange={openFilters} color="primary" />}
                  label={t("label_show_filters")}
                />
              </div>
              <div className={classes.switch}>
                <FormControlLabel
                  control={<Switch checked={isMapOpen} onChange={openMap} color="primary" />}
                  label={t("label_show_map")}
                />
              </div>
            </div>
          </Container>
        </div>
        <Container maxWidth={false} className={classes.content}>
          {isMapOpen ? (
            <GoogleMap
              // Do stuff on map initial load
              onLoad={loadHandler}
              // Save the current center position in state
              onCenterChanged={() => {
                centerChanged(mapRef);
              }}
              // Save the user's map click position
              //@ts-ignore
              onClick={(e) => {
                console.log("clickedLatLng: ", e.latLng.toJSON());
              }}
              center={{ lat: centerlat, lng: centerlng }}
              onDragStart={() => setMoveBox(false)}
              onDragEnd={() => {
                mapGetData(mapRef);
              }}
              zoom={zoom}
              mapContainerStyle={{
                height: "70vh",
                width: "100%",
              }}
            >
              {mapRef && (
                <Circle
                  //@ts-ignore
                  center={
                    moveBox === false
                      ? //@ts-ignore
                        { lat: mapRef.getCenter().toJSON().lat, lng: mapRef.getCenter().toJSON().lng }
                      : { lat: centerlat, lng: centerlng }
                  }
                  // required
                  options={options}
                  radius={1000}
                />
              )}
              {mapRef && (
                <Marker
                  icon="/assets/images/marker/marker4.png"
                  position={
                    moveBox === false
                      ? //@ts-ignore
                        { lat: mapRef.getCenter().toJSON().lat, lng: mapRef.getCenter().toJSON().lng }
                      : { lat: centerlat, lng: centerlng }
                  }
                />
              )}
              {getParkingData &&
                getParkingData.map((item: any, index: number) => (
                  <Marker
                    key={item.id}
                    position={{ lat: parseFloat(item.location.lat), lng: parseFloat(item.location.lon) }}
                    onLoad={(marker) => markerLoadHandler(marker, item)}
                    onClick={(event) => {
                      markerClickHandler(event, item);
                      setMoveBox(true);
                    }}
                    icon="/assets/images/marker/marker.png"
                  />
                ))}
              {infoOpen && selectedPlace && (
                <InfoWindow
                  //@ts-ignore
                  anchor={markerMap[selectedPlace.id]}
                  onCloseClick={() => setInfoOpen(false)}
                >
                  <div>
                    <CampCard item={selectedPlace} openMapAndCard={openMapAndCard} />
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={5} lg={2} xl={1}>
                <InputLabel id="demo-simple-select-label" shrink>
                  {t("label_sort_by")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sort}
                  onChange={handleSortChange}
                  style={{ display: "block" }}
                >
                  <MenuItem value={"ASC"}>{t("label_price_ascending")}</MenuItem>
                  <MenuItem value={"DSC"}>{t("label_price_descending")}</MenuItem>
                  <MenuItem value={"DIS"}>{t("label_sort_distance")}</MenuItem>
                </Select>
              </Grid>
              <Grid item sm={6} md={7} lg={10} xl={11} />
              {getParkingData.map((item: any, index: number) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CampCard item={item} openMapAndCard={openMapAndCard} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </React.Fragment>
    );
  };

  return renderMap();
}

export const Map = withTranslation()(MapImp);
