import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { IUpdateVerifySync } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useUpdateVerifySync = (options?: UseMutationOptions<any, any, IUpdateVerifySync>) => {
  const { userApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: IUpdateVerifySync) =>
      userApiService.createCancellableRequest((cancelToken) =>
        userApiService.updateVerifySync(payload, cancelToken)
      ),
    [userApiService]
  );

  const mutation = useMutation<any, any, IUpdateVerifySync>(mutationFn, options);

  return mutation;
};
