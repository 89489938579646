import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  FormControl,
  Button,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { vehicleSizes } from "./DemoDummyData";

export const PhoneVerificationPage: React.FC<any> = ({
  setActivePage,
  selectedSize,
  amountOfDays,
  selectedArea,
  validatedSmsPhone,
  setValidatedSmsPhone,
  numberplate,
  setNumberplate,
}) => {
  const [phoneCode, setPhoneCode] = useState<string>("+49");
  const [phone, setPhone] = useState<any>();
  const [verificationCode, setVerificationCode] = useState<any>();
  const [isWaitSmsCode, setIsWaitSmsCode] = useState<any>();

  const totalValue = vehicleSizes?.[selectedSize]?.priceValue * amountOfDays;

  useEffect(() => {
    if (validatedSmsPhone) {
      setPhoneCode(validatedSmsPhone.phoneCode);
      setPhone(validatedSmsPhone.phone);
    }
  }, [phone]);

  const handleChangePhone = (e: any, value: any) => {
    let phone = e.split(" ").join("");
    const count = value.dialCode.length;
    let phoneNumber = phone.substring(count + 1);
    let phoneCode = value.dialCode;
    if (phone[count + 1] === "0") {
      setPhone(phoneNumber);
    } else {
      setPhone(phoneNumber);
      setPhoneCode("+" + phoneCode);
    }
  };

  const handleSmsVerification = () => {
    setIsWaitSmsCode(true);
  };

  const handleSmsValidation = () => {
    setValidatedSmsPhone({ phoneCode, phone });
    setActivePage(4);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "70px" }}
    >
      <Grid item>
        <TextField
          InputLabelProps={{
            style: { fontSize: "24px", fontWeight: 900 },
          }}
          InputProps={{ style: { fontSize: 26, marginBottom: "15px" } }}
          fullWidth={true}
          value={selectedArea?.item?.name}
          label={"Stellplatznummer"}
          margin="normal"
          variant="outlined"
          id={"PitchNumber"}
          disabled
        />
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            style: { fontSize: "24px", fontWeight: 900 },
          }}
          InputProps={{ style: { fontSize: 26 } }}
          fullWidth={true}
          value={totalValue?.toFixed(2).replace(".", ",") + " €"}
          label={"Stellplatzgebühr"}
          margin="normal"
          variant="outlined"
          id={"Days"}
          disabled
        />
      </Grid>
      <Grid item>
        <div
          style={{
            color: "#0b429c",
            marginBottom: "35px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {"Strom, Wasser, Dusche, etx... werden extra berechnet."}
        </div>
      </Grid>
      <Grid item>
        <TextField
          InputLabelProps={{
            style: { fontSize: "24px", fontWeight: 900 },
          }}
          InputProps={{ style: { fontSize: 26 } }}
          fullWidth={true}
          value={numberplate}
          onChange={(e) => setNumberplate(e.target.value)}
          label={"Kennzeichen"}
          margin="normal"
          variant="outlined"
          id={"Mark"}
        />
      </Grid>
      <Grid item>
        <div
          style={{
            color: "#0b429c",
            marginBottom: "55px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {"Erkennung für Check-in und Check-out."}
        </div>
      </Grid>
      <Grid item>
        <FormControl fullWidth variant="outlined">
          <MuiPhoneNumber
            id={"phone"}
            InputLabelProps={{
              style: { fontSize: "24px", fontWeight: 900 },
            }}
            InputProps={{ style: { fontSize: 23, marginBottom: "15px" } }}
            value={phoneCode + phone}
            maxLength={20}
            onChange={handleChangePhone}
            defaultCountry="de"
            regions={"europe"}
            variant="outlined"
            label="Telefon"
            required
            autoFormat={false}
            countryCodeEditable={false}
          />
        </FormControl>
      </Grid>
      {!isWaitSmsCode && (
        <Grid>
          <Button
            onClick={handleSmsVerification}
            variant="contained"
            color="primary"
            style={{ marginBottom: "15px" }}
            disabled={!phone}
          >
            {validatedSmsPhone
              ? "Telefon ändern & verifizieren"
              : "SMS VERIFIZIEREN"}
          </Button>
        </Grid>
      )}
      {isWaitSmsCode && (
        <Grid item>
          <div
            style={{
              color: "red",
              fontSize: "24px",
              fontWeight: "bold",
              maxWidth: "260px",
              marginTop: "30px",
            }}
          >
            {"Demo Code: 123456"}
          </div>
        </Grid>
      )}
      {isWaitSmsCode && (
        <Grid item>
          <TextField
            InputLabelProps={{
              style: { fontSize: "24px", fontWeight: 900 },
            }}
            InputProps={{ style: { fontSize: 26, marginBottom: "15px" } }}
            fullWidth={true}
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            label={"Verifizlerungscode"}
            margin="normal"
            variant="outlined"
            id={"verificationCode"}
          />
        </Grid>
      )}
      {isWaitSmsCode && (
        <Grid>
          <Button
            onClick={() => handleSmsValidation()}
            variant="contained"
            color="primary"
            style={{ marginBottom: "15px", color: "black", fontWeight: "bold" }}
            disabled={!(verificationCode?.toString() === "123456")}
          >
            {"NOCHMAL SCHICKEN"}
          </Button>
        </Grid>
      )}

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "60px",
          marginBottom: "20px",
          justifyContent: "space-around",
        }}
      >
        <IconButton aria-label="next" onClick={() => setActivePage(2)}>
          <img
            alt="Back Button"
            src="/assets/images/BackButton.svg"
            height="50px"
            width="50px"
          />
        </IconButton>
        <IconButton
          aria-label="next"
          onClick={() => setActivePage(4)}
          style={{
            opacity: verificationCode?.toString() === "123456" ? 1 : 0.5,
          }}
          disabled={!(verificationCode?.toString() === "123456")}
        >
          <img
            alt="Next Button"
            src="/assets/images/NextButton.svg"
            height="50px"
            width="50px"
          />
        </IconButton>
      </div>
    </Grid>
  );
};
