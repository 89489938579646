import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useVehicle } from "context";
import { useDeleteVehicle } from "rest";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    media: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
    },
  })
);

export const VehicleCard = ({ openVehicleForm }) => {
  const classes = useStyles();

  const { card1, card2, prev, setChecked, setSavedVehicle, setCheckedE } = useVehicle();

  const { mutate: deleteVehicle } = useDeleteVehicle({
    onSuccess: () => {
      setChecked(true);
      setSavedVehicle(false);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const removeVehicle = () => {
    deleteVehicle({});
  };

  return (
    <Card elevation={card1} className={card2 === 5 ? classes.disabledDiv : ""}>
      <div className={classes.details}>
        <CardContent className={classes.content} style={{ textAlign: "right" }}>
          <Typography className={classes.title} color="primary" gutterBottom>
            {prev?.numberplate}
          </Typography>
          <Typography className={classes.description} color="primary" gutterBottom>
            {prev?.lengthInMM}
          </Typography>
          <Grid container spacing={2} className={card1 === 5 ? classes.disabledDiv : ""}>
            <Grid item md={6}>
              <Button
                onClick={openVehicleForm}
                size="small"
                variant="contained"
                disableElevation
                color="primary"
                style={{ width: "100%" }}
              >
                Bearbeiten
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                onClick={removeVehicle}
                size="small"
                variant="contained"
                disableElevation
                style={{ width: "100%" }}
              >
                Löschen
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardMedia className={classes.media} image="/assets/images/mt.jpg" title="Vehicle" />
      </div>
    </Card>
  );
};
