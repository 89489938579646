/***
 * Endpoint paths of the admin rest api
 */
export abstract class AdminRestApiPath {
    public static importParkingArealPath = "/importParkingAreal";
    public static createParkingAreal = "/createParkingAreal";
    public static parkingArealsPaginated = "/parkingArealsPaginated";
    public static createTablePath = "/createTable";
    public static updateParkingAreal = "/updateParkingAreal";
    public static updateParkingArealPartial = "/updateParkingArealPartial";
    public static adminUser = "/user";
    public static adminUserCouch = "/usercouch";
    public static adminUserTravellerStatus = "/usercouch/traveller";
    public static adminUserPetStatus = "/usercouch/pet";
    public static adminComment = "/comment";
    public static adminCommentCouch = "/commentCouch";
    public static adminBooking = "/booking";
    public static adminBookingCount = "/booking/count";
    public static adminImage = "/image";
    public static parkingSpot = "/parkingSpot";
    public static machineBarrierCheck = "/machine/barrierCheck";
    public static machineBooking =  "/machine/booking";
    public static uploadSvg =  "/uploadSvg";
    public static deleteSvg =  "/deleteSvg";
    public static role =  "/role";
    public static crmLog =  "/crmLog";
    public static contact =  "/contact";
    public static saler =  "/saler";
    public static crmFile =  "/crmFile";
    public static contactImage =  "/contactImage";
    public static crmSettings =  "/crmSettings";
    public static config =  "/config";
    public static pillars =  "/pillars";
    public static pillarSpots = "/pillars/spots"

    // new booking apis
    public static parkingSpotsSvg = "/booking/spots"
    public static bookingPrice = "/booking/price"
    public static bookingCreate = "/booking/create"

    public static receipt =  "/receipt";
    public static forms =  "/forms";
    public static xmlforms =  "/xmlforms";

    public static point =  "/point";
    public static permanentEntrance = "/permaEntrance";

    public static queue = "/automate/queue/action";
    public static queueOption = "/automate/queue/option";
    public static worker = "/automate/worker";

    public static elasticBulkInsert = "/elastic/bulk";
    public static elasticSpotUpdate = "/elastic/spot";

    //User
    public static user = "/user";
    public static userProfile = "/user/profile";
    public static userPetStatus = "/user/pet";
    public static userRole = "/userRole";
    public static userCategory = "/user/category";
    public static usersListFirebase = "/users/list";
    public static userRevokeFirebase = "/users/revoke";
    public static userCreate = "/user/create";

     //Vehicle
    public static userVehicle = "/user/vehicle";
    public static userPendant = "/user/pendant";
    
    //Traveller
    public static userTraveller = "/user/travellers";
    public static userTravellerId = "/user/travellers/:travellerId";
    public static userTravellerStatus = "/user/traveller";
}