import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-dates/lib/css/_datepicker.css";
import Paper from "@material-ui/core/Paper";
import SVG from "react-inlinesvg";
import { ParkingSpotComponentWeb } from "./ParkingSpotComponent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, IconButton } from "@material-ui/core";
import {
  circlesData,
  circlesDetData,
} from "../ParkingAreaDetail/DemoComponents/DemoDummyData";

export const DateRange = (params: any) => {
  const [spot, setSpot] = React.useState<any>("");
  const [svg, setSvg] = React.useState<any>("");
  const [conflict, setConflict] = React.useState<any>(false);
  const [isAreaSelected, setIsAreaSelected] = React.useState<boolean>(false);

  const handleCircle = (event: any, item: any, index: any) => {
    if (item.bookingState === 0) return null;
    setIsAreaSelected(true);
    let svglist = JSON.parse(JSON.stringify(circlesData));

    const regex = /Ebene_([^-]*)/m;
    // eslint-disable-next-line array-callback-return

    const indId = svglist.findIndex((o) => {
      let temp = o.match(regex);
      if (temp !== null && o.match(regex)[1] === item.name) {
        return o;
      }
    });
    svglist[indId] = svglist[indId].replace(regex, "#ff8106");
    setSvg([...svglist].join(""));
    item.bookingState = 2;
    let spots = JSON.parse(JSON.stringify(circlesDetData));
    spots[index] = item;
    params.setSelectedArea({ item, index });
    setSpot([...spots]);
  };

  useEffect(() => {
    setConflict(false);

    setSpot(JSON.parse(JSON.stringify(circlesDetData)));
    setSvg(JSON.parse(JSON.stringify(circlesData.join(""))));

    if (params?.selectedArea) {
      handleCircle(null, params.selectedArea.item, params.selectedArea.index);
    } else {
    }
  }, []);

  const spotButton = (item: any, index: number) => {
    return (
      <React.Fragment>
        <Card elevation={0}>
          <CardActionArea
            onClick={(event: any) => handleCircle(event, item, index)}
          >
            <CardMedia>
              <ParkingSpotComponentWeb
                type={item.type}
                bookingState={item.bookingState}
                label={item.name}
              />
            </CardMedia>
          </CardActionArea>
        </Card>
      </React.Fragment>
    );
  };

  if (conflict) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={0}>
          <Grid item xs={1} md={3} lg={4} />
          <Grid item xs={9} md={6} lg={4}>
            <Alert severity="warning">
              <AlertTitle>Warnung</AlertTitle>
              Sie haben eine Reservierung zum gewählten Datum.
              <strong>
                {" "}
                Bitte überprüfen Sie die Daten vor der Reservierung{" "}
              </strong>
            </Alert>
          </Grid>
          <Grid item xs={1} md={3} lg={4} />
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <div
        style={{ minHeight: "90vh", display: "flex", flexDirection: "column" }}
      >
        <div>
          <CssBaseline />
          <Grid container spacing={0}>
            <Grid item xs={1} md={3} lg={4} />
            {svg && (
              <Grid item xs={9} md={6} lg={4} style={{ margin: "10px auto" }}>
                <Paper elevation={0}>
                  <SVG src={svg} />
                </Paper>
              </Grid>
            )}
            <Grid item xs={1} md={3} lg={4} />
          </Grid>
          <Grid container spacing={0}>
            {spot &&
              spot.map((item: any, index: number) => (
                <Grid item xs={4} sm={3} md={2} lg={1} key={index}>
                  {spotButton(item, index)}
                </Grid>
              ))}
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            marginTop: "auto",
            alignSelf: "end",
          }}
        >
          <IconButton
            aria-label="next"
            onClick={() => params?.setActivePage(1)}
          >
            <img
              alt="Back Button"
              src="/assets/images/BackButton.svg"
              height="50px"
              width="50px"
            />
          </IconButton>
          <IconButton
            aria-label="next"
            style={{ opacity: isAreaSelected ? 1 : 0.5 }}
            onClick={() => params?.setActivePage(3)}
            disabled={!isAreaSelected}
          >
            <img
              alt="Next Button"
              src="/assets/images/NextButton.svg"
              height="50px"
              width="50px"
            />
          </IconButton>
        </div>
      </div>
    );
  }
};

export default DateRange;
