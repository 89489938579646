import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import { StepIconProps } from "@material-ui/core/StepIcon";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import DateRange from "./DateRange";
import BillTable from "./ParkingSpot";
import { useCreateBooking, useParkingSpotBookingPricesByDate } from "rest";
import SaveIcon from "@material-ui/icons/Save";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EndPage from "./EndPage";
import Grid from "@material-ui/core/Grid";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      zIndex: theme.zIndex.drawer + 105,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    headStepper: {
      padding: "0px !important",
    },
  })
);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, #7be47f 0%, #59ca5d 50%, #4caf50 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, #7be47f 0%, #59ca5d 50%, #4caf50 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, #7be47f 0%, #59ca5d 50%, #4caf50 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, #7be47f 0%, #59ca5d 50%, #4caf50 100%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <RoomIcon />,
    2: <DateRangeIcon />,
    3: <RoomIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const HorizontalLabelPositionBelowStepper2 = (params: any) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [user, setUser] = React.useState<any>("");
  const [parkingAreal, setParkingAreal] = React.useState<any>({});
  const [priceList, setPriceList] = React.useState<any>({});
  const [spotId, setSpotId] = React.useState("");
  const [spotType, setSpotType] = React.useState("");
  const [spotName, setSpotName] = React.useState("");
  const [blocked, setBlocked] = React.useState(false);
  const { t } = useTranslation();
  const steps = getSteps();
  const [timeLeft, setTimeLeft] = React.useState<any>(null);

  function getSteps() {
    return [t("label_booking_select_spot"), t("label_booking_info")];
  }

  const { mutate: userCreateBooking } = useCreateBooking({
    onSuccess: (data) => {
      console.info(data);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    onError: (error) => {
      setBlocked(true);
      console.info(error);
    },
  });

  const { refetch: getParkingSpotBookingPricesByDate } =
    useParkingSpotBookingPricesByDate(
      {
        parkingArealId: parkingAreal._id,
        parkingSpotId: spotId,
        endDate: endDate?.toISOString(),
        startDate: startDate?.toISOString(),
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setPriceList(data);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setTimeLeft(300);
        },
        onError: (error) => {
          setBlocked(true);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          console.log(error);
        },
      }
    );

  useEffect(() => {
    setStartDate(params.startDate);
    setEndDate(params.endDate);
    setUser(params.user);
    setParkingAreal(params.parkAreal);
    setBlocked(false);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      handleClose();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleNext = () => {
    if (activeStep === 0) {
      getParkingSpotBookingPricesByDate();
    } else if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setReady(false);
  };

  const handleSave = () => {
    let book = {
      userId: user._id,
      userVehicle: user.vehicle,
      parkingArealId: parkingAreal._id,
      parkingSpotId: spotId,
      startDate: startDate,
      endDate: endDate,
      PIN: "1234",
      status: "active",
      checkIn: false,
      checkOut: false,
      priceList: priceList.tablePrice,
      total: priceList.totalPrice,
      name: spotName,
      type: spotType,
      hund: "none",
      phoneNumber: "",
    };

    userCreateBooking(book);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <DateRange
            startDate={params.startDate}
            endDate={params.endDate}
            user={params.user}
            parkingAreal={params.parkAreal}
            ready={setReady}
            spotId={setSpotId}
            spotName={setSpotName}
            spotType={setSpotType}
          />
        );
      case 1:
        return (
          <BillTable
            user={params.user}
            ready={setReady}
            priceList={priceList}
            blocked={blocked}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const handleClose = () => {
    params.situation(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={1} sm={3} className={classes.headStepper} />
        <Grid item xs={9} sm={6} className={classes.headStepper}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={1} sm={3} className={classes.headStepper}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClose}
          >
            <CancelPresentationIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <div>
            {activeStep === steps.length ? (
              <div>
                <EndPage situation={params.situation} />
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions} component={"div"}>
                  {getStepContent(activeStep)}
                </Typography>
                <span></span>
                <div>
                  {activeStep !== 0 ? (
                    <Button
                      onClick={handleBack}
                      variant="contained"
                      size="large"
                      color="primary"
                      startIcon={<ArrowBackIcon />}
                      style={{ marginRight: "10px" }}
                    >
                      {t("label_back")}
                    </Button>
                  ) : (
                    ""
                  )}
                  {activeStep === steps.length - 1 ? (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleSave}
                        style={{ marginRight: "10px" }}
                        startIcon={<SaveIcon />}
                      >
                        {t("label_save")}
                      </Button>
                      {!blocked && (
                        <Alert severity="warning" style={{ marginTop: "12px" }}>
                          <AlertTitle>Warnung</AlertTitle>
                          Der Parkplatz ist für 5 Minuten gesperrt. Sie sollten
                          Ihre Buchung in <strong>{timeLeft}</strong> Sekunden
                          abschließen.
                        </Alert>
                      )}
                    </React.Fragment>
                  ) : (
                    <Button
                      disabled={!ready}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={handleNext}
                      style={{ marginRight: "10px" }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {t("label_next")}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HorizontalLabelPositionBelowStepper2;
