import { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import {
  DemoDayAndSizeSelect,
  PhoneVerificationPage,
  DemoBillPage,
  ProcessCompletePage,
} from "../../components/ParkingAreaDetail/DemoComponents";
import { DateRange } from "../../components/Booking/DateRange";

export const Demo: React.FC = () => {
  const [selectedSize, setSelectedSize] = useState<number>();
  const [amountOfDays, setAmountOfDays] = useState<number>();
  const [numberplate, setNumberplate] = useState<string>();
  const [activePage, setActivePage] = useState<number>(1);
  const [selectedArea, setSelectedArea] = useState<any>();
  const [validatedSmsPhone, setValidatedSmsPhone] = useState<any>();

  return (
    <div style={{ marginTop: "56px" }}>
      <div
        style={{
          zIndex: 999999,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          backgroundColor: "#fff",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Grid
          item
          sm={2}
          style={{
            minHeight: "56px",
            width: "100%",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/assets/images/logo.png"
            alt="Camp 24"
            style={{ height: "36px", padding: "5px" }}
          />
        </Grid>
      </div>

      {activePage === 1 && (
        <DemoDayAndSizeSelect
          setActivePage={setActivePage}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          amountOfDays={amountOfDays}
          setAmountOfDays={setAmountOfDays}
        />
      )}
      {activePage === 2 && (
        <DateRange
          setSelectedArea={setSelectedArea}
          selectedArea={selectedArea}
          setActivePage={setActivePage}
        />
      )}
      {activePage === 3 && (
        <PhoneVerificationPage
          setActivePage={setActivePage}
          selectedSize={selectedSize}
          amountOfDays={amountOfDays}
          selectedArea={selectedArea}
          setNumberplate={setNumberplate}
          numberplate={numberplate}
          validatedSmsPhone={validatedSmsPhone}
          setValidatedSmsPhone={setValidatedSmsPhone}
        />
      )}
      {activePage === 4 && (
        <DemoBillPage
          numberplate={numberplate}
          setActivePage={setActivePage}
          selectedSize={selectedSize}
          amountOfDays={amountOfDays}
          selectedArea={selectedArea}
        />
      )}
      {activePage === 5 && (
        <ProcessCompletePage setActivePage={setActivePage} />
      )}
    </div>
  );
};
