import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Container, CssBaseline, Grid, Backdrop, CircularProgress, Fade } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { getUserRole, getUserTravellers, getVehicle } from "rest/AdminRestClient";

import { AppBarMain } from "components/AppBar";
import { Sidebar } from "components";

import {
  Travellers,
  Profile,
  Vehicles,
  Bookmarks,
  Bookings,
  Reviews,
  Stornieren,
  Rechnungen,
} from "pages/authenticated";
import {
  ProfileProvider,
  BookingsProvider,
  BookmarksProvider,
  VehicleProvider,
  TravellersProvider,
} from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 80,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserPagesLayout: React.FC = () => {
  const classes = useStyles();

  const [user, setUser] = useState<any>(null);
  const [fade, setFade] = useState<any>(false);
  const [vehicle, setVehicle] = useState<any>(null);
  const [key, setKey] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getUserRole("")
      .then((results) => {
        setUser(results.data.user);
      })
      .catch((error) => console.error(error));

    getUserTravellers()
      .then((results) => {
        let arrResult: any[] = [];
        results.data.forEach((x: any) => {
          arrResult[x.order - 1] = {
            address: {
              area: x.area,
              areaCode: x.areaCode,
              countryCode: x.countryCode,
              streetAndHouseNumber: x.streetAndHouseNumber,
            },
            person: {
              birthDate: x.birthDate,
              birthPlace: x.birthPlace,
              countryCode: x.countryCode,
              firstName: x.firstName,
              identityCardNo: x.identityCardNo,
              lastName: x.lastName,
              nationality: x.nationality,
            },
            sameAddressAsUser: x.sameAddressAsUser,
            status: x.enabled,
            id: x.id,
          };
        });
        setFade(true);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));

    getVehicle()
      .then((results) => {
        let vehicle = {
          lengthInMM: parseFloat(results.data.lengthInM),
          numberplate: results.data.numberplate,
          vehicleOwner: results.data.owner,
          pendant: {
            lengthInMM: results.data.pendantLengthInM,
            numberplate: results.data.pendantNumberplate,
            isPendantActive: results.data.isPendantActive,
          },
        };
        setVehicle(vehicle);
      })
      .catch((error) => console.error(error));
  }, []);

  const refreshUser = async () => {
    getUserRole("")
      .then((results) => {
        setUser(results.data.user);
      })
      .catch((error) => console.error(error));

    getUserTravellers()
      .then((results) => {
        let arrResult: any[] = [];
        results.data.forEach((x: any) => {
          arrResult[x.order - 1] = {
            address: {
              area: x.area,
              areaCode: x.areaCode,
              countryCode: x.countryCode,
              streetAndHouseNumber: x.streetAndHouseNumber,
            },
            person: {
              birthDate: x.birthDate,
              birthPlace: x.birthPlace,
              countryCode: x.countryCode,
              firstName: x.firstName,
              identityCardNo: x.identityCardNo,
              lastName: x.lastName,
              nationality: x.nationality,
            },
            sameAddressAsUser: x.sameAddressAsUser,
            status: x.enabled,
            id: x.id,
          };
        });
      })
      .catch((error) => console.error(error));
  };

  const refreshUserImage = async () => {
    getUserRole("")
      .then((results) => {
        setUser(results.data.user);
        setKey((prev) => prev + 1);
      })
      .catch((error) => console.error(error));
  };
  if (vehicle != null && user != null && fade) {
    return (
      <Fade in={fade}>
        <>
          <CssBaseline />
          <AppBarMain key={key} />

          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Sidebar user={user} fade={fade} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Route
                  exact
                  path={"/user/profile"}
                  render={(props) => (
                    <ProfileProvider>
                      <Profile {...props} refreshUser={refreshUser} refreshUserImage={refreshUserImage} />
                    </ProfileProvider>
                  )}
                />
                <Route
                  exact
                  path={"/user/travellers"}
                  render={(props) => (
                    <TravellersProvider>
                      <Travellers {...props} refreshUser={refreshUser} />
                    </TravellersProvider>
                  )}
                />
                <Route
                  exact
                  path={"/user/vehicles"}
                  render={(props) => (
                    <VehicleProvider>
                      <Vehicles {...props} refreshUser={refreshUser} vehicle={vehicle} />
                    </VehicleProvider>
                  )}
                />
                <Route
                  exact
                  path={"/user/bookmarks"}
                  render={() => (
                    <BookmarksProvider>
                      <Bookmarks />
                    </BookmarksProvider>
                  )}
                />
                <Route
                  exact
                  path={"/user/bookings"}
                  render={() => (
                    <BookingsProvider>
                      <Bookings />
                    </BookingsProvider>
                  )}
                />
                <Route
                  exact
                  path={"/user/bookings-past"}
                  render={() => (
                    <BookingsProvider>
                      <Bookings bookingPast />
                    </BookingsProvider>
                  )}
                />
                <Route exact path={"/user/reviews"} render={() => <Reviews />} />
                <Route exact path={"/user/stornieren"} render={() => <Stornieren />} />
                <Route exact path={"/user/rechnungen"} render={() => <Rechnungen />} />
              </Grid>
            </Grid>
          </Container>
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const UserPages = withTranslation()(UserPagesLayout);
