import { makeStyles, Theme, createStyles, Button, Grid, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FilterItem } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: "100%",
      color: "#fff",
      backgroundColor: "#7ed321",
      "&:hover": {
        backgroundColor: "#82e226",
      },
      "&:active": {
        backgroundColor: "#82e226",
      },
    },
    filter: {
      width: 900,
      margin: "30px auto 0",
      padding: "0px 11px",
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0px 0px 0px 10px rgba(255, 255, 255, 0.3)",
    },
    filterMobile: {
      width: "100%",
      margin: "30px auto 60px",
      padding: "0px 11px",
      backgroundColor: "#fff",
    },
    filterScroll: {
      height: "160px",
      overflowY: "scroll",
      overflowX: "hidden",
      marginBottom: "20px",
      paddingLeft: "3px",
    },
  })
);

export function Filters({ getFilters, handleFilterChange, currentUser, resetFilters, saveFilters }) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:959px)");
  const { t } = useTranslation();

  const filtersArray = [
    {
      name: "electricity",
      text: "Strom",
    },
    {
      name: "water",
      text: "Wasser",
    },
    {
      name: "shower",
      text: "Dusche",
    },
    {
      name: "toilet",
      text: "WC",
    },
    {
      name: "wheelchair",
      text: "Barrierfrei",
    },
    {
      name: "playground",
      text: "Kinderspielplatz",
    },
    {
      name: "chemistrywc",
      text: "Entsorgung",
    },
    {
      name: "automat",
      text: "Entsorgungsautomat",
    },
    {
      name: "disposal",
      text: "Bodenentsorgung",
    },
    {
      name: "rubbish",
      text: "Müllentsorgung",
    },
    {
      name: "wifi",
      text: "WLAN",
    },
    {
      name: "pets",
      text: "Hunde erlaubt",
    },
    {
      name: "machine",
      text: "Wasch-Trockenmöglichkeit",
    },
  ];

  return (
    <div className={matches ? classes.filter : classes.filterMobile}>
      <div className={classes.filterScroll}>
        <Grid container spacing={0}>
          {filtersArray.map((filter) => (
            <FilterItem
              key={filter.name}
              name={filter.name}
              text={filter.text}
              getFilters={getFilters}
              handleFilterChange={handleFilterChange}
            />
          ))}
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={3} style={{ marginBottom: 11 }}>
          <Button
            variant="contained"
            onClick={resetFilters}
            className={classes.button}
            disableElevation
            fullWidth
          >
            {t("label_reset_filters")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} />
        {currentUser && (
          <Grid item xs={12} md={3} style={{ marginBottom: 11 }}>
            <Button
              variant="contained"
              onClick={saveFilters}
              className={classes.button}
              disableElevation
              fullWidth
            >
              {t("label_save_filters")}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
