import type { IUpdatePetStatusParams } from "./domain";

export const EntityType = {
  USER_PROFILE: "user_profile",
  USER_ROLE: "user_role",
  USER_OWN: "user_own",
  USER_PET_STATUS: "user_pet_status",
  VERIFY_CODE: "verify_code",
};

export const QueryKeys = {
  userProfile: () => [EntityType.USER_PROFILE, "LIST"],
  userRole: () => [EntityType.USER_ROLE, "LIST"],
  userOwn: () => [EntityType.USER_OWN, "DETAIL"],
  userPetStatus: (params: IUpdatePetStatusParams) => [
    EntityType.USER_PET_STATUS,
    "LIST",
    JSON.stringify(params),
  ],
  verifyCode: () => [EntityType.VERIFY_CODE, "DETAIL"],
};
