import { useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  CardActions,
  Button,
  IconButton,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { getDMS } from "helpers";
import ImageGallery from "react-image-gallery";
import {
  StarBorder,
  FavoriteBorder,
  Share,
  Room,
  Explore,
} from "@material-ui/icons";
import {
  facilitiesArray,
  activitiesArray,
  showplacesArray,
} from "./tooltipLists";
import { AuthContext } from "context/Auth";
import { useParkingAreaDetailState } from "context/ParkingAreaDetail";

import useStyles from "./style";

function MainCard({
  images,
  item,
  commentHandle,
  dist,
  openBookmarkForm,
  openCopyDialog,
  lat,
  lng,
  user,
}) {
  const { currentUser }: any = useContext(AuthContext);
  const { totalRating, totalComments } = useParkingAreaDetailState();

  const classes = useStyles();

  return (
    <Card style={{ marginBottom: 18 }}>
      <CardContent>
        <div className={classes.imageGallery} id={"galerie"}>
          <ImageGallery
            items={images}
            showThumbnails={false}
            showFullscreenButton={true}
            showPlayButton={false}
            showBullets={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            align="left"
            variant="h5"
            component="h1"
            color="primary"
            style={{ fontWeight: 600 }}
          >
            {item.name}
          </Typography>
          <Rating
            size="large"
            name="mainItem"
            value={totalRating}
            readOnly
            precision={0.5}
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
        </div>
        <div>
          <Typography
            align="right"
            color="primary"
            style={{ marginBottom: "-12px", cursor: "pointer" }}
            onClick={(event: any) => commentHandle(event)}
          >
            {`${totalComments} Bewertungen`}
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <br />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingBottom: "5px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{ paddingTop: "3px", paddingLeft: "5px" }}
            color="primary"
          >
            Entfernungen:
          </Typography>
        </Grid>
        {item.distance !== undefined ? (
          <Grid
            item
            xs={8}
            sm={4}
            md={4}
            lg={3}
            style={{ display: "flex", marginLeft: "-10px" }}
          >
            <ListItemAvatar style={{ textAlignLast: "center" }}>
              <Explore
                style={{
                  color: "#7ED321",
                  paddingTop: "3px",
                  textAlignLast: "center",
                }}
              />
            </ListItemAvatar>
            <ListItemText primary={"Zielort: " + item.distance + " Km"} />
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          xs={8}
          sm={5}
          md={5}
          lg={6}
          style={{ display: "flex", marginLeft: "-10px" }}
        >
          <ListItemAvatar style={{ textAlignLast: "center" }}>
            <Room style={{ color: "#7ED321", paddingTop: "3px" }} />
          </ListItemAvatar>
          <ListItemText
            primary={"Standort: " + dist(lat, lng, item.lat, item.lon) + " Km"}
          />
        </Grid>
      </Grid>

      <Divider />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingTop: "9px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{
              paddingTop: "10px",
              paddingRight: "12px",
              paddingLeft: "5px",
              maxWidth: "%15",
            }}
            color="primary"
          >
            Ausstatung:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "flex" }}>
          <div className={classes.facilitiesList}>
            {/* map */}
            {facilitiesArray.map((facility, index) => {
              const imgSource = facility.source || facility.name;

              return (
                item.properties?.[facility?.name] && (
                  <Tooltip
                    key={index}
                    title={facility?.title}
                    placement="top"
                    arrow
                  >
                    <img
                      src={`/assets/images/newfacilities/${imgSource}${
                        item.properties?.[facility?.name] ? "-green" : ""
                      }.png`}
                      className={classes.facilities}
                      alt={facility?.alt}
                    />
                  </Tooltip>
                )
              );
            })}
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingTop: "9px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{
              paddingTop: "10px",
              paddingRight: "16px",
              paddingLeft: "5px",
              maxWidth: "%15",
            }}
            color="primary"
          >
            Aktivitäten:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "flex" }}>
          <div className={classes.facilitiesList}>
            {activitiesArray.map((activity, index) => {
              const imgSource = activity.source || activity.name;

              return (
                <Tooltip
                  key={index}
                  title={activity.title}
                  placement="top"
                  arrow
                >
                  <img
                    src={`/assets/images/newfacilities/${imgSource}${
                      item.properties?.[activity.name] ? "-green" : ""
                    }.png`}
                    className={classes.facilities}
                    alt={activity.alt}
                  />
                </Tooltip>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <Divider />

      <Divider />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingTop: "9px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{
              paddingTop: "10px",
              paddingRight: "16px",
              paddingLeft: "5px",
              maxWidth: "%15",
            }}
            color="primary"
          >
            Sehenswürdigkeiten:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "flex" }}>
          <div className={classes.facilitiesList}>
            {/* map */}
            {showplacesArray.map((showplace, index) => {
              const imgSource = showplace.name;

              return (
                <Tooltip
                  key={index}
                  title={showplace.title}
                  placement="top"
                  arrow
                >
                  <img
                    src={`/assets/images/newfacilities/${imgSource}${
                      item.properties?.[showplace.name] ? "-green" : ""
                    }.png`}
                    className={classes.facilities}
                    alt={showplace.alt}
                  />
                </Tooltip>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingTop: "9px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{
              paddingTop: "3px",
              paddingRight: "16px",
              paddingLeft: "5px",
              maxWidth: "%15",
            }}
            color="primary"
          >
            GPS:
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          lg={9}
          style={{ display: "flex", marginLeft: "-12px" }}
        >
          <ListItemAvatar style={{ textAlignLast: "center" }}>
            <Room
              style={{
                color: "#7ED321",
                paddingTop: "3px",
                textAlignLast: "center",
              }}
            />
          </ListItemAvatar>
          {item.GPSA !== "" &&
          item.GPSB !== "" &&
          item.sekunden1 !== "" &&
          item.minute1 !== "" &&
          item.grad1 !== "" &&
          item.sekunden2 !== "" &&
          item.minute2 !== "" &&
          item.grad2 !== "" &&
          item.GPSA !== undefined &&
          item.GPSB !== undefined &&
          item.sekunden1 !== undefined &&
          item.minute1 !== undefined &&
          item.grad1 !== undefined &&
          item.sekunden2 !== undefined &&
          item.minute2 !== undefined &&
          item.grad2 !== undefined ? (
            <ListItemText
              primary={
                item.grad1 +
                "°" +
                item.minute1 +
                "'" +
                item.sekunden1 +
                '"' +
                item.GPSA +
                "  ,  " +
                item.grad2 +
                "°" +
                item.minute2 +
                "'" +
                item.sekunden2 +
                '"' +
                item.GPSB
              }
            />
          ) : (
            <ListItemText
              primary={
                getDMS(item.lat, "lat") + " , " + getDMS(item.lon, "long")
              }
            />
          )}
        </Grid>
      </Grid>
      <Divider id={"comment"} />
      <CardActions className={classes.cardActions}>
        <Button
          onClick={(e) =>
            (window.location.href = `http://maps.google.com/maps?saddr=${lat},${lng}&daddr=${item.lat},${item.lon}`)
          }
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginLeft: "auto" }}
        >
          Navigation Starten
        </Button>
        {currentUser && user && (
          <IconButton onClick={openBookmarkForm} size="small" color="primary">
            <FavoriteBorder />
          </IconButton>
        )}
        <IconButton size="small" color="primary" onClick={openCopyDialog}>
          <Share />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default MainCard;
