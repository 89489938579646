import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  search: {
    height: 350,
    margin: "64px 0px 24px",
    position: "relative",
    textAlign: "center",
  },
  borderBottom: {
    borderBottom: "none",
  },
  borderTop: {
    borderTop: "solid",
  },
});

function ccyFormat(num: number) {
  return `${num.toFixed(2).replace(/\./g, ",")}`;
}

export default function BillTable(params: any) {
  const classes = useStyles();
  console.log("params", params);

  return (
    <>
      <CssBaseline />
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {params.blocked ? (
          <Grid item>
            <Alert severity="error">
              <AlertTitle>Fehler</AlertTitle>
              Der Parkplatz, den Sie zu reservieren versuchen, ist bereits
              reserviert.{" "}
              <strong>
                Bitte kehren Sie zurück und wählen Sie einen anderen Parkplatz.
              </strong>
            </Alert>
          </Grid>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: 100 }} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      size={"small"}
                      align="center"
                      style={{
                        fontSize: "x-large",
                        fontWeight: "bold",
                        borderBottom: "none",
                      }}
                    >
                      Buchungsinformation:
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      size={"small"}
                      align="center"
                      style={{ fontSize: "large", borderBottom: "none" }}
                    >
                      {params.priceList.parkAreal.name +
                        " - " +
                        params.priceList.parkAreal.address
                          .streetAndHouseNumber +
                        " - " +
                        params.priceList.parkAreal.address.areaCode +
                        " " +
                        params.priceList.parkAreal.address.area}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      size={"small"}
                      align="center"
                      style={{
                        fontSize: "x-large",
                        fontWeight: "bold",
                        borderBottom: "none",
                      }}
                    >
                      {`Stellplatznummer: ${params.priceList.spot} - Kennzeichen: ${params.priceList.vehicleNumberPlate}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      size={"small"}
                      align="center"
                      style={{ fontSize: "large" }}
                    >
                      {`Buchungsdatum: ` +
                        ("0" + new Date().getDate()).slice(-2) +
                        "/" +
                        ("0" + (new Date().getMonth() + 1)).slice(-2) +
                        "/" +
                        new Date().getFullYear()}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            <TableContainer component={Paper}>
              <Table style={{ minWidth: 650 }} aria-label="spanning table">
                <TableBody>
                  <TableRow key={"start"} style={{ borderTop: "solid" }}>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Datum
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      weitere Personen
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Kind
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Hund
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Kurtaxe
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Stellplatz
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      Summe
                    </TableCell>
                  </TableRow>
                  {params.priceList.tablePrice.map((row: any, ind: any) => (
                    <TableRow key={ind}>
                      <TableCell align="left" className={classes.borderBottom}>
                        {("0" + new Date(row.date).getDate()).slice(-2) +
                          "." +
                          ("0" + (new Date(row.date).getMonth() + 1)).slice(
                            -2
                          ) +
                          "." +
                          new Date(row.date).getFullYear()}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(parseFloat(row.extraPersonPrice))}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(parseFloat(row.extraChildPrice))}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(parseFloat(row.petPrice))}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(parseFloat(row.visitorTax))}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.price)}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.sum)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow style={{ borderBottom: "ridge" }} />
                  <TableRow
                    style={{ borderBottom: "solid", borderTop: "ridge" }}
                  >
                    <TableCell
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      Gesamtbetrag:
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      colSpan={2}
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    />
                    <TableCell
                      align="right"
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      {ccyFormat(params.priceList.netPrice)}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      7% Mwst
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      {ccyFormat(
                        params.priceList.totalPrice - params.priceList.netPrice
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: "large", fontWeight: "bold" }}
                    >
                      {ccyFormat(params.priceList.totalPrice)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </>
  );
}
