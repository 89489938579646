import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  CssBaseline,
  Typography,
  Grid,
  Card,
  CardActions,
  Button,
  Tooltip,
  Backdrop,
  CircularProgress,
  Fade,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";

import { useVehicle as useGetVehicle, useCreateVehicle, useUpdateVehicle } from "rest";
import { VehicleForm, VehicleCard, PendantVehicleCard, PendantVehicleForm } from "components/user/vehicles";
import { useVehicle } from "context";

const Logo = "/assets/images/info.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    infoLogo: {
      height: 36,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    cardActions: {
      padding: 16,
    },
    media: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    green: {
      color: theme.palette.primary.main,
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    switchFormControl: {
      height: "100%",
      padding: "0 14px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function UserVehicles(props: any) {
  const classes = useStyles();
  const location = useLocation<any>();

  const firstName = location.state.user.person.firstName;
  const lastName = location.state.user.person.lastName;

  const [pendantStatus, setPendantStatus] = useState(false);
  const [exist, setExist] = useState(false);
  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [types, setTypes] = useState({
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
  });

  const {
    vehicles,
    setVehicles,
    checked,
    setChecked,
    checkedE,
    setCheckedE,
    checkedC,
    setCheckedC,
    vehicleForm,
    setVehicleForm,
    pendantVehicleForm,
    setPendantVehicleForm,
    prev,
    setPrev,
    setCard1,
    setCard2,
    savedVehicle,
    setSavedVehicle,
  } = useVehicle();

  useEffect(() => {
    handleImageBackdropOpen();
  }, []);

  useGetVehicle({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      getVehicleHelper(data);
      handleImageBackdropClose();

      setVehicleForm(false);
      setPendantVehicleForm(false);
    },
  });

  const { mutate: createVehicle } = useCreateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const handleImageBackdropClose = () => {
    setOpenImageBackdrop(false);
  };
  const handleImageBackdropOpen = () => {
    setOpenImageBackdrop(true);
  };

  const getVehicleHelper = (data: any) => {
    let vehicle = {
      lengthInMM: data.lengthInM !== "" ? parseFloat(data.lengthInM) : "",
      numberplate: data.numberplate,
      vehicleOwner: data.owner === "" ? firstName + " " + lastName : data.owner,
      isactive: data.hasPendant,
      pendant: {
        lengthInMM: data.hasPendant ? parseFloat(data.pendantLengthInM) : "",
        numberplate: data.hasPendant ? data.pendantNumberplate : "",
        isPendantActive: data.isPendantActive,
      },
    };

    let length = parseFloat(data.lengthInM);
    if (data.isPendantActive && data.hasPendant) {
      length = parseFloat(data.pendantLengthInM) + parseFloat(data.lengthInM);
    }
    setTypes({
      B: length < 6.1 ? true : false,
      C: length < 8.1 ? true : false,
      D: length < 9.1 ? true : false,
      E: length < 10.1 ? true : false,
      F: length <= 20 ? true : false,
    });

    setVehicles(vehicle);
    setPendantStatus(data.hasPendant);
    setExist(data.lengthInM !== "" && data.numberplate !== "" ? true : false);
    setPrev(JSON.parse(JSON.stringify(vehicle)));
  };

  const saveVehicles = () => {
    let first = Object.keys(vehicles).filter((key) => vehicles[key] === "");

    if (first.length > 0) {
      setSavedVehicle(true);
      setCheckedC(true);
      setTimeout(() => {
        setCheckedC(false);
      }, 5000);
    } else {
      let updateVehicleObject = {
        lengthInM: vehicles.lengthInMM.toString(),
        numberplate: vehicles.numberplate.toString(),
        owner: vehicles.vehicleOwner.toString(),
        hasPendant: vehicles.pendant.numberplate === "" ? false : true,
        pendantLengthInM: vehicles.pendant.lengthInMM.toString(),
        pendantNumberplate: vehicles.pendant.numberplate.toString(),
        isPendantActive: vehicles.pendant.isPendantActive,
      };
      setCard1(1);
      setCard2(1);
      if (!exist) {
        createVehicle(updateVehicleObject);
      } else {
        updateVehicle(updateVehicleObject);
      }
    }
  };

  const openVehicleForm = () => {
    setPrev(JSON.parse(JSON.stringify(vehicles)));
    setVehicleForm(true);
    setPendantVehicleForm(false);
    setCard1(5);
  };

  const openPendantVehicleForm = () => {
    setPrev(JSON.parse(JSON.stringify(vehicles)));
    setPendantVehicleForm(true);
    setVehicleForm(false);
    setCard2(5);
  };

  if (vehicles != null && props.vehicle != null) {
    return (
      <Fade in={true}>
        <>
          <CssBaseline />
          <Snackbar open={checked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="success">Ihre Fahrzeuge wurden erfolgreich eingereicht</Alert>
          </Snackbar>
          <Snackbar open={checkedE} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="error">Ihre Fahrzeuge wurden nicht erfolgreich eingereicht</Alert>
          </Snackbar>
          <Snackbar open={checkedC} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="error"> Bitte überprüfen Sie die Pflichtfelder.</Alert>
          </Snackbar>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {exist === true && <VehicleCard openVehicleForm={openVehicleForm} />}
            </Grid>
            <Grid item xs={12} md={6}>
              {pendantStatus === true && prev?.pendant && (
                <PendantVehicleCard
                  openPendantVehicleForm={openPendantVehicleForm}
                  saveVehicles={saveVehicles}
                />
              )}
            </Grid>
          </Grid>
          {vehicleForm && (
            <VehicleForm saveVehicles={saveVehicles} firstName={firstName} lastName={lastName} />
          )}
          {pendantVehicleForm && <PendantVehicleForm savedVehicle={savedVehicle} />}

          {exist === false && vehicleForm === false && (
            <Card style={{ marginTop: 18 }}>
              <CardActions className={classes.cardActions}>
                <Button onClick={openVehicleForm} variant="contained" color="primary">
                  Neues Fahrzeug hinzufügen +
                </Button>
              </CardActions>
            </Card>
          )}

          {pendantStatus === false &&
            pendantVehicleForm === false &&
            vehicleForm === false &&
            exist === true && (
              <Card style={{ marginTop: 18 }}>
                <CardActions className={classes.cardActions}>
                  <Button onClick={openPendantVehicleForm} variant="outlined" color="primary">
                    Anhänger hinzufügen +
                  </Button>
                </CardActions>
              </Card>
            )}

          <br />

          <Grid container spacing={3}>
            <Grid item xs={1}>
              <HtmlTooltip
                title={
                  <>
                    <span>
                      <p>S ca. 5x6 meter 30qm</p>
                      <p>M ca. 5x8 meter 40qm</p>
                      <p>L ca. 5x9 meter 50qm</p>
                      <p>XL ca. 6x10 meter 72qm</p>
                      <p>XXL ca. 7x12 meter 98qm</p>
                    </span>
                  </>
                }
              >
                <img src={Logo} alt="Camp 24" className={classes.infoLogo} />
              </HtmlTooltip>
            </Grid>
            <Grid container item xs={11} alignItems={"center"}>
              <Typography variant={"subtitle1"}>
                Aufgrund ihrer Angaben können Sie einen Stellpatz wählen:
                <span> -</span>
                <span className={types.B === true ? classes.green : undefined}>S</span>
                <span>- </span>
                <span> -</span>
                <span className={types.C === true ? classes.green : undefined}>M</span>
                <span>- </span>
                <span> -</span>
                <span className={types.D === true ? classes.green : undefined}>L</span>
                <span>- </span>
                <span> -</span>
                <span className={types.E === true ? classes.green : undefined}>XL</span>
                <span>- </span>
                <span> -</span>
                <span className={types.F === true ? classes.green : undefined}>XXL</span>
                <span>- </span>
              </Typography>
            </Grid>
          </Grid>
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={openImageBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export const Vehicles = withTranslation()(UserVehicles);
