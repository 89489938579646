import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import Flag from "react-world-flags";
import deLocale from "date-fns/locale/de";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { CountriesDe } from "constants/countries";
import { useUpdateUserProfile, useUploadUserImage } from "rest";
import { useProfile } from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 16,
      fontSize: 14,
    },
    cardActions: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export const UserForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    getProfile,
    setProfile,
    setVerifyCodeStatus,
    setChecked,
    setOpenImageBackdrop,
  } = useProfile();

  const { address, person } = getProfile;

  const [checkedC, setCheckedC] = useState(false);
  const [checkedE, setCheckedE] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { mutate: updateUserProfile } = useUpdateUserProfile({
    onSuccess: (results) => {
      if (results.phoneChanged) {
        setVerifyCodeStatus(true);
      }
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const saveProfile = () => {
    let first = Object.keys(getProfile).filter((key) => getProfile[key] === "");
    let second = Object.keys(person).filter((key) => person[key] === "");
    let third = Object.keys(address).filter((key) => address[key] === "");

    let secondImp = second.filter((e) => e !== "birthDate" || "email");

    if (first.length > 0 || third.length > 0 || secondImp.length > 0) {
      setCheckedC(true);
      setTimeout(() => {
        setCheckedC(false);
      }, 5000);
    } else {
      updateUserProfile({ ...getProfile });
    }
  };

  const { mutate: uploadUserImage } = useUploadUserImage({
    onSuccess: () => {
      setOpenImageBackdrop(false);
      // refreshUserImage().then(() => {
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
      // };
    },
    onError: () => {
      setOpenImageBackdrop(false);
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const fileUpload = (files: FileList | null) => {
    if (files && files.item(0)) {
      //@ts-ignore
      const type = files.item(0).type;
      const arr = ["image/png", "image/jpg", "image/jpeg"];
      //@ts-ignore
      if (!arr.includes(type)) {
        setDialogOpen(true);
        //@ts-ignore
      } else if (files.item(0).size > 10222181) {
        setDialogOpen(true);
      } else {
        setOpenImageBackdrop(true);
        //@ts-ignore
        uploadUserImage(files.item(0));
      }
    }
  };

  const handleChangePerson = (e: any) => {
    setProfile((prev) => ({
      ...prev,
      person: {
        ...prev.person,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handleChangeAddress = (e: any) => {
    setProfile((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setProfile((prev) => ({
      ...prev,
      person: {
        ...prev.person,
        birthDate: date,
      },
    }));
  };

  const handleChangePhone = (e: any, value: any) => {
    let phone = e.split(" ").join("");
    const count = value.dialCode.length;
    let phoneNumber = phone.substring(count + 1);
    let phoneCode = value.dialCode;
    if (phone[count + 1] === "0") {
      setProfile((prev) => ({
        ...prev,
        phone: "",
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        phone: phoneNumber,
        phoneCode: "+" + phoneCode,
      }));
    }
  };

  const handleNationalityChange = (e: any, value: any) => {
    if (value !== null) {
      setProfile((prev) => ({
        ...prev,
        person: {
          ...prev.person,
          nationality: value.code,
        },
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        person: {
          ...prev.person,
          nationality: "",
        },
      }));
    }
  };

  const handleCountryChange = (e: any, value: any) => {
    if (value !== null) {
      setProfile((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          countryCode: value.code,
        },
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          countryCode: "",
        },
      }));
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Snackbar
        open={checkedE}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">
          Ihr Profil wurde nicht erfolgreich übermittelt
        </Alert>
      </Snackbar>
      <Snackbar
        open={checkedC}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Bitte überprüfen Sie die Pflichtfelder</Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("label_wrong_image_format_size_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("label_wrong_image_format_or_size")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardContent>
          <Typography className={classes.title} color="primary" gutterBottom>
            Dein Profil
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"firstName"}
                  defaultValue={person.firstName}
                  onChange={handleChangePerson}
                  variant="outlined"
                  label="Vorname"
                  helperText={
                    person.firstName === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  required
                  error={
                    person.firstName === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"lastName"}
                  defaultValue={person.lastName}
                  helperText={
                    person.lastName === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  onChange={handleChangePerson}
                  variant="outlined"
                  label="Familienname"
                  required
                  error={
                    person.lastName === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DatePicker
                    id={"birthDate"}
                    value={person.birthDate === "" ? null : person.birthDate}
                    onChange={handleDateChange}
                    openTo="year"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    maxDate={new Date().toISOString().slice(0, 10)}
                    label="Geburtsdatum"
                    views={["year", "month", "date"]}
                    disableFuture
                    clearLabel="ok"
                    cancelLabel="abbrechen"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"birthPlace"}
                  defaultValue={person.birthPlace}
                  onChange={handleChangePerson}
                  helperText={
                    person.birthPlace === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  variant="outlined"
                  label="Geburtsort"
                  required
                  error={
                    person.birthPlace === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item md={2} sm={2} xs={3}>
                    {person.nationality !== "" && (
                      <Flag
                        // @ts-ignore
                        code={
                          CountriesDe?.find(
                            (x) => x?.code === person?.nationality
                          )?.code
                        }
                        width="60"
                        style={{ paddingBottom: "4px" }}
                      />
                    )}
                  </Grid>

                  <Grid item md={10} sm={10} xs={9}>
                    <Autocomplete
                      id={"nationality"}
                      options={CountriesDe}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => {
                        return (
                          <>
                            <Flag
                              code={option.code}
                              width="26"
                              style={{ paddingRight: "6px" }}
                            />
                            {option.label}
                          </>
                        );
                      }}
                      value={{
                        code: person.nationality,
                        label: person.nationality
                          ? CountriesDe.find(
                              (x) => x.code === person.nationality
                            )!.label
                          : "",
                        phone: "",
                      }}
                      onChange={(event: object, value: any) =>
                        handleNationalityChange(event, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={person.nationality}
                          label={"Staasangehörigkeit"}
                          variant="outlined"
                          style={{ width: "-webkit-fill-available" }}
                          helperText={
                            person.nationality === "" &&
                            getProfile.status !== "new"
                              ? "Pflichtfeld"
                              : ""
                          }
                          error={
                            person.nationality === "" &&
                            getProfile.status !== "new"
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"identityCardNo"}
                  defaultValue={person.identityCardNo}
                  onChange={handleChangePerson}
                  helperText={
                    person.identityCardNo === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  variant="outlined"
                  label="gültige Ausweisnummer oder Passnummer"
                  required
                  error={
                    person.identityCardNo === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"areaCode"}
                  defaultValue={address.areaCode}
                  onChange={handleChangeAddress}
                  helperText={
                    address.areaCode === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  variant="outlined"
                  label="Postleitzahl"
                  required
                  error={
                    address.areaCode === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"area"}
                  defaultValue={address.area}
                  onChange={handleChangeAddress}
                  helperText={
                    address.area === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  variant="outlined"
                  label="Wohnort"
                  required
                  error={
                    address.area === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"streetAndHouseNumber"}
                  defaultValue={address.streetAndHouseNumber}
                  onChange={handleChangeAddress}
                  helperText={
                    address.streetAndHouseNumber === "" &&
                    getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  variant="outlined"
                  label="Straße, Hausnummer"
                  required
                  error={
                    address.streetAndHouseNumber === "" &&
                    getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item md={2} sm={2} xs={3}>
                    {address.countryCode !== "" && (
                      <Flag
                        code={address.countryCode}
                        width="60"
                        style={{ paddingBottom: "4px" }}
                      />
                    )}
                  </Grid>

                  <Grid item md={10} sm={10} xs={9}>
                    <Autocomplete
                      id={"nationality"}
                      options={CountriesDe}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => {
                        return (
                          <>
                            <Flag
                              code={option.code}
                              width="26"
                              style={{ paddingRight: "6px" }}
                            />
                            {option.label}
                          </>
                        );
                      }}
                      value={{
                        code: address.countryCode,
                        label: address.countryCode
                          ? CountriesDe.find(
                              (x) => x.code === address.countryCode
                            )!.label
                          : "",
                        phone: "",
                      }}
                      onChange={(event: object, value: any) =>
                        handleCountryChange(event, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={address.countryCode}
                          label={"Wohnland"}
                          variant="outlined"
                          style={{ width: "-webkit-fill-available" }}
                          helperText={
                            address.countryCode === "" &&
                            getProfile.status !== "new"
                              ? "Pflichtfeld"
                              : ""
                          }
                          error={
                            address.countryCode === "" &&
                            getProfile.status !== "new"
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPhoneNumber
                  id={"phone"}
                  value={getProfile.phoneCode + getProfile.phone}
                  onChange={handleChangePhone}
                  defaultCountry="de"
                  helperText={
                    getProfile.phone === "" && getProfile.status !== "new"
                      ? "Pflichtfeld"
                      : ""
                  }
                  regions={"europe"}
                  variant="outlined"
                  label="Ländervorwahl, Telefon"
                  required
                  autoFormat={false}
                  countryCodeEditable={false}
                  error={
                    getProfile.phone === "" && getProfile.status !== "new"
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            onClick={saveProfile}
            variant="contained"
            color="primary"
            disableElevation
          >
            Änderungen speichern
          </Button>
          <input
            id="contained-button-file"
            accept="image/*"
            type="file"
            onChange={(event: any) => fileUpload(event.target.files)}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button variant="outlined" component="span" disableElevation>
              Klicken Sie hier, um ein Foto hochzuladen
            </Button>
          </label>
        </CardActions>
      </Card>
    </>
  );
};
