import React from "react";
import { withTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { AppBarMain } from "components/AppBar";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 80,
    },
  })
);

const ImpressumPage = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <AppBarMain />
      <Container maxWidth="lg" className={classes.container}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" color="primary" align="center">
              Impressum
            </Typography>
            <p>CAMP/24 check-in GmbH</p>
            <p>Singener Straße 25</p>
            <p>78239 Rielasingen-Worblingen</p>
            <p>e-mail: info@camp24checkin.de</p>
            <p>Geschäftsführer: Metin Sentürk</p>
            <p>Registergericht: Amtsgericht Freiburg</p>
            <p>Registernummer: HRB 709290</p>
            <p>Umsatzsteuer-ID Nr. : DE 287043086</p>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export const Impressum = withTranslation()(ImpressumPage);
