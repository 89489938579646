import type { CancelToken } from "axios";

import type {
  IUpdatePetStatusParams,
  IUpdateUserProfileParams,
  IGetVerifyCodeParams,
  IUpdateVerifySync,
} from "./domain";

import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  userProfile: "/user/profile",
  userRole: "/userRole",
  userOwn: "/user/own",
  userPetStatus: "/user/pet",
  getVerifyCode: "/user/sync/verify",
  verifySync: "/user/sync/done/verify",
};

export class UserApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/`) {
    super(baseURL);
  }

  async getUserProfile(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userProfile, { cancelToken });

    return data;
  }

  async updateUserProfile(params: IUpdateUserProfileParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.userProfile, params, { cancelToken });

    return data;
  }

  async getUserRole(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userRole, { cancelToken });

    return data;
  }

  async getUserOwn(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userOwn, { cancelToken });

    return data;
  }

  async updatePetStatus(params: IUpdatePetStatusParams, cancelToken?: CancelToken) {
    const filter = params.id && params.pet ? "?id=" + params.id + "&pet=" + params.pet.toString() : "";
    const { data } = await this.http.post(URLS.userPetStatus + filter, { cancelToken });

    return data;
  }

  async getVerifyCode(params: IGetVerifyCodeParams, cancelToken?: CancelToken) {
    let filter = params?.code ? "/" + params?.code : "";
    const { data } = await this.http.get(URLS.getVerifyCode + filter, { cancelToken });

    return data;
  }
  async updateVerifySync({ code, bookingId }: IUpdateVerifySync, cancelToken?: CancelToken) {
    let filter = code && bookingId ? "/" + code + "/" + bookingId : "";
    const { data } = await this.http.post(URLS.verifySync + filter, { cancelToken });

    return data;
  }
}
