import React, { useState, createContext } from "react";

import { IHomeContext } from "interfaces/context";

const HomeContext = createContext<IHomeContext>({} as IHomeContext);

export const useHomeState = () => {
  const context = React.useContext(HomeContext);
  if (!context) {
    throw new Error(`useHomeStates must be used within a HomeProvider`);
  }
  return context;
};

export const HomeProvider = ({ children }) => {
  const [getAddress, setAddress] = useState<string>("");
  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);
  const [getStartDate, setStartDate] = useState<string | null>(null);
  const [getEndDate, setEndDate] = useState<string | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <HomeContext.Provider
      value={{
        getAddress,
        setAddress,
        isFiltersOpen,
        setFiltersOpen,
        getStartDate,
        setStartDate,
        getEndDate,
        setEndDate,
        infoOpen,
        setInfoOpen
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
