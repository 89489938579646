import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

import { Button, Link, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";

import { CenteredContainer } from "components/common/CenteredContainer";
import { FirebaseAuth } from "../../../services/firebase/Firebase";

const VerifyEmailPage: React.FC<WithTranslation> = ({ t }) => {
  const [open, setOpen] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    const tick = () => {
      if (FirebaseAuth.currentUser) {
        FirebaseAuth.currentUser.reload().then((res) => {
          if (FirebaseAuth.currentUser && FirebaseAuth.currentUser.emailVerified) {
            push("/login");
          }
        });
      }
    };

    const intervalId = setInterval(() => tick(), 5000);
    return () => clearInterval(intervalId);
  }, []);

  const sendEmailVerification = () => {
    FirebaseAuth.currentUser!.sendEmailVerification()
      .then((value) => console.log(value))
      .catch((reason) => console.log(reason));
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  return (
    <CenteredContainer>
      <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success">Der Bestätigungslink wurde an Ihre E-Mail gesendet</Alert>
      </Snackbar>
      <Paper elevation={1} style={{ padding: "10px 10px 10px 10px" }}>
        <Typography variant="h5" gutterBottom>
          {t("label_resend_text")}
        </Typography>
      </Paper>
      <Button
        fullWidth={true}
        onClick={() => sendEmailVerification()}
        variant="contained"
        style={{ marginTop: "10px" }}
        color="primary"
      >
        {t("label_resend_email_verification")}
      </Button>
      <div style={{ marginTop: "10px", cursor: "pointer" }}>
        <Link
          onClick={(e) => {
            FirebaseAuth.signOut()
              .then((_) => console.log("ok"))
              .catch((reason) => console.log(reason));
          }}
          variant="body2"
        >
          {t("label_cancel")}
        </Link>
      </div>
    </CenteredContainer>
  );
};

export const VerifyEmail = withTranslation()(VerifyEmailPage);
