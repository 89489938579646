import React, { useState, createContext } from "react";
import { useLocation } from "react-router-dom";

import { IVehicleContext, IPrev } from "interfaces/context";

const VehicleContext = createContext<IVehicleContext>({} as IVehicleContext);

export const useVehicle = () => {
  const context = React.useContext(VehicleContext);
  if (!context) {
    throw new Error(`useVehicle must be used within a VehicleProvider`);
  }
  return context;
};

export const VehicleProvider = ({ children }) => {
  const location = useLocation<any>();

  const [vehicles, setVehicles] = useState<any>(location.state.vehicle);
  const [checked, setChecked] = useState(false);
  const [checkedE, setCheckedE] = useState(false);
  const [checkedC, setCheckedC] = useState(false);
  const [vehicleForm, setVehicleForm] = useState(false);
  const [pendantVehicleForm, setPendantVehicleForm] = useState(false);
  const [prev, setPrev] = useState<IPrev>();
  const [card1, setCard1] = useState(1);
  const [card2, setCard2] = useState(1);
  const [savedVehicle, setSavedVehicle] = useState(false);
  const [trigger, setTrigger] = useState(false);

  return (
    <VehicleContext.Provider
      value={{
        vehicles,
        setVehicles,
        checked,
        setChecked,
        checkedE,
        setCheckedE,
        checkedC,
        setCheckedC,
        vehicleForm,
        setVehicleForm,
        pendantVehicleForm,
        setPendantVehicleForm,
        prev,
        setPrev,
        card1,
        setCard1,
        card2,
        setCard2,
        savedVehicle,
        setSavedVehicle,
        trigger,
        setTrigger,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};
