import { Card, List, ListSubheader, ListItem, Grid, Typography } from "@material-ui/core";

function GeneralInfoCard({ item }) {
  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">Allgemeine Informationen</ListSubheader>}>
        {item.generalInformation.amountOfSpots && item.generalInformation.amountOfSpots !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography style={{ color: "#7ED321", fontSize: "revert" }}>Anzahl Stellplätze:</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.amountOfSpots}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.generalInformation.season && item.generalInformation.season !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography style={{ color: "#7ED321", fontSize: "revert" }}>Saison:</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.season}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.generalInformation.maximumStay && item.generalInformation.maximumStay !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography style={{ color: "#7ED321", fontSize: "revert" }}>
                  Maximaler Aufenthalt:
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.maximumStay}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.checkin && item.checkin !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography style={{ color: "#7ED321", fontSize: "revert" }}>Check/in ab:</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.checkin.slice(11, 16)} Uhr
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.checkin && item.checkin !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography style={{ color: "#7ED321", fontSize: "revert" }}>Check/out bis:</Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.checkout.slice(11, 16)} Uhr
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
    </Card>
  );
}

export default GeneralInfoCard;
