import React from "react";
import "./App.css";
import "./translations/i18n";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Map as Home } from "./pages/public/Home";
import { ParkingAreal } from "./pages/public/ParkingAreaDetail";
import { AuthProvider } from "./context/Auth";
import { PrivateRoute } from "./components/PrivateRoute";
import { UserPages } from "./components/user";

import {
  Impressum,
  Fair,
  B2b,
  Return,
  Help,
  ReservationSVG,
  Login,
  Forget,
  SignUp,
  Demo,
} from "pages/public";
import { VerifyEmail } from "pages/authenticated";
import { ParkingAreaDetailProvider } from "context";
import { ApiServiceProvider } from "rest";
import { HomeProvider } from "context/Home";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C8E6C9",
      main: "#7ED321",
      dark: "#a1e515",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f9f9f9",
      light: "#ffffff",
      dark: "#c6c6c6",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
  },
});

const HomeWithProvider = () => (
  <HomeProvider>
    <Home />
  </HomeProvider>
);

const ParkingArealWithProvider = () => (
  <ParkingAreaDetailProvider>
    <ParkingAreal />
  </ParkingAreaDetailProvider>
);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApiServiceProvider>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <Switch>
              {/* <Route exact path={"/"} component={HomeWithProvider} />
              <Route exact path={"/impressum"} component={Impressum} />
              <Route exact path={"/fair"} component={Fair} />
              <Route exact path={"/b2b"} component={B2b} />
              <Route exact path={"/return"} component={Return} />
              <Route exact path={"/help"} component={Help} />
              <Route exact path={"/booking/svg"} component={ReservationSVG} />
              <Route exact path={"/login"} component={Login} />
              <Route exact path={"/login/forget"} component={Forget} />
              <Route exact path={"/login/signup"} component={SignUp} />
              <Route
                exact
                path={"/parkingAreal/:id"}
                component={ParkingArealWithProvider}
              /> */}
              <Route exact path={"/demo"} component={Demo} />
              {/* <PrivateRoute
                exact
                path={"/user/verify"}
                component={VerifyEmail}
              />
              <PrivateRoute
                exact
                path={"/user/profile"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/travellers"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/vehicles"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/bookmarks"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/bookings"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/bookings-past"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/reviews"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/guthaben"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/stornieren"}
                component={UserPages}
              />
              <PrivateRoute
                exact
                path={"/user/rechnungen"}
                component={UserPages}
              /> */}
            </Switch>
          </MuiThemeProvider>
        </BrowserRouter>
      </ApiServiceProvider>
    </AuthProvider>
  );
};

export default App;
