import { useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  FormControl,
  Button,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";

export const ProcessCompletePage: React.FC<any> = ({ setActivePage }) => {
  const [phoneCode, setPhoneCode] = useState<string>("+49");
  const [phone, setPhone] = useState<any>();
  const [isWaitSmsCode, setIsWaitSmsCode] = useState<boolean>(false);

  const handleChangePhone = (e: any, value: any) => {
    let phone = e.split(" ").join("");
    const count = value.dialCode.length;
    let phoneNumber = phone.substring(count + 1);
    let phoneCode = value.dialCode;
    if (phone[count + 1] === "0") {
      setPhone(phoneNumber);
    } else {
      setPhone(phoneNumber);
      setPhoneCode("+" + phoneCode);
    }
  };

  const handleSmsVerification = () => {
    setIsWaitSmsCode(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        textAlign: "left",
        fontSize: "20px",
        width: "100%",
        fontWeight: 500,
        marginTop: "30px",
        padding: "0 20px",
        height: "90vh",
      }}
    >
      <Grid
        item
        direction="column"
        justifyContent="center"
        alignContent="center"
        style={{ display: "flex" }}
      >
        <h2 style={{ marginBottom: "-5px", color: "#0b429c", fontWeight: 900 }}>
          Ihre Buchung war erfolgreich!
        </h2>
        <p style={{ color: "#0b429c" }}>
          Wir wünschen einen angenehmen Aufenthalt
        </p>
        <div style={{ fontSize: "15px", color: "grey" }}>Ihr Code: 1234</div>
        <p style={{ color: "#0b429c" }}>
          Sie können jetzt Ihren Code notieren oder wir sendien Ihnen eine SMS.
        </p>
        <Button
          onClick={console.log}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginBottom: "5px", color: "black", fontWeight: "bold" }}
        >
          {"SMS SCHICKEN"}
        </Button>
        <p style={{ fontSize: "15px", color: "grey", textAlign: "left" }}>
          Ihre Quittung
        </p>
        <p style={{ color: "#0b429c" }}>
          Auf Wunsch senden sir Ihnen nach dem Check-out eine Quittung.
        </p>
        <Button
          onClick={console.log}
          variant="contained"
          color="primary"
          style={{
            marginTop: "15px",
            color: "black",
            fontWeight: "bold",
            maxWidth: "70%",
            alignSelf: "center",
          }}
        >
          {"QUITTUNG SCHICKEN"}
        </Button>
      </Grid>

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "auto",
          marginBottom: "50px",
          justifyContent: "space-around",
        }}
      >
        <IconButton aria-label="next" onClick={() => setActivePage(4)}>
          <img
            alt="Back Button"
            src="/assets/images/BackButton.svg"
            height="50px"
            width="50px"
          />
        </IconButton>
        <IconButton aria-label="next" onClick={() => setActivePage(1)} disabled>
          {/* <img
            alt="Next Button"
            src="/assets/images/NextButton.svg"
            height="50px"
            width="50px"
          /> */}
        </IconButton>
      </div>
    </Grid>
  );
};
