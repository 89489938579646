import React from "react";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
  CardActions,
  Button,
  Switch,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { useVehicle } from "context";
import { useUpdateVehicle } from "rest";

const RedSwitch = withStyles({
  switchBase: {
    color: "#FF0000",
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles(() =>
  createStyles({
    cardActions: {
      padding: 16,
    },
  })
);

export const PendantVehicleForm = ({ savedVehicle }) => {
  const classes = useStyles();

  const {
    vehicles,
    setVehicles,
    setCheckedC,
    setSavedVehicle,
    setCard1,
    setCard2,
    setPendantVehicleForm,
    setChecked,
    setCheckedE,
    setTrigger,
    trigger,
    prev,
  } = useVehicle();

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const savePendant = () => {
    let first = Object.keys(vehicles).filter((key) => vehicles[key] === "");
    let second = Object.keys(vehicles.pendant).filter((key) => vehicles.pendant[key] === "");

    if (first.length > 0 || second.length > 0) {
      setCheckedC(true);
      setSavedVehicle(true);
      setTimeout(() => {
        setCheckedC(false);
      }, 5000);
    } else {
      let updateVehicleObject = {
        lengthInM: vehicles.lengthInMM.toString(),
        numberplate: vehicles.numberplate.toString(),
        owner: vehicles.vehicleOwner.toString(),
        hasPendant: true,
        pendantLengthInM: vehicles.pendant.lengthInMM.toString(),
        pendantNumberplate: vehicles.pendant.numberplate.toString(),
        isPendantActive: vehicles.pendant.isPendantActive,
      };
      setCard1(1);
      setCard2(1);
      updateVehicle(updateVehicleObject);
    }
  };

  const handleChangePendantVehicle = (e: any) => {
    vehicles.pendant[e.target.id] = e.target.value;
    setTrigger(!trigger);
  };

  const handlePendantActive = (e) => {
    if (e.target.checked) {
      vehicles.pendant[e.target.id] = true;
    } else {
      vehicles.pendant[e.target.id] = false;
    }
    setTrigger(!trigger);
  };

  const closePendant = () => {
    setPendantVehicleForm(false);
    setCard2(1);
    setVehicles(prev);
  };

  return (
    <Card style={{ marginTop: 18 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"numberplate"}
                defaultValue={vehicles.pendant.numberplate}
                onChange={handleChangePendantVehicle}
                variant="outlined"
                label="Kennzeichen"
                required
                helperText={vehicles.pendant.numberplate === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.pendant.numberplate === "" && savedVehicle === true ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"lengthInMM"}
                defaultValue={vehicles.pendant.lengthInMM}
                onChange={handleChangePendantVehicle}
                variant="outlined"
                label="Länge in Meter"
                type="number"
                helperText={vehicles.pendant.lengthInMM === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.pendant.lengthInMM === "" && savedVehicle === true ? true : false}
                required
                onInput={(e: any) => {
                  e.target.value = e.target.value.includes("-")
                    ? ""
                    : e.target.value === "0"
                    ? ""
                    : e.target.value.includes(".")
                    ? e.target.value.indexOf(".") === 2
                      ? e.target.value.toString().slice(0, 5)
                      : e.target.value.toString().slice(0, 4)
                    : Number(parseFloat(e.target.value).toString().slice(0, 2)) < 20
                    ? e.target.value.toString().slice(0, 2)
                    : e.target.value.toString().slice(0, 1);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid item>
          <Button onClick={savePendant} variant="contained" color="primary" disableElevation>
            Anhänger Speichern
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={closePendant} variant="contained" disableElevation>
            Abbrechen
          </Button>
        </Grid>
        <Grid item style={{ marginLeft: "auto" }}>
          <RedSwitch
            checked={vehicles.pendant.isPendantActive}
            onChange={handlePendantActive}
            id={"isPendantActive"}
            name="checkedB"
            color="primary"
          />
        </Grid>
      </CardActions>
    </Card>
  );
};
