import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import Flag from "react-world-flags";
import deLocale from "date-fns/locale/de";

import {
  Accordion,
  AccordionSummary,
  Grid,
  Avatar,
  Typography,
  FormControlLabel,
  AccordionDetails,
  FormControl,
  TextField,
  AccordionActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  makeStyles,
  Theme,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useTravellers } from "context";

import {
  useCreateTravellers,
  useDeleteTravellers,
  useUserTravellers,
  useUpdateTravellers,
  useUploadTravellerImage,
} from "rest";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CountriesDe } from "constants/countries";

export type TravellerCardProps = {
  item: any;
  index: number;
  refreshUser?: any;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckedE: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckedC: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 16,
      fontSize: 14,
    },
    cardActions: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
      marginLeft: "12px",
      paddingTop: "5px",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      paddingTop: "5px",
    },
    switchFormControl: {
      height: "100%",
      padding: "0 14px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

export const TravellerCard: React.FC<TravellerCardProps> = ({
  item,
  index,
  setChecked,
  setCheckedE,
  setCheckedC,
}) => {
  const classes = useStyles();
  const {
    travellers,
    expanded,
    setExpanded,
    savedTraveller,
    setSavedTraveller,
    newTraveller,
    setNewTraveller,
  } = useTravellers();

  const [updateState, setUpdateState] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [indexx, setIndexx] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCheckedAlert = (type) => {
    type(true);
    setTimeout(() => {
      type(false);
    }, 5000);
  };

  const { refetch: getUserTravellers } = useUserTravellers({
    enabled: false,
    onSuccess: () => {
      setSavedTraveller(true);
      setNewTraveller(false);
    },
  });

  const { mutate: uploadTravellerImage } = useUploadTravellerImage({
    onSuccess: (result) => {
      travellers[index].person["avatar"] = result;
      setTrigger(!trigger);
      // saveTraveller(index);
    },
    onError: () => handleCheckedAlert(setCheckedE),
  });

  const { mutate: updateTraveller } = useUpdateTravellers({
    onSuccess: () => handleCheckedAlert(setChecked),
    onError: () => handleCheckedAlert(setCheckedE),
  });

  const { mutate: deleteUserTraveller } = useDeleteTravellers({
    onSuccess: () => handleCheckedAlert(setChecked),
    onError: () => handleCheckedAlert(setCheckedE),
  });

  const { mutate: createTraveller } = useCreateTravellers({
    onSuccess: () => {
      handleCheckedAlert(setChecked);
      setSavedTraveller(true);
      setNewTraveller(false);
    },
    onError: () => handleCheckedAlert(setCheckedE),
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const cancelTraveller = () => {
    getUserTravellers();
  };

  const deleteTraveller = (e: any, index: number) => {
    setExpanded(false);
    let id = travellers[index].id;
    deleteUserTraveller({ id });
  };

  const handlePanelChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDateChange = (date: any, index: number) => {
    travellers[index].person.birthDate = new Date(date).toISOString();

    setUpdateState(!updateState);
  };

  const handleAddressSwitchChange = (e: any, index: number) => {
    travellers[index].sameAddressAsUser = e.target.checked;

    if (!e.target.checked) {
      travellers[index].address = {
        area: "",
        areaCode: "",
        streetAndHouseNumber: "",
        countryCode: "",
      };
    } else {
      delete travellers[index].address;
    }

    setUpdateState(!updateState);
  };

  const handleAddressChange = (e: any, index: number) => {
    travellers[index].address[e.target.id] = e.target.value;
    setTrigger(!trigger);
  };

  const fileUpload = (files: FileList | null, index: number) => {
    if (files && files.item(0)) {
      //@ts-ignore
      const type = files.item(0).type;
      const arr = ["image/png", "image/jpg", "image/jpeg"];
      //@ts-ignore
      if (!arr.includes(type)) {
        setDialogOpen(true);
        //@ts-ignore
      } else if (files.item(0).size > 1022181) {
        setDialogOpen(true);
      } else {
        //@ts-ignore
        uploadTravellerImage({ travellerId: travellers[index].id, file: files.item(0) });
      }
    }
  };

  const findLabel = (code) => {
    function findCountry(country) {
      if (country.code === code) {
        return country.label;
      }
    }

    let country = CountriesDe.find(findCountry);

    if (country === undefined) {
      return "";
    } else {
      return country.label;
    }
  };

  const active = (index, event: React.ChangeEvent<HTMLInputElement>) => {
    travellers[index].enabled = event.target.checked;
    saveTraveller(index);
  };

  const handleChange = (e: any, index: number) => {
    travellers[index].person[e.target.id] = e.target.value;
    setTrigger(!trigger);
  };

  const handleNationalityChange = (e: any, index: number, value: any) => {
    if (value !== null) {
      travellers[index].person["nationality"] = value.code;
      setTrigger(!trigger);
    } else {
      travellers[index].person["nationality"] = "";
    }
  };

  const handleCountryCodeChange = (e: any, index: number, value: any) => {
    if (value !== null) {
      travellers[index].address["countryCode"] = value.code;
      setTrigger(!trigger);
    } else {
      travellers[index].address["countryCode"] = "";
    }
  };

  const saveTraveller = (index?: number) => {
    setNewTraveller(false);
    if (index !== undefined) {
      let first = Object.keys(travellers[index].person).filter(
        (key) => travellers[index].person[key] === "avatar"
      );
      if (!travellers[index].sameAddressAsUser) {
        let third = Object.keys(travellers[index].address).filter(
          (key) => travellers[index].address[key] === ""
        );
        first = [...first, ...third];
      }
      let secondImp = first;

      if (secondImp.length > 0) {
        setCheckedC(true);
        setTimeout(() => {
          setCheckedC(false);
        }, 5000);
      } else {
        let destructured = nestedLoop(travellers[index]);
        if (travellers[index].id !== "") {
          updateTraveller({ data: destructured });
        } else {
          createTraveller({ data: destructured });
        }
      }
    }
  };

  const nestedLoop = (obj: any) => {
    const res = {};
    function recurse(obj: any, current?: any) {
      for (const key in obj) {
        let value = obj[key];
        if (value !== undefined) {
          if (value && typeof value === "object") {
            recurse(value, key);
          } else {
            res[key] = value;
          }
        }
      }
    }
    recurse(obj);
    return res;
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wrong Image Format or Size"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please check your image format and size. Only PNG and JPG files which are below 1 MB are
            supported.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Accordion
        key={index}
        expanded={expanded === `panel${index}`}
        onChange={savedTraveller ? handlePanelChange(`panel${index}`) : () => null}
        elevation={1}
      >
        <AccordionSummary expandIcon={savedTraveller ? <ExpandMoreIcon /> : null}>
          <>
            <Grid container spacing={0}>
              <Grid item xs={2} md={2}>
                <Avatar className={classes.small} alt="Avatar" src={item.person.avatar} />
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography className={classes.heading}>
                  {item.person.firstName} {item.person.lastName}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography className={classes.secondaryHeading}>
                  {findLabel(item.person.nationality)} - {item.person.birthPlace}
                </Typography>
              </Grid>
              <Grid item xs={1} md={2}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Switch
                      checked={travellers[index].enabled}
                      onChange={(event) => (savedTraveller ? active(index, event) : null)}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      color="primary"
                    />
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"firstName"}
                  defaultValue={travellers[index].person.firstName}
                  onChange={(e) => handleChange(e, index)}
                  variant="outlined"
                  label="Vorname"
                  required
                  helperText={travellers[index].person.firstName === "" && !newTraveller ? "Pflichtfeld" : ""}
                  error={travellers[index].person.firstName === "" && !newTraveller ? true : false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"lastName"}
                  defaultValue={travellers[index].person.lastName}
                  onChange={(e) => handleChange(e, index)}
                  variant="outlined"
                  label="Familienname"
                  required
                  helperText={travellers[index].person.lastName === "" && !newTraveller ? "Pflichtfeld" : ""}
                  error={travellers[index].person.lastName === "" && !newTraveller ? true : false}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DatePicker
                    id={"birthDate"}
                    value={
                      travellers[index].person.birthDate === "" ? null : travellers[index].person.birthDate
                    }
                    onChange={(date) => handleDateChange(date, index)}
                    openTo="year"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    maxDate={new Date().toISOString().slice(0, 10)}
                    label="Geburtsdatum"
                    views={["year", "month", "date"]}
                    disableFuture
                    clearLabel="ok"
                    cancelLabel="abbrechen"
                    error={travellers[index].person.birthDate === "" && !newTraveller ? true : false}
                    helperText={
                      travellers[index].person.birthDate === "" && !newTraveller ? "Pflichtfeld" : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"birthPlace"}
                  defaultValue={travellers[index].person.birthPlace}
                  onChange={(e) => handleChange(e, index)}
                  variant="outlined"
                  label="Geburtsort"
                  required
                  helperText={
                    travellers[index].person.birthPlace === "" && !newTraveller ? "Pflichtfeld" : ""
                  }
                  error={travellers[index].person.birthPlace === "" && !newTraveller ? true : false}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item md={2}>
                    {travellers[index].person.nationality !== "" && (
                      <Flag
                        code={travellers[index].person.nationality}
                        width="60"
                        style={{ paddingBottom: "4px" }}
                      />
                    )}
                  </Grid>

                  <Grid item md={10}>
                    <Autocomplete
                      id={"nationality"}
                      options={CountriesDe}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => {
                        return (
                          <>
                            <Flag code={option.code} width="26" style={{ paddingRight: "6px" }} />
                            {option.label}
                          </>
                        );
                      }}
                      // @ts-ignore
                      defaultValue={{
                        code: travellers[index].person.nationality,
                        label: findLabel(travellers[index].person.nationality),
                      }}
                      onChange={(event: object, value: any) =>
                        // @ts-ignore
                        handleNationalityChange(event, index, value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={travellers[index].person.nationality}
                          label={"Staasangehörigkeit"}
                          variant="outlined"
                          style={{ width: "-webkit-fill-available" }}
                          helperText={
                            travellers[index].person.nationality === "" && !newTraveller ? "Pflichtfeld" : ""
                          }
                          error={travellers[index].person.nationality === "" && !newTraveller ? true : false}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"identityCardNo"}
                  defaultValue={travellers[index].person.identityCardNo}
                  onChange={(e) => handleChange(e, index)}
                  variant="outlined"
                  label="gültige Ausweisnummer oder Passnummer"
                  required
                  helperText={
                    travellers[index].person.identityCardNo === "" && !newTraveller ? "Pflichtfeld" : ""
                  }
                  error={travellers[index].person.identityCardNo === "" && !newTraveller ? true : false}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" className={classes.switchFormControl}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={travellers[index].sameAddressAsUser}
                      onChange={(e) => handleAddressSwitchChange(e, index)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Gleiche Adresse wie Nutzer"
                />
              </FormControl>
            </Grid>
            {!travellers[index].sameAddressAsUser && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id={"streetAndHouseNumber"}
                    defaultValue={travellers[index].address.streetAndHouseNumber}
                    onChange={(e) => handleAddressChange(e, index)}
                    variant="outlined"
                    label="Straße, Hausnummer"
                    error={
                      travellers[index].address.streetAndHouseNumber === "" &&
                      !newTraveller &&
                      !travellers[index].sameAddressAsUser
                        ? true
                        : false
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {!travellers[index].sameAddressAsUser && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id={"areaCode"}
                    defaultValue={travellers[index].address.areaCode}
                    onChange={(e) => handleAddressChange(e, index)}
                    variant="outlined"
                    label="Postleitzahl, Wohnort"
                    helperText={
                      travellers[index].address.areaCode === "" &&
                      !newTraveller &&
                      !travellers[index].sameAddressAsUser
                        ? "Pflichtfeld"
                        : ""
                    }
                    error={
                      travellers[index].address.areaCode === "" &&
                      !newTraveller &&
                      !travellers[index].sameAddressAsUser
                        ? true
                        : false
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {!travellers[index].sameAddressAsUser && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id={"area"}
                    defaultValue={travellers[index].address.area}
                    onChange={(e) => handleAddressChange(e, index)}
                    variant="outlined"
                    label="Staat"
                    helperText={
                      travellers[index].address.area === "" &&
                      !newTraveller &&
                      !travellers[index].sameAddressAsUser
                        ? "Pflichtfeld"
                        : ""
                    }
                    error={
                      travellers[index].address.area === "" &&
                      !newTraveller &&
                      !travellers[index].sameAddressAsUser
                        ? true
                        : false
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {!travellers[index].sameAddressAsUser && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item md={2}>
                      {travellers[index].address.countryCode !== "" ? (
                        <Flag
                          code={travellers[index].address.countryCode}
                          width="60"
                          style={{ paddingBottom: "4px" }}
                        />
                      ) : null}
                    </Grid>

                    <Grid item md={10}>
                      <Autocomplete
                        id={"countryCode"}
                        options={CountriesDe}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => {
                          return (
                            <>
                              <Flag code={option.code} width="26" style={{ paddingRight: "6px" }} />
                              {option.label}
                            </>
                          );
                        }}
                        // @ts-ignore
                        defaultValue={{
                          code: travellers[index].address.countryCode,
                          label: findLabel(travellers[index].address.countryCode),
                        }}
                        onChange={(event: object, value: any) =>
                          // @ts-ignore
                          handleCountryCodeChange(event, index, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={travellers[index].address.countryCode}
                            label={"Landesvorwahl"}
                            variant="outlined"
                            style={{ width: "-webkit-fill-available" }}
                            helperText={
                              travellers[index].address.countryCode === "" &&
                              !newTraveller &&
                              !travellers[index].sameAddressAsUser
                                ? "Pflichtfeld"
                                : ""
                            }
                            error={
                              travellers[index].address.countryCode === "" &&
                              !newTraveller &&
                              !travellers[index].sameAddressAsUser
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>

        <AccordionActions className={classes.cardActions}>
          <Button onClick={(e) => saveTraveller(index)} variant="contained" color="primary" disableElevation>
            Änderungen speichern
          </Button>
          <div>
            <input
              id="contained-button-file"
              accept="image/*"
              type="file"
              onChange={(e) => fileUpload(e.target.files, indexx)}
              style={{ display: "none" }}
            />
            <label htmlFor="contained-button-file" style={{ paddingRight: "7px" }}>
              <Button variant="outlined" component="span" disableElevation onClick={() => setIndexx(index)}>
                Klicken Sie hier, um ein Foto hochzuladen
              </Button>
            </label>
            <Button
              onClick={(e) => (savedTraveller ? deleteTraveller(e, index) : cancelTraveller())}
              variant="contained"
              disableElevation
            >
              {savedTraveller ? "LÖSCHEN" : "ABBRECHEN"}
            </Button>
          </div>
        </AccordionActions>
      </Accordion>
    </>
  );
};
