import React, { Suspense, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import firebase from "firebase";

import {
  CssBaseline,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Fade,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { VerifyCodeDialog, UserForm, VehicleAndTravellersDialog } from "components/user/profile";
import { GoogleIcon } from "components/icons/GoogleIcon";
import { FirebaseAuth } from "services/firebase/Firebase";
import { useUserProfile } from "rest";
import { useProfile } from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserProfile: React.FC<any> = (props) => {
  const classes = useStyles();

  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [linked, setLinked] = useState(false);
  const [loading, setLoading] = useState(true);

  const { checked, setProfile, openImageBackdrop, setOpenImageBackdrop } = useProfile();

  useEffect(() => {
    setOpenImageBackdrop(true);
  }, []);

  useUserProfile({
    onSuccess: (results) => {
      setProfile(results);
      setOpenImageBackdrop(false);
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const linkAccount = (type: string) => {
    if (type === "google") {
      let googleProvider = new firebase.auth.GoogleAuthProvider();
      if (FirebaseAuth.currentUser != null) {
        FirebaseAuth.currentUser
          .linkWithPopup(googleProvider)
          .then(() => {
            setLinked(true);
            setTimeout(() => {
              setLinked(false);
            }, 5000);
          })
          .catch((error) => {
            setAlreadyLinked(true);
            setTimeout(() => {
              setAlreadyLinked(false);
            }, 5000);

            console.log(error);
          });
      }
    } else if (type === "apple") {
      let appleProvider = new firebase.auth.OAuthProvider("apple.com");
      if (FirebaseAuth.currentUser != null) {
        FirebaseAuth.currentUser
          .linkWithPopup(appleProvider)
          .then((result) => {
            setLinked(true);
            setTimeout(() => {
              setLinked(false);
            }, 5000);
          })
          .catch((error) => {
            setAlreadyLinked(true);
            setTimeout(() => {
              setAlreadyLinked(false);
            }, 5000);
          });
      }
    }
  };

  if (!loading) {
    return (
      <>
        <Backdrop
          className={classes.backdrop}
          open={openImageBackdrop}
          onClick={() => setOpenImageBackdrop(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />

        <Snackbar open={checked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="success">Ihr Profil wurde erfolgreich übermittelt</Alert>
        </Snackbar>

        <Snackbar open={linked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="success">Ihr Konto wurde verlinkt</Alert>
        </Snackbar>

        <Snackbar open={alreadyLinked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="warning">Ihr Konto wurde bereits verlinkt</Alert>
        </Snackbar>

        <VehicleAndTravellersDialog profilePageProps={props} />

        <VerifyCodeDialog />

        <Suspense fallback={<h1>Loading profile...</h1>}>
          <Fade in={true}>
            <UserForm />
          </Fade>
          <br />
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="span"
                disableElevation
                onClick={(e) => linkAccount("google")}
                startIcon={<GoogleIcon />}
              >
                Link Google Account
              </Button>
            </Grid>
          </Grid>
        </Suspense>
      </>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={openImageBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const Profile = withTranslation()(UserProfile);
