import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Delete as DeleteIcon, StarBorder as StarBorderIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    booking: {
      backgroundColor: "#DCE6A0",
      borderBottom: "5px solid white",
    },
    red: {
      backgroundColor: "#fad0c5",
      borderBottom: "5px solid white",
    },
    titlex: {
      backgroundColor: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

export const CommentRow = ({ item, cDelete }) => {
  const classes = useStyles();
  return (
    <>
      <TableRow className={classes.titlex}>
        <TableCell colSpan={3} style={{ paddingTop: "3px", paddingBottom: "0px" }}>
          <Typography style={{ color: "#009646" }} variant="h6">
            {item.parkingArealName}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow className={item.tot > 2 ? classes.booking : classes.red}>
        <TableCell component="th" scope="row" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          {item.start && (
            <p style={{ marginBottom: "0px", marginTop: "0px" }}>
              von :
              {" " +
                new Date(item.start).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
            </p>
          )}
          {item.end && (
            <p style={{ marginBottom: "0px", marginTop: "0px" }}>
              bis :
              {"  " +
                new Date(item.end).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
            </p>
          )}
          <p style={{ marginBottom: "0px", marginTop: "10px" }}>
            <Rating
              name={item.id}
              value={item.tot}
              precision={1}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </p>
        </TableCell>
        <TableCell style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={3}
            value={item.body}
            variant="outlined"
            disabled
            style={{ width: "-webkit-fill-available", backgroundColor: "#FFFFFF" }}
          />
        </TableCell>
        <TableCell align="left" size="small" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <Tooltip title="Delete Folder" placement="top" arrow>
            <IconButton
              onClick={() => cDelete(item)}
              edge="end"
              style={{ marginRight: "auto", color: "rgba(255,0,0,0.62)" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};
