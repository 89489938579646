import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Chip,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { SyncAlt as SyncAltIcon, Delete as DeleteIcon, Room as RoomIcon } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { campCardServices, campCardActivities } from "constants/campCardItems";

import { useBookmarks } from "context/Bookmarks";

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      minWidth: 320,
      height: 218,
    },
    facilitiesList: {
      marginTop: 8,
    },
    facilities: {
      marginRight: 4,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
    },
    card: {
      width: "100%",
      marginBottom: 18,
      display: "flex",
      justifyContent: "flex-start",
    },
    details: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    campCardActions: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export const CampCard = ({ item, index, groups }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:680px)");

  const {
    setChangeFolder,
    setChangeFolderForm,
    setItemId,
    setDialogOpen,
    setSelectedPlace,
    setInfoOpen,
    setZoom,
    setCenterlat,
    setCenterlng,
  } = useBookmarks();

  const openChangeFolderForm = (bookmarkId: string) => {
    setChangeFolder((prev) => ({ ...prev, bookmarkId }));
    setChangeFolderForm(true);
  };

  const openMapAndCard = (event: any, item: any) => {
    setSelectedPlace(item);
    setInfoOpen((previousValue) => !previousValue);
    setZoom(10);
    setCenterlat(item.lat);
    setCenterlng(item.lon);

    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#bookingpast");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <Card className={classes.card} key={index}>
        <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.id}`, state: item }}>
          {matches && (
            <CardMedia
              className={classes.media}
              image={`/assets/images/placeholders/${item.lat.toString().slice(-1)}.jpg`}
              title={item.name}
            />
          )}
        </CardActionArea>
        <div className={classes.details}>
          <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.id}`, state: item }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {item.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Stadt: {item.address.area}
                {item.generalInformation.amountOfSpots
                  ? ` - Anzahl Stellplätze: ${item.generalInformation.amountOfSpots}`
                  : ""}
              </Typography>
              <div className={classes.facilitiesList}>
                {campCardServices.map((service) => {
                  const hasProperty = item.properties[service.name];
                  const src = service.source || service.name;

                  return (
                    !!hasProperty && (
                      <Tooltip title={service.title} placement="top" arrow>
                        <img
                          src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                          className={classes.facilities}
                          alt={service.title}
                        />
                      </Tooltip>
                    )
                  );
                })}

                <br />

                {campCardActivities.map((service) => {
                  if (service.name === "br") {
                    return <br />;
                  }
                  const hasProperty = item.properties[service.name];
                  const src = service.source || service.name;

                  return (
                    <Tooltip title={service.title || service.name} placement="top" arrow>
                      <img
                        src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                        className={classes.facilities}
                        alt={service.title}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.campCardActions}>
            <Chip
              label={`PREIS ${item.priceInformation.priceForTwoAdults}`}
              variant="outlined"
              className={classes.chip}
            />
            <div>
              <Tooltip title="Change Folder" placement="top" arrow>
                <IconButton
                  onClick={() => openChangeFolderForm(item.bookmarkId)}
                  size="small"
                  edge="end"
                  color="primary"
                >
                  <SyncAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setItemId(item.bookmarkId);
                    setDialogOpen(true);
                  }}
                  size="small"
                  edge="end"
                  color="primary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {groups[index] === true && (
                <Tooltip title="Auf der Karte" placement="top" arrow>
                  <IconButton
                    size="small"
                    edge="end"
                    color="primary"
                    onClick={(event) => {
                      openMapAndCard(event, item);
                    }}
                  >
                    <RoomIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </CardActions>
        </div>
      </Card>
    </>
  );
};
