import React from "react";
import { useHistory } from "react-router-dom";
import { TableRow, TableCell, FormControlLabel, IconButton, makeStyles } from "@material-ui/core";
import { Room as RoomIcon } from "@material-ui/icons";
import { useBookings } from "context";

const useStyles = makeStyles({
  booking: {
    backgroundColor: "#DCE6A0",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
});

export const RowItem = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();

  const { setSelectedPlace, setInfoOpen, setZoom, setCenterlat, setCenterlng } = useBookings();

  const openMapAndCard = (event: any, item: any) => {
    setSelectedPlace(item);
    setInfoOpen((previousValue) => !previousValue);
    setZoom(10);
    setCenterlat(item.lat);
    setCenterlng(item.lng);
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#bookingpast");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <TableRow
      className={item.status === "active" ? classes.booking : classes.no}
      onClick={() => history.push(`/parkingAreal/${item.parkingArealId}`)}
    >
      <TableCell component="th" scope="row">
        {new Date(item.createdAt).toLocaleDateString("de-DE")}
      </TableCell>
      <TableCell align="left">{item.parkingArealName}</TableCell>
      <TableCell align="right">
        {new Date(item.startDate).toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })}
      </TableCell>
      <TableCell align="right">
        {new Date(item.endDate).toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })}
      </TableCell>
      <TableCell align="right">{item.parkingSpotName}</TableCell>
      <TableCell align="right">{item.PIN}</TableCell>
      <TableCell align="center">
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <IconButton
              edge="end"
              color="primary"
              onClick={(event) => {
                openMapAndCard(event, item);
              }}
            >
              <RoomIcon />
            </IconButton>
          }
          label=""
        />
      </TableCell>
    </TableRow>
  );
};
