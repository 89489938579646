import { Grid, TextField, IconButton } from "@material-ui/core";
import { CaravanSizeComponent } from "./CaravanSizeComponent";
import { vehicleSizes } from "./DemoDummyData";

export const DemoDayAndSizeSelect: React.FC<any> = ({
  setActivePage,
  selectedSize,
  setSelectedSize,
  amountOfDays,
  setAmountOfDays,
}) => {
  const isInputsFilled =
    (selectedSize === 0 || selectedSize) && amountOfDays > 0;
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Grid container>
          <Grid
            item
            xs={12}
            direction="column"
            alignItems="center"
            style={{ margin: "0 10px", textAlign: "center" }}
          >
            <h2>{"Fahrzeuglänge"}:</h2>
            <div
              style={{
                color: "#0b429c",
                marginBottom: "15px",
                marginTop: "-10px",
                fontSize: "22px",
              }}
            >
              {"Wir ermitteln für Sie den passenden Platz."}
            </div>
          </Grid>
          <Grid id={"StepOne"} style={{ margin: "40px auto" }} item>
            <div id={"small"} onClick={() => setSelectedSize(0)}>
              <CaravanSizeComponent
                selected={selectedSize === 0}
                caravanSize={vehicleSizes[0]}
                price={vehicleSizes[0]["price"]}
                color={vehicleSizes[0]["background"]}
              />
            </div>
            <div id={"medium"} onClick={() => setSelectedSize(1)}>
              <CaravanSizeComponent
                selected={selectedSize === 1}
                caravanSize={vehicleSizes[1]}
                price={vehicleSizes[1]["price"]}
                color={vehicleSizes[1]["background"]}
              />
            </div>

            <div id={"large"} onClick={() => setSelectedSize(2)}>
              <CaravanSizeComponent
                selected={selectedSize === 2}
                caravanSize={vehicleSizes[2]}
                price={vehicleSizes[2]["price"]}
                color={vehicleSizes[2]["background"]}
              />
            </div>
            <div id={"x_large"} onClick={() => setSelectedSize(3)}>
              <CaravanSizeComponent
                selected={selectedSize === 3}
                caravanSize={vehicleSizes[3]}
                price={vehicleSizes[3]["price"]}
                color={vehicleSizes[3]["background"]}
              />
            </div>
            <div id={"xx_large"} onClick={() => setSelectedSize(4)}>
              <CaravanSizeComponent
                selected={selectedSize === 4}
                caravanSize={vehicleSizes[4]}
                price={vehicleSizes[4]["price"]}
                color={vehicleSizes[4]["background"]}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "20px 0", width: "70%", maxWidth: "450px" }}>
        <Grid container>
          <Grid item style={{ marginRight: "30px" }}>
            <h2> {"Aufenthaltsdauer"}:</h2>
            <div
              style={{
                color: "#0b429c",
                marginBottom: "15px",
                marginTop: "-10px",
              }}
            >
              {"Tägliches Auschecken möglich."}
            </div>
          </Grid>
        </Grid>

        <TextField
          InputLabelProps={{
            style: { fontSize: "24px", fontWeight: 900 },
          }}
          InputProps={{ style: { fontSize: 26 } }}
          fullWidth={true}
          value={amountOfDays}
          onChange={(e) => setAmountOfDays(parseInt(e.target.value))}
          label={"In Tagen eingeben"}
          margin="normal"
          variant="outlined"
          id={"Days"}
          type="number"
        />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            marginTop: "auto",
            marginBottom: "30px",
            alignSelf: "end",
          }}
        >
          <IconButton
            aria-label="next"
            style={{
              margin: "15px",
            }}
            onClick={console.log}
            disabled
          >
            {/* <img
              alt="Back Button"
              src="/assets/images/BackButton.svg"
              height="50px"
              width="50px"
            /> */}
          </IconButton>
          <IconButton
            aria-label="next"
            style={{
              margin: "15px",
              opacity: isInputsFilled ? 1 : 0.5,
            }}
            onClick={() => setActivePage(2)}
            disabled={!isInputsFilled}
          >
            <img
              alt="Next Button"
              src="/assets/images/NextButton.svg"
              height="50px"
              width="50px"
            />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};
