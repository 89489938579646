import React, { useState, useContext, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  TextField,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Collapse,
} from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";
import { StarBorder as StarBorderIcon } from "@material-ui/icons";

import {
  bookingCommentUpdate,
  createCommentUser,
  getBookingOfUser,
  getCommentOfUser,
} from "rest/AdminRestClient";
import { AuthContext } from "context/Auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    booking: {
      backgroundColor: "#DCE6A0",
      borderBottom: "5px solid white",
    },
    red: {
      backgroundColor: "#fad0c5",
      borderBottom: "5px solid white",
    },
    titlex: {
      backgroundColor: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

export const BookingCommentRow = ({ item, getStars, setStars, setComments, setBookings }) => {
  const classes = useStyles();
  const { currentUser } = useContext<any>(AuthContext);

  const [checkedA, setCheckedA] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [comment, setComment] = useState<any>([]);

  useEffect(() => {
    console.log({ comment });
  }, [comment]);

  const sendComment = (id: any, pid: any, s: any, e: any) => {
    const send = {
      title: "",
      body: comment[id],
      authorUserId: currentUser.uid,
      parkingArealId: pid,
      createdAt: new Date().toISOString(),
      quiet: getStars[id],
      location: getStars[id],
      furnishing: getStars[id],
      cleanliness: getStars[id],
      price: getStars[id],
      activity: getStars[id],
      total: "",
      ratio: getStars[id],
      start: s,
      end: e,
    };

    createCommentUser(send)
      .then((result) => {
        bookingCommentUpdate(id).then((result) => {
          getCommentOfUser()
            .then((results) => {
              setComments(results.data);
            })
            .catch((error) => console.error(error));

          getBookingOfUser()
            .then((results: { data: any }) => {
              const books = results.data
                .filter(
                  (item: any, index: number) => new Date(item.endDate).getTime() - new Date().getTime() < 0
                )
                .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
              setBookings(books);
              let arrResult: any = [];
              books.forEach(function (value) {
                arrResult[value.id] = 3;
              });
              setStars(arrResult);
            })
            .catch((error: any) => console.error(error));
          setCheckedA(true);
          setTimeout(() => {
            setCheckedA(false);
          }, 5000);
        });
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Collapse in={checkedA}>
        <Alert severity="success">Your review has been saved successfully</Alert>
      </Collapse>

      <TableRow className={classes.titlex}>
        <TableCell colSpan={3} style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <Typography style={{ color: "#009646" }} variant="h6">
            {item.parkingArealName}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.booking}>
        <TableCell component="th" scope="row" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            von :
            {" " +
              new Date(item.startDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            bis :
            {"  " +
              new Date(item.endDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "10px" }}>
            <Rating
              name={item.id}
              value={getStars[item.id]}
              defaultValue={3}
              precision={1}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              onChange={(e, value) => {
                getStars[item.id] = value;
              }}
            />
          </p>
        </TableCell>
        <TableCell style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={3}
            value={comment[item.id]}
            variant="outlined"
            onChange={(event) => (comment[item.id] = event.target.value)}
            style={{ width: "-webkit-fill-available", backgroundColor: "#FFFFFF" }}
          />
        </TableCell>
        <TableCell align="center" size="small" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => sendComment(item.id, item.parkingArealId, item.startDate, item.endDate)}
          >
            Sichern
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
