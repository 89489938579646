import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1800,
    },
    media: {
      height: 275,
    },
    container: {
      marginTop: 80,
    },
    tabs: {
      display: "flex",
      background: "#eff3d0",
      borderRadius: "15px",
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    buttonDisactive: {
      background: "#fff",
      margin: theme.spacing(1),
      borderRadius: "10px",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      textTransform: "none",
      color: "#b2cbd2",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#035875",
      },
    },
    buttonActive: {
      background: "#fff",
      margin: theme.spacing(1),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderRadius: "10px",
      textTransform: "none",
      color: "#035875",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#035875",
      },
    },
    buttonMainActive: {
      background: "#035875",
      margin: theme.spacing(1),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderRadius: "10px",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#035875",
        color: "#fff",
      },
    },
    cellEnd: {
      display: "flex",
      justifyContent: "flex-end",
    },
    cellStart: {
      display: "flex",
      justifyContent: "flex-start",
    },
    title: {
      display: "flex",
      color: "#9eb912",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      paddingBottom: theme.spacing(2),
    },
    blueTitle: {
      display: "flex",
      color: "#035875",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    image: {
      paddingTop: theme.spacing(2),
    },
    bluePaper: {
      display: "flex",
      borderRadius: "15px",
      background: "#e6edf5",
      padding: theme.spacing(2),
    },
    blueTextInside: {
      display: "flex",
      alignItems: "center",
      color: "#035875",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    greenDot: {
      display: "flex",
      color: "#b7ce3f",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(30),
    },
    titleCenter: {
      display: "flex",
      justifyContent: "center",
      color: "#9eb912",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      paddingTop: theme.spacing(2),
    },
    greenSubTitle: {
      display: "flex",
      color: "#b7ce3f",
      fontWeight: theme.typography.fontWeightBold,
    },
    blueSubTitle: {
      display: "flex",
      color: "#47c8ff",
      fontWeight: theme.typography.fontWeightBold,
    },
    listSeperate: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    priceBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

export default useStyles;
