import React from "react";
import { withTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import { Grid, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Image from "material-ui-image";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { AppBarMain } from "components/AppBar";

import useStyles from "./style";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export const LEFT_TAB_BUTTONS = [
  { title: "Willkommen", tabIndex: 1 },
  { title: "Lösung", tabIndex: 2 },
  { title: "Idee", tabIndex: 3 },
  { title: "Komponenten", tabIndex: 4 },
  { title: "Dienste", tabIndex: 5 },
];

const B2bPage: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  return (
    <>
      <CssBaseline />
      <AppBarMain />
      <Container maxWidth="lg" className={classes.container}>
        <Card className={classes.root}>
          <CardMedia>
            <Image
              src={value !== 6 ? `/assets/images/title${value}.jpg` : `/assets/images/title1.jpg`}
              disableSpinner
              aspectRatio={14 / 4}
            />
          </CardMedia>

          <CardContent>
            <div className={classes.tabs}>
              <Grid container spacing={1}>
                <Grid item sm={8} xs={12} className={classes.cellStart}>
                  {value !== 6 && (
                    <Grid container spacing={1}>
                      {LEFT_TAB_BUTTONS.map((btn) => (
                        <Grid item>
                          <Button
                            onClick={() => setValue(btn.tabIndex)}
                            className={
                              value === btn.tabIndex ? classes.buttonActive : classes.buttonDisactive
                            }
                          >
                            {btn.title}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} className={classes.cellEnd}>
                  <Button
                    className={value !== 6 ? classes.buttonMainActive : classes.buttonDisactive}
                    onClick={() => (value === 6 ? setValue(1) : null)}
                  >
                    Stellplatz
                  </Button>
                  <Button
                    className={value === 6 ? classes.buttonMainActive : classes.buttonDisactive}
                    onClick={() => setValue(6)}
                  >
                    Camping
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              <TabPanel index={1} value={value}>
                <Grid container spacing={3}>
                  <Grid item sm={12} style={{ justifyContent: "flex-start" }}>
                    <Typography className={classes.title}>Von Camper für Camper</Typography>
                  </Grid>
                  <Grid item sm={12} style={{ justifyContent: "flex-start" }}>
                    <Typography variant="body1" style={{ color: "#525252" }}>
                      Über 40 Jahre Camping Erfahrung sind maßgeblich bei der Entwicklung unseres Systems mit
                      eingeflossen.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Image
                      src={`/assets/images/willkommenInside.jpg`}
                      disableSpinner
                      aspectRatio={14 / 5}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={12}>
                    <Typography className={classes.blueTitle}>
                      Profitieren Sie von den vielen Vorteilen unseres Systems:
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Paper elevation={0} className={classes.bluePaper}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Mehrwertsteuerersparnisse`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Flexible Preisgestaltung nach Saison und Bedarf`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Registrierung aller Übernachtungsgäste`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Bargeldlose Zahlung – kein Aufbruch Risiko`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Stellplatz Sicherheit: volle Zufahrtskontrolle auf das Gelände`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Detaillierte Abrechnungen`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Umweltschonende Effekte`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Werbemöglichkeiten für Veranstaltungen und Handel vor Ort`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Parkscheinkontrolle nicht notwendig`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Gebührenfreies Parken ist nicht mehr möglich`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.blueTextInside}>
                            <span className={classes.greenDot}>{`•`}&nbsp;</span>
                            {` Kurtaxe wird pro Person erhoben`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Image
                      src={`/assets/images/greenCircle.jpg`}
                      disableSpinner
                      aspectRatio={1 / 1}
                      className={classes.image}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel index={2} value={value}>
                <Grid container spacing={3}>
                  <Grid item sm={12} style={{ justifyContent: "flex-start" }}>
                    <Typography className={classes.title}>
                      Wohnmobile und Caravans erfreuen sich stetig wachsender Beliebtheit.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Die Branche verzeichnet seit Jahren einen starken Anstieg der Zulassungszahlen und damit
                      steigt auch die Nachfrage an Stellplätzen für die Übernachtungen. Dabei kommt es vor,
                      dass Camper immer wieder erfolglos überfüllte Plätze anfahren und sich dann wieder auf
                      die Suche nach einem anderen Stellplatz begeben müssen. Das mindert die Urlaubsqualität
                      und Zeit der Gäste und schadet der Umwelt. Wir von Camp24Check-in lösen dieses Problem
                      und erhöhen dadurch einerseits die Urlaubsqualität und Zufriedenheit der Gäste und
                      bieten gleichzeitig den Betreibern eine effektive, effiziente und sichere
                      Bewirtschaftung der Stellplätze.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Die Gäste erwartet ein Stellplatz mit moderner Infrastruktur und bargeldloser
                      Abrechnung. Für die Stellplatzbetreiber verringert sich der Verwaltungsaufwand auf ein
                      Minimum. Sie haben die Kontrolle und Sicherheit darüber, wer sich auf Ihrem Platz
                      befindet.
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel index={3} value={value}>
                <Grid container spacing={3}>
                  <Grid item sm={12} style={{ justifyContent: "flex-start" }}>
                    <Typography className={classes.title}>
                      Wir machen Ihre Reisemobilstellplätze online reservier-/buchbar.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Image
                      src={`/assets/images/ideeInside.jpg`}
                      disableSpinner
                      aspectRatio={16 / 6}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Über unsere APP und Webseite können sich Campinggäste registrieren und für den
                      gewünschten Zeitraum ihren Stellplatz vorab buchen. Der Stellplatzbetreiber kann die
                      unterschiedlichen Platzgrößen selbst bestimmen und die Preise dafür festlegen.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Grundvoraussetzung für eine Reservierbarkeit der Stellplätze ist eine Zufahrtskontrolle
                      zum Stellplatz. Diese erreichen wir durch unser Schrankensystem mit integrierter
                      Kennzeichenerkennung. Gäste, die nicht vorab gebucht haben, können über eine
                      Buchungsstation vor Ort einchecken und die verfügbaren Plätze nutzen. Nach der Zufahrt
                      zum Gelände können die Gäste ihren gebuchten Stellplatz anfahren. Über die große
                      digitale Nummerierung unserer Versorgungssäulen können die Gäste diesen sehr leicht
                      lokalisieren.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Unsere Dienstleistungen beinhalten die Erfassung der Gästedaten für die Meldescheine und
                      ein umfassendes Rechnungswesen inklusive Rechnungstellung für Gast und Betreiber sowie
                      monatlichen Abrechnungen für Ihre Buchhaltung.
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel index={4} value={value}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography className={classes.titleCenter}>Schrankensystem:</Typography>
                  </Grid>
                  <Grid item sm={2} xs={false} />
                  <Grid item sm={8} xs={12}>
                    <Image
                      src={`/assets/images/schranke.jpg`}
                      disableSpinner
                      aspectRatio={20 / 9}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item sm={2} xs={false} />
                  <Grid item sm={12}>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Eine Zufahrtskontrolle zum Gelände ist die Grundvoraussetzung, um die Stellplätze
                      reservierbar zu machen. Unser Schrankensystem arbeitet mit einer automatischen
                      Kennzeichenerkennung. Die eingebauten Kameras erkennen das Kennzeichen eines Fahrzeugs,
                      gleichen dieses mit der Buchungsdatenbank ab und prüfen, ob eine Buchung für Ihren Platz
                      vorliegt. Bei einer Bestätigung öffnet sich die Schranke. Gleichzeitig werden die
                      Versorgungssäule (optional) am gebuchten Platz aktiviert und die Strom- und
                      Wasserversorgung freigeshaltet. Während der gebuchten Aufenthaltsdauer kann ein Gast das
                      Gelände nach Belieben verlassen und auch wieder einfahren. Die Versorgungssäule wird
                      dann jeweils auf Wunsch ab- und wieder eingeschaltet. Beim Verlassen des Geländes nach
                      Ablauf des gebuchten Aufenthalts registriert die Kamera das Auschecken des Gastes.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography className={classes.titleCenter}>Buchungsstation/Check-In:</Typography>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Image
                      src={`/assets/images/uberdacht.jpg`}
                      disableSpinner
                      aspectRatio={21 / 9}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item sm={12}>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Gäste, die sich nicht registriert und keine Reservierung haben, können vor Ort an
                      unserer Buchungsstation einchecken und einen freien Platz buchen. Die Buchungsstation
                      akzeptiert nur elektronische Zahlungsmittel, wodurch das Aufbruchrisiko für die
                      Stationen reduziert wird. Über die reine Check-In Funktion hinaus bietet die
                      Buchungsstation weitere Optionen.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Alle Buchungen können verlängert oder der Platz bei nicht gefallen gewechselt werden
                      (falls die Platzbelegung es erlaubt). Für Stellplätze, die sich in einer Kurregion
                      befinden, gibt es einen (optional) eingebauten Gästekartendrucker, mit dem sich die
                      Gäste ihre Karte direkt am Platz ausdrucken lassen können. Die Rückseite der Gästekarte
                      bietet dem Stellplatzbetreiber die Möglichkeit, kostenpflichtige Werbung zu platzieren,
                      um zusatzliche Einnahmen zu generieren.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Über die integrierte Tourist-Info können die Gäste am Platz über Einkaufsmöglichkeiten,
                      Gastronomie, Veranstaltungen, Freizeitmöglichkeiten, Sehenswürdigkeiten sowie weitere
                      wichtige Infastrukturen für die Gesundheit, Apotheken etc. sowie aktuelle Informationen
                      der Gemeinde informiert werden.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Diese Auskunftsmöglichkeiten können wom Platzbetreiber jederzeit selbst eingepflegt und
                      aktualisiert werden. Hieraus ergeben sich weitere Einnahmemöglichkeiten, womit sich die
                      Kosten für eine Buchungsstation über Werbeeinahmen innerhalb von 1,5 Jahren bereits
                      amortisieren können.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Über die Stellplatzinfo können die Gäste über Platzregeln und die Allgemeinen
                      Geschäftsbedingungen informiert werden.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography className={classes.titleCenter}>Versorgungssäule:</Typography>
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Image
                      src={`/assets/images/saule.jpg`}
                      disableSpinner
                      aspectRatio={12 / 14}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item sm={12}>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Unsere Versorgungssäulen bieten eine gleichzeitige Strom- und Frischwasserversorgung in
                      einer Säule. Der exakte Verbrauch kann jeweils erfasst und nach Ende des Aufenthaltes
                      automatisch abgerechnet werden. Der Gast erhält nach dem Aufenthalt eine detaillierte
                      Abrechnung seines Verbrauchs. Eine pauschale Abrechnung für Strom und Wasser ist
                      weiterhin auch möglich.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Unsere Säulen haben ein eingebautes Frostschutzsystem, um das Einfrieren der Leitungen
                      im Winter zu verhindern. Dabei verzichten wir auf Heizkabel oder ähnlche
                      Heizungssysteme, wodurch keine zusätzlichen Stromkosten entstehen.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Da der Strom verbrauchsgenau abgerechnet werden kann, sind unsere Säulen mit 16A (3,6
                      KW) Cekon Anschlüssen ausgestattet. Der aktuelle Wandel in der Automobilindustrie hin
                      zur Elektromobilität wird auch in der Reisemobilbranche Einzug halten. Da gerade diese
                      Fahrzeuge in der Regel auf Stellplätzen lange Standzeiten haben, sind
                      Reisemobilstellplätze auch für das Laden der Batterien und Akkus prädestiniert. Somit
                      wird jede Versorgungssäule auch zu einer potenziellen Ladestation und bietet dadurch
                      eine zusätzliche Einnahmequelle. Sie als Stellplatzbetreiber sind dadurch bereits jetzt
                      schon auf zukünftige Entwicklungen vorbereit.
                    </Typography>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Für unsere Säulen bieten wir ein optionales Frostschutzsystem an, um das Einfrieren der
                      Leitungen im Winter zu verhindern. Dabei verzichten wir auf Heizkabel oder ähnliche
                      Heizungssysteme, wodurch keine zusätzlichen Stromkosten entstehen.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography className={classes.titleCenter}>
                      Flush-It Reinigungsstation (optional):
                    </Typography>
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Image
                      src={`/assets/images/flushit.png`}
                      disableSpinner
                      aspectRatio={3 / 4}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item sm={12}>
                    <Typography variant="body2" align="justify" style={{ color: "#0e4b77" }}>
                      Das Flush-It ist ein vollautomatische Entleer- und Reinigungsautomat für
                      Toilettenkassetten. Die Reinigungsstation ist für jeden handelsüblichen Kassettentyp
                      geeignet. Auch neue Kassettenmodelle können durch unsere Station ohne Systemupdate
                      gereinigt werden. Mit unserem System werden die Toilettenkassetten automatisch entleert,
                      von innen vollkommen mit Wasser gereinigt und mit einer Sanitärflüssigkeit je nach Größe
                      auf Wunsch befüllt. Die Reinigungsdauer liegt bei ca. 60 Sek. Über die eingebaute
                      Siemens-Steurung S7-1200 und dem Touch Comfort Monitor wird der Anwender ständig in
                      Schrift und Bild über den Reinigungsprozess informiert. Die Gebühren für die Benutzung
                      der Anlage betragen 1 € ohne und 2 € mit Sanitärflüssigkeit. Die Bezahlung kann
                      bargeldlos über das Camp24 Check-in Buchungssystem abgerechnet werden. Es besteht auch
                      die Möglichkeit, die Gebühr über einen eingebauten Münzautomaten zu bezahlen.
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel index={5} value={value}>
                <Grid container spacing={3}>
                  <Grid item sm={12} style={{ justifyContent: "flex-start" }}>
                    <Typography className={classes.title}>
                      Unsere Dienstleistungen beinhalten die Erfassung der Gästedaten für die Meldescheine und
                      ein umfassendes Rechnungswesen inklusive Rechnungstellung für Gast und Betreiber sowie
                      monatlichen Abrechnungen für Ihre Buchhaltung.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Rechtliche Lage in Deutschland:
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Wer in Hotels oder Campingplätzen eincheckt, muss sich an der Rezeption mit seinen
                      persönlichen Daten anmelden. Die rechtliche Grundlage hierfür bilden die Paragrafen 29 &
                      30 des Bundesmeldegesetzes. Auf dieser Grundlage müssen auch auf Reisemobil-Stellplätzen
                      in Deutschland zwingend Meldescheine ausgefüllt werden. Da die meisten Stellplätze über
                      keine dauerhaft besetzten Rezeptionen oder Personal vor Ort verfügen ist die praktische
                      Umsetzung der Meldepflicht nur sehr schwer möglich. Dabei kommt es manchmal zu
                      speziellen Vereinbarungen zwischen Stellplatzbetreibern mit Gemeinden und Städten,
                      welche die gesetzliche Meldepflicht allerdings meist nur unzureichend erfüllen.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Mit Camp24checkin lösen wir dieses Problem und gleichzeitig helfen wir Ihnen Steuern zu
                      sparen. Bereits bei der Online Buchung erheben wir alle geforderten Daten aus §30
                      Bundesmeldegesetz. Wir erstellen einen elektronischen Meldeschein in Übereinstimmung mit
                      §29 Abs. 5 S.1 Bundesmeldegesetz. Mit einem ordnungsgemäßen Meldeschein wird aus dem
                      Parken eine Beherbergung. Für eine Beherbergung wird in Deutschland und (mit wenigen
                      Ausnahmen) in ganz Europa der ermäßigte Mehrwertsteuersatz fällig wohingegen Parken mit
                      dem Normalsatz besteuert wird. Wir ermöglichen die Registrierung aller
                      Übernachtungsgäste wodurch auch die Kurtaxe pro Person erhoben werden kann. Mehrtägige
                      Aufenthalte sind Dank Camp24Checkin kein Problem.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Unser System ermöglicht eine wertschöpfende, flexible Preisgestaltung nach Saison und
                      Bedarf. Sie können jederzeit selbst die Preise ändern und beispielsweise an Wochenenden
                      und Feiertagen die Preise der Nachfrage entsprechend gestalten.
                    </Typography>
                  </Grid>

                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Die Zufahrtskontrolle über unser Schrankensystem erhöht die Sicherheit am Platz und
                      macht eine Parkscheinkontrolle überflüssig. Gebührenfreies Parken, besonders über Nacht,
                      ist nicht mehr möglich. Sie sparen die Kosten für die Kontrolle und erhöhen gleichzeitig
                      die Umsätze.
                    </Typography>
                  </Grid>

                  <Grid item sm={12}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Plätze die reservierbar sind, werden von Campern bevorzugt angefahren da ein Stellplatz
                      garantiert wird. Lästige und unnötige Suchfahrten werden vermieden und gleichzeitig wird
                      die Umwelt geschont.
                    </Typography>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel index={6} value={value}>
                <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item sm={12}>
                    <Typography className={classes.title}>
                      Online Buchungssystem für Campingplätze:
                    </Typography>
                  </Grid>

                  <Grid item sm={3} />
                  <Grid item sm={9}>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Wir bieten Ihnen für Campingplätze drei Lösungen für die Online Buchungen und eine
                      moderne Platzverwaltung. Bei allen Lösungen können Camper per Camp24Checkin APP oder
                      über unser Webportal einen Platz bei Ihnen online reservieren.
                    </Typography>
                  </Grid>

                  <Grid item sm={3} className={classes.listSeperate}>
                    <Grid container spacing={0}>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={7}>
                        <Image
                          src={`/assets/images/dienste1.jpg`}
                          disableSpinner
                          aspectRatio={4 / 4}
                          className={classes.image}
                          imageStyle={{ height: "100%", width: "100%", left: "inherit", top: "-12px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={9} className={classes.listSeperate}>
                    <Typography variant="body1" style={{ color: "#525252" }}>
                      <span className={classes.blueSubTitle} style={{ display: "inline-block" }}>
                        {`Camp24 light:`}&nbsp;
                      </span>
                      <span className={classes.priceBold} style={{ display: "inline-block" }}>
                        499,-€&nbsp;
                      </span>
                      für die ersten 2 Jahre, danach jährlich 499,-€
                    </Typography>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Planerstellung 6€ pro Platz (einmalig)
                    </Typography>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Webbasiertes Reservierungstool
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Platzinfo editierbar
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Buchungen eintragbar
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Wir erstellen für Sie einen Plan Ihres Campingplatzes.
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Die Kosten betragen hierbei einmalig 6€ je Platz, Parzelle oder sonstige Unterkunft
                        (Chalet, etc.).
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Sie erhalten ein modernes, webbasiertes Reservierungstool mit dem Sie jederzeit eine
                        genaue Übersicht über die Platzbelegung haben.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Sie können eigenständig Ihre Platzinfos auf Camp24 editieren. Dazu gehören die
                        Ausstattung Ihres Platzes, die Aktivitäten für Ihre Gäste und empfehlenswerte
                        Sehenswürdigkeiten.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Für Buchungen die direkt bei Ihnen eingehen können die Plätze für eine Online
                        Reservierung gesperrt werden und erscheinen dann nicht mehr als buchbar.
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item sm={3} className={classes.listSeperate}>
                    <Grid container spacing={0}>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={7}>
                        <Image
                          src={`/assets/images/dienste2.jpg`}
                          disableSpinner
                          aspectRatio={4 / 4}
                          className={classes.image}
                          imageStyle={{ height: "100%", width: "100%", left: "inherit", top: "-12px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={9} className={classes.listSeperate}>
                    <Typography variant="body1" style={{ color: "#525252" }}>
                      <span className={classes.greenSubTitle} style={{ display: "inline-block" }}>
                        {`Camp24 basic:`}&nbsp;
                      </span>
                      <span className={classes.priceBold} style={{ display: "inline-block" }}>
                        899,-€&nbsp;
                      </span>
                      für die ersten 2 Jahre, danach jährlich 899,-€
                    </Typography>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Planerstellung 5€ pro Platz (einmalig)
                    </Typography>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Inklusive Camp24 light
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Saison und Preise editierbar
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Sperrung einzelner Stellplätze
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Webbasiertes Reservierungstool mit Buchungsinformationen
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Kennzeichen Erkennung
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Die Kosten für die Planerstellung betragen 5€ je Platz, Parzelle oder sonstige
                        Unterkunft.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Dieses Lösung enthält alle Funktionalitäten des Camp24 Light Paketes.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Sie können Saisons und die Preise jederzeit editieren und damit beispielsweise für das
                        Oster- oder Pfingstwochenende einen Preisaufschlag festlegen.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Anbindung unserer Kennzeichen Erkennungsoftware an ein Schrankensystem.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Erweitertes Reservierungstool mit Angabe der Kennzeichen und allen
                        Buchungsinformationen.
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item sm={3} className={classes.listSeperate}>
                    <Grid container spacing={0}>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={7}>
                        <Image
                          src={`/assets/images/dienste3.jpg`}
                          disableSpinner
                          aspectRatio={4 / 4}
                          className={classes.image}
                          imageStyle={{ height: "100%", width: "100%", left: "inherit", top: "-12px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={9} className={classes.listSeperate}>
                    <Typography variant="body1" style={{ color: "#525252" }}>
                      <span className={classes.blueSubTitle} style={{ display: "inline-block" }}>
                        {`Camp24 professionell:`}&nbsp;
                      </span>
                      <span className={classes.priceBold} style={{ display: "inline-block" }}>
                        1299,-€&nbsp;
                      </span>
                      für die ersten 2 Jahre, danach jährlich 1299,-€
                    </Typography>
                    <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                      Planerstellung 5€ pro Platz (einmalig)
                    </Typography>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Inklusive Camp24 basic
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Rechnungsstellung
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Beherbergungsformular
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Gästekarte
                      </Typography>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        • Tourist Info
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Dieses Lösung enthält alle Funktionalitäten des Camp24 basic Paketes.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Die Kosten für die Planerstellung betragen 4€ je Platz, Parzelle oder sonstige
                        Unterkunft.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Automatisierte Rechnungsstellung über Camp24.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Camp24 erstellt für jeden gemeldeten Gast ein elektronisches Beherbergungsformular.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Für Kurregionen erstellen wir Ihnen eine elektronische Gästekarte zum Versenden oder
                        Ausdrucken.
                      </Typography>
                    </div>
                    <div className={classes.listSeperate}>
                      <Typography variant="body1" align="justify" style={{ color: "#525252" }}>
                        Sie können Tourist-Informationen in die Camp24Checkin APP bzw. auf unser Webportal
                        einpflegen.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
            </div>
          </CardContent>
        </Card>
      </Container>
      <br />
    </>
  );
};

export const B2b = withTranslation()(B2bPage);
