import type {
  IFetchParkingArealByIdParams,
  IFetchCommentsOfParkingArealParams,
  IFetchPublicSearchParams,
} from "./domain";

export const EntityType = {
  PUBLIC_SEARCH: "public_search",
  PARKING_AREAL_BY_ID: "parking_areal_by_id",
  COMMENTS_OF_PARKING_AREAL: "comments_of_parking_areal",
};

export const QueryKeys = {
  publicSearch: (params: IFetchPublicSearchParams) => [
    EntityType.PUBLIC_SEARCH,
    "LIST",
    JSON.stringify(params),
  ],
  parkingArealById: (params: IFetchParkingArealByIdParams) => [
    EntityType.PARKING_AREAL_BY_ID,
    "DETAIL",
    JSON.stringify(params),
  ],
  commentsOfParkingAreal: (params: IFetchCommentsOfParkingArealParams) => [
    EntityType.COMMENTS_OF_PARKING_AREAL,
    "LIST",
    JSON.stringify(params),
  ],
};
