import { SvgIcon } from "@material-ui/core";

export const StornierenIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="st3"
        d="M19.5,4.5c-0.6-0.6-1.7-0.6-2.3,0l-5.1,5.1L7,4.5c-0.6-0.6-1.7-0.6-2.3,0c-0.6,0.6-0.6,1.7,0,2.3l5.1,5.1
              L4.7,17c-0.6,0.6-0.6,1.7,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.2-0.5l5.1-5.1l5.1,5.1c0.3,0.3,0.7,0.5,1.1,0.5
              c0.4,0,0.8-0.2,1.2-0.5c0.6-0.6,0.6-1.7,0-2.3l-5.1-5.1l5.1-5.1C20.2,6.1,20.2,5.1,19.5,4.5z"
      />
      <path
        className="st3"
        d="M21.6,0H2.4C1.1,0,0,1.4,0,3v18c0,1.6,1.1,3,2.4,3h19.3c1.3,0,2.4-1.4,2.4-3V3C24,1.4,23,0,21.6,0z M23.1,21
              c0,1.2-0.7,2.1-1.5,2.1H2.4c-0.8,0-1.5-1-1.5-2.1V3c0-1.2,0.7-2.1,1.5-2.1h19.3c0.8,0,1.5,1,1.5,2.1V21z"
      />
    </SvgIcon>
  );
};
