import React, { useContext } from "react";
import {
  Card,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { AuthContext } from "context/Auth";

function PriceInfoCard({ item, vehicleType }) {
  const { currentUser }: any = useContext(AuthContext);

  return (
    <Card style={{ marginBottom: 18 }}>
      {item.bookable === true ? (
        item.price !== undefined ? (
          <List dense={true} subheader={<ListSubheader color="primary">Preisinformationen</ListSubheader>}>
            {item.season.length > 0 ? (
              <ListItem>
                <ListItemAvatar key="transition-group-content">
                  <Avatar variant="square" src={`/assets/images/newfacilities/caravan.png`} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                        <span>ab </span>
                        {vehicleType && item.season[0].hasOwnProperty("defB")
                          ? currentUser
                            ? item.season
                                .filter((e: any) => e.default === true)[0]
                                [vehicleType][vehicleType + "0"].replace(".", ",")
                            : item.seasonp
                                .filter((e: any) => e.default === true)[0]
                                ["B"]["B0"].replace(".", ",")
                          : ""}
                        <Typography
                          display={"inline"}
                          style={{
                            fontWeight: 400,
                            fontSize: "revert",
                          }}
                        >
                          {" "}
                          € für 2 Personen
                        </Typography>
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ) : (
              ""
            )}
            {(item.price.priceExtraAdult && item.price.priceExtraAdult !== "0") ||
            (item.price.priceChild && item.price.priceChild !== "0") ? (
              <ListItem>
                <ListItemAvatar key="priceExtraAdult">
                  <Avatar variant="square" src={`/assets/images/newfacilities/per48.png`} />
                </ListItemAvatar>
                {item.price.priceExtraAdult && !item.price.priceChild ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.priceExtraAdult.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.priceExtraAdult.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      weitere Person
                    </Typography>
                  </Typography>
                ) : !item.price.priceExtraAdult && item.price.priceChild ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.priceChild.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.priceChild.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      pro Kind{" "}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.priceExtraAdult.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.priceExtraAdult.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      weitere Person /{" "}
                    </Typography>{" "}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.priceChild.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.priceChild.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      pro Kind
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}

            {(item.price.visitorTax && item.price.visitorTax !== "0") ||
            (item.price.visitorTaxKind && item.price.visitorTaxKind !== "0") ||
            (item.price.visitorTaxHund && item.price.visitorTaxHund !== "0") ? (
              <ListItem>
                <ListItemAvatar key="priceExtraAdult">
                  <Avatar variant="square" src={`/assets/images/newfacilities/kurztaxe.png`} />
                </ListItemAvatar>
                {item.price.visitorTax && !item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTax.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTax.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Person
                    </Typography>
                  </Typography>
                ) : !item.price.visitorTax && item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTaxKind.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTaxKind.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Kind
                    </Typography>
                  </Typography>
                ) : !item.price.visitorTax && !item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTaxHund.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTaxHund.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      Hund
                    </Typography>
                  </Typography>
                ) : item.price.visitorTax && item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTax.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTax.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Person /{" "}
                    </Typography>{" "}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.visitorTaxKind.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.visitorTaxKind.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Kind
                    </Typography>
                  </Typography>
                ) : item.price.visitorTax && !item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTax.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTax.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Person /{" "}
                    </Typography>{" "}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.visitorTaxHund.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.visitorTaxHund.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      Hund
                    </Typography>
                  </Typography>
                ) : !item.price.visitorTax && item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTaxKind.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTaxKind.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Kind /{" "}
                    </Typography>{" "}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.visitorTaxHund.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.visitorTaxHund.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      Hund
                    </Typography>
                  </Typography>
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.visitorTax.split("/")[0] === "0"
                      ? "inklusive"
                      : item.price.visitorTax.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Person /{" "}
                    </Typography>{" "}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.visitorTaxKind.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.visitorTaxKind.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      p. Kind /{" "}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {item.price.visitorTaxHund.split("/")[0] === "0"
                        ? "inklusive"
                        : item.price.visitorTaxHund.split("/")[0] + " €"}
                    </Typography>
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      Hund
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.price.electricityCosts && item.price.electricityCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="electricity48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/electricity48.png`} />
                </ListItemAvatar>

                {item.price.electricityCosts === "inklusive" ? (
                  <ListItemText primary={item.price.electricityCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.electricityCosts.split("/")[0] + " € kW"}

                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      {item.price.electricityCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.price.waterCosts && item.price.waterCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="water48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/water48.png`} />
                </ListItemAvatar>
                {item.price.waterCosts === "inklusive" ? (
                  <ListItemText primary={item.price.waterCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.waterCosts.split("/")[0] + " € 100 Liter"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      {item.price.waterCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.showerCosts && item.priceInformation.showerCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="shower48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/shower48.png`} />
                </ListItemAvatar>
                {item.price.showerCosts === "inklusive" ? (
                  <ListItemText primary={item.priceInformation.showerCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.showerCosts.split("/")[0] + " €"}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.showerCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.showerCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.toiletCosts && item.priceInformation.toiletCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="wifi48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/toilet48.png`} />
                </ListItemAvatar>
                {item.priceInformation.toiletCosts === "inklusive" ? (
                  <ListItemText primary={item.priceInformation.toiletCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.toiletCosts.split("/")[0] + " €"}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.toiletCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.toiletCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}

            {item.price.wifiCosts && item.price.wifiCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="wifi48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/wifi48.png`} />
                </ListItemAvatar>
                {item.price.wifiCosts === "inklusive" ? (
                  <ListItemText primary={item.price.wifiCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.wifiCosts.split("/")[0] + " €"}
                    <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                      {" "}
                      am Tag
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.price.petCosts && item.price.petCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="hund48">
                  <Avatar variant="square" src={`/assets/images/newfacilities/hund48.png`} />
                </ListItemAvatar>
                {item.price.petCosts === "inklusive" ? (
                  <ListItemText primary={item.price.petCosts} style={{ marginRight: "50px" }} />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.price.petCosts.split("/")[0] + " €"}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${(10 - item.price.petCosts.split("/")[0].length) * 8.5}px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.price.petCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.chemistryWCCosts && item.priceInformation.chemistryWCCosts !== "0" ? (
              <ListItem>
                <ListItemAvatar key="chemistryWCCosts">
                  <Avatar variant="square" src={`/assets/images/newfacilities/dis48.png`} />
                </ListItemAvatar>
                {item.priceInformation.chemistryWCCosts === "inklusive" ? (
                  <ListItemText
                    primary={item.priceInformation.chemistryWCCosts}
                    style={{ marginRight: "50px" }}
                  />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.chemistryWCCosts.split("/")[0] + " €"}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.chemistryWCCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.chemistryWCCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.automatCosts && item.priceInformation.automatCosts !== "-" ? (
              <ListItem>
                <ListItemAvatar key="automatCosts">
                  <Avatar variant="square" src={`/assets/images/newfacilities/automat48.png`} />
                </ListItemAvatar>
                {item.priceInformation.automatCosts === "inklusive" ? (
                  <ListItemText
                    primary={item.priceInformation.automatCosts}
                    style={{ marginRight: "50px" }}
                  />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.automatCosts.split("/")[0]}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.automatCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.automatCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.disposalCosts && item.priceInformation.disposalCosts !== "-" ? (
              <ListItem>
                <ListItemAvatar key="disposalCosts">
                  <Avatar variant="square" src={`/assets/images/newfacilities/baden48.png`} />
                </ListItemAvatar>
                {item.priceInformation.disposalCosts === "inklusive" ? (
                  <ListItemText
                    primary={item.priceInformation.disposalCosts}
                    style={{ marginRight: "50px" }}
                  />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.disposalCosts.split("/")[0]}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.disposalCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.disposalCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
            {item.priceInformation.rubbishCosts && item.priceInformation.rubbishCosts !== "-" ? (
              <ListItem>
                <ListItemAvatar key="rubbishCosts">
                  <Avatar variant="square" src={`/assets/images/newfacilities/dustbin48.png`} />
                </ListItemAvatar>
                {item.priceInformation.rubbishCosts === "inklusive" ? (
                  <ListItemText
                    primary={item.priceInformation.rubbishCosts}
                    style={{ marginRight: "50px" }}
                  />
                ) : (
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {item.priceInformation.rubbishCosts.split("/")[0]}
                    <Typography
                      display={"inline"}
                      style={{
                        paddingLeft: `${
                          (10 - item.priceInformation.rubbishCosts.split("/")[0].length) * 8.5
                        }px`,
                        fontSize: "revert",
                      }}
                    >
                      {item.priceInformation.rubbishCosts.split("/")[1]}
                    </Typography>
                  </Typography>
                )}
              </ListItem>
            ) : (
              ""
            )}
          </List>
        ) : (
          ""
        )
      ) : (
        <List dense={true} subheader={<ListSubheader color="primary">Preisinformationen</ListSubheader>}>
          {item.priceInformation.priceForTwoAdults && item.priceInformation.priceForTwoAdults !== "-" ? (
            <ListItem>
              <ListItemAvatar key="transition-group-content">
                <Avatar variant="square" src={`/assets/images/newfacilities/caravan.png`} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                      <span>ab </span>
                      {item.priceInformation.priceForTwoAdults}
                      <Typography
                        display={"inline"}
                        style={{
                          fontWeight: 400,
                          fontSize: "revert",
                        }}
                      >
                        {" "}
                        für 2 Personen
                      </Typography>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ) : (
            ""
          )}
          {(item.priceInformation.priceExtraAdult && item.priceInformation.priceExtraAdult !== "-") ||
          (item.priceInformation.priceChild && item.priceInformation.priceChild !== "-") ? (
            <ListItem>
              <ListItemAvatar key="priceExtraAdult">
                <Avatar variant="square" src={`/assets/images/newfacilities/per48.png`} />
              </ListItemAvatar>
              {item.priceInformation.priceExtraAdult && !item.priceInformation.priceChild ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.priceExtraAdult.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    weitere Person
                  </Typography>
                </Typography>
              ) : !item.priceInformation.priceExtraAdult && item.priceInformation.priceChild ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.priceChild.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    pro Kind{" "}
                  </Typography>
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.priceExtraAdult.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    weitere Person /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.priceChild.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    pro Kind
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {(item.priceInformation.visitorTax && item.priceInformation.visitorTax !== "-") ||
          (item.priceInformation.visitorTaxKind && item.priceInformation.visitorTaxKind !== "-") ||
          (item.priceInformation.visitorTaxHund && item.priceInformation.visitorTaxHund !== "-") ? (
            <ListItem>
              <ListItemAvatar key="priceExtraAdult">
                <Avatar variant="square" src={`/assets/images/newfacilities/kurztaxe.png`} />
              </ListItemAvatar>
              {item.priceInformation.visitorTax &&
              !item.priceInformation.visitorTaxKind &&
              !item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTax.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Person
                  </Typography>
                </Typography>
              ) : !item.priceInformation.visitorTax &&
                item.priceInformation.visitorTaxKind &&
                !item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTaxKind.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Kind
                  </Typography>
                </Typography>
              ) : !item.priceInformation.visitorTax &&
                !item.priceInformation.visitorTaxKind &&
                item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTaxHund.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    Hund
                  </Typography>
                </Typography>
              ) : item.priceInformation.visitorTax &&
                item.priceInformation.visitorTaxKind &&
                !item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTax.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Person /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.visitorTaxKind.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Kind
                  </Typography>
                </Typography>
              ) : item.priceInformation.visitorTax &&
                !item.priceInformation.visitorTaxKind &&
                item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTax.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Person /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.visitorTaxHund.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    Hund
                  </Typography>
                </Typography>
              ) : !item.priceInformation.visitorTax &&
                item.priceInformation.visitorTaxKind &&
                item.priceInformation.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTaxKind.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Kind /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.visitorTaxHund.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    Hund
                  </Typography>
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.visitorTax.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Person /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.visitorTaxKind.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    p. Kind /{" "}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.priceInformation.visitorTaxHund.split("/")[0]}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    Hund
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.electricityCosts && item.priceInformation.electricityCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="electricity48">
                <Avatar variant="square" src={`/assets/images/newfacilities/electricity48.png`} />
              </ListItemAvatar>

              {item.priceInformation.electricityCosts === "inklusive" ? (
                <ListItemText
                  primary={item.priceInformation.electricityCosts}
                  style={{ marginRight: "50px" }}
                />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.electricityCosts.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {item.priceInformation.electricityCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.waterCosts && item.priceInformation.waterCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="water48">
                <Avatar variant="square" src={`/assets/images/newfacilities/water48.png`} />
              </ListItemAvatar>
              {item.priceInformation.waterCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.waterCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.waterCosts.split("/")[0]}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {item.priceInformation.waterCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.showerCosts && item.priceInformation.showerCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="shower48">
                <Avatar variant="square" src={`/assets/images/newfacilities/shower48.png`} />
              </ListItemAvatar>
              {item.priceInformation.showerCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.showerCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.showerCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.priceInformation.showerCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.showerCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.toiletCosts && item.priceInformation.toiletCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="wifi48">
                <Avatar variant="square" src={`/assets/images/newfacilities/toilet48.png`} />
              </ListItemAvatar>
              {item.priceInformation.toiletCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.toiletCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.toiletCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.priceInformation.toiletCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.toiletCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.priceInformation.wifiCosts && item.priceInformation.wifiCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="wifi48">
                <Avatar variant="square" src={`/assets/images/newfacilities/wifi48.png`} />
              </ListItemAvatar>
              {item.priceInformation.wifiCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.wifiCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.wifiCosts.split("/")[0]}

                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    am Tag
                  </Typography>
                  {/*</Typography>*/}
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.petCosts && item.priceInformation.petCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="hund48">
                <Avatar variant="square" src={`/assets/images/newfacilities/hund48.png`} />
              </ListItemAvatar>
              {item.priceInformation.petCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.petCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.petCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.priceInformation.petCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.petCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.chemistryWCCosts && item.priceInformation.chemistryWCCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="chemistryWCCosts">
                <Avatar variant="square" src={`/assets/images/newfacilities/dis48.png`} />
              </ListItemAvatar>
              {item.priceInformation.chemistryWCCosts === "inklusive" ? (
                <ListItemText
                  primary={item.priceInformation.chemistryWCCosts}
                  style={{ marginRight: "50px" }}
                />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.chemistryWCCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${
                        (10 - item.priceInformation.chemistryWCCosts.split("/")[0].length) * 8.5
                      }px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.chemistryWCCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.automatCosts && item.priceInformation.automatCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="automatCosts">
                <Avatar variant="square" src={`/assets/images/newfacilities/automat48.png`} />
              </ListItemAvatar>
              {item.priceInformation.automatCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.automatCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.automatCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${
                        (10 - item.priceInformation.automatCosts.split("/")[0].length) * 8.5
                      }px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.automatCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.disposalCosts && item.priceInformation.disposalCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="disposalCosts">
                <Avatar variant="square" src={`/assets/images/newfacilities/baden48.png`} />
              </ListItemAvatar>
              {item.priceInformation.disposalCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.disposalCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.disposalCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${
                        (10 - item.priceInformation.disposalCosts.split("/")[0].length) * 8.5
                      }px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.disposalCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.priceInformation.rubbishCosts && item.priceInformation.rubbishCosts !== "-" ? (
            <ListItem>
              <ListItemAvatar key="rubbishCosts">
                <Avatar variant="square" src={`/assets/images/newfacilities/dustbin48.png`} />
              </ListItemAvatar>
              {item.priceInformation.rubbishCosts === "inklusive" ? (
                <ListItemText primary={item.priceInformation.rubbishCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.priceInformation.rubbishCosts.split("/")[0]}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${
                        (10 - item.priceInformation.rubbishCosts.split("/")[0].length) * 8.5
                      }px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.priceInformation.rubbishCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
        </List>
      )}
    </Card>
  );
}

export default PriceInfoCard;
