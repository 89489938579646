import React, { useState, useContext, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Grid,
  Avatar,
  useMediaQuery,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon, Person as PersonIcon, Menu as MenuIcon } from "@material-ui/icons";


import { FirebaseAuth } from "services/firebase/Firebase";
import { getUserRole } from "rest/AdminRestClient";
import { AuthContext } from "context/Auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#fff",
      zIndex: theme.zIndex.drawer + 100,
    },
    appBarLogo: {
      height: 36,
    },
    googleBadge: {
      height: 44,
    },
    accButton: {
      marginRight: theme.spacing(2),
    },
    menuButton: {
      textDecoration: "none",
    },
    datenschutz: {
      "&:hover": {
        backgroundColor: "#fff",
        color: "#4CAF50",
      },
    },
  })
);

const AppBarMainImp2 = () => {
  const { currentUser } = useContext<any>(AuthContext);
  const matchesBig = useMediaQuery("(min-width:1100px)");
  const matches = useMediaQuery("(min-width:770px)");
  const history = useHistory();
  const classes = useStyles();

  const { t } = useTranslation();
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<String>("");
  const [secondRole, setSecondRole] = useState<String>("");
  const [user, setUser] = useState<any>({});
  const mopen = Boolean(anchorEl);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified === false) {
        history.push("/user/verify");
      } else {
        getUserRole("")
          .then((results) => {
            setSecondRole(results.data.secondRole);
            setRole(results.data.role);
            setUser(results.data.user);
          })
          .catch((error) => {
            FirebaseAuth.signOut()
              .then((_) => console.log("ok"))
              .catch((reason) => console.log(reason));
            console.info(error);
          });
      }
    }
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    return FirebaseAuth.signOut()
      .then((_) => history.push("/"))
      .catch((reason) => console.log(reason));
  };

  const admin = () => {
    return history.push({
      pathname: "/admin/parking_areal_list",
      state: { role: role },
    });
  };

  const myAccount = () => {
    return history.push({
      pathname: "/user/profile",
      state: { user: user },
    });
  };

  const datenschutz = () => {
    return history.push({
      pathname: "/help",
    });
  };

  const impressum = () => {
    return history.push({
      pathname: "/impressum",
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.root} elevation={2}>
        <Toolbar>
          <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
            {!matches && (
              <Grid item xs={2}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
            >
              <MenuItem onClick={impressum}>Impressum</MenuItem>
              <MenuItem onClick={datenschutz}>Datenschutz</MenuItem>
            </Menu>

            <Grid item sm={!matches ? 5 : 4}>
              <Grid container justifyContent={"flex-start"}>
                <Grid item sm={3}>
                  <Link to={"/b2b"} style={{ display: "flex" }}>
                    <img src="/assets/images/b2b.png" alt="Camp 24" className={classes.appBarLogo} />
                  </Link>
                </Grid>
                {matches && (
                  <>
                    <Grid item sm={4}>
                      <a href="https://play.google.com/store/apps/details?id=de.camp24checkin.pr">
                        <img src="/assets/images/google-play-badge.png" alt="Camp 24" className={classes.appBarLogo} />
                      </a>
                    </Grid>
                    {!matchesBig && <Grid item sm={1} />}
                    <Grid item sm={4}>
                      <a href="https://apps.apple.com/us/app/camp-24-check-in/id1477801769?uo=4">
                        <img src="/assets/images/appleBadge.svg" alt="Camp 24" className={classes.appBarLogo} />
                      </a>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item sm={2}>
              <Link to={"/"} style={{ display: "flex", justifyContent: "center" }}>
                <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
              </Link>
            </Grid>
            <Grid item sm={matches ? 4 : 2}>
              <Grid container spacing={0}>
                {matches && (
                  <>
                    <Grid
                      item
                      sm={7}
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                    >
                      <Link to={"/impressum"} className={classes.menuButton}>
                        <Button className={classes.datenschutz}>Impressum</Button>
                      </Link>
                      <Link to={"/help"} className={classes.menuButton}>
                        <Button className={classes.datenschutz}>Datenschutz</Button>
                      </Link>
                    </Grid>
                    <Grid item sm={1} />
                  </>
                )}
                <Grid item sm={4}>
                  {!!currentUser ? (
                    <div className={classes.accButton}>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="secondary"
                      >
                        {
                          //@ts-ignore
                          user !== undefined ? (
                            user.profileImage ? (
                              <Avatar alt={user.id} src={user.profileImage.url} />
                            ) : (
                              <PersonIcon />
                            )
                          ) : (
                            <PersonIcon />
                          )
                        }
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={mopen}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={myAccount}>{t("label_my_account")}</MenuItem>
                        {role === "owner" || role === "superAdmin" || secondRole === "CrmAgent" ? (
                          <MenuItem onClick={admin}>Admin</MenuItem>
                        ) : null}
                        <MenuItem onClick={signOut}>{t("label_log_out")}</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <Button
                      component={Link}
                      to={"/login"}
                      variant="contained"
                      color="primary"
                      startIcon={<ExitToAppIcon />}
                      disableElevation
                    >
                      Login
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export const AppBarMain = withTranslation()(AppBarMainImp2);
