import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

import styles from "./style";

export const Cookies: React.FC = () => {
  return (
    <CookieConsent
      buttonText="Ich stimme der Nutzung von Cookies ausdrücklich zu"
      declineButtonText="Cookies löschen und Ablehnen"
      cookieName="camp24checkin"
      style={styles.banner}
      contentStyle={styles.message}
      buttonStyle={styles.button}
      declineButtonStyle={styles.declineButton}
      buttonWrapperClasses="buttonDiv"
      flipButtons
      enableDeclineButton
      onDecline={() => {
        window.location.replace("http://www.google.com");
      }}
      expires={365}
      acceptOnScroll={true}
      acceptOnScrollPercentage={75}
      setDeclineCookie={false}
    >
      Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir
      Cookies. Dabei werden Daten, die Sie beim Besuch unserer Website hinterlassen gespeichert und unter
      Umstän0den an die in unserer Datenschutzerklärung erwähnten Dritten weitergegeben. Entscheiden Sie sich
      gegen die Verwendung von Cookies, kann dadurch die Funktionalität der Website eingeschränkt sein.{" "}
      <Link to={"/help"}>Weitere Informationen</Link>
    </CookieConsent>
  );
};
