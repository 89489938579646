import axios from "axios";
// import { AdminRestApiPath } from "shared/dist/path/AdminRestApiPath";
import { AdminRestApiPath } from "./AdminRestApiPath";
import { PublicRestApiPath } from "./PublicRestApiPath";
import { PrivateRestApiPath } from "./PrivateRestApiPath";
import { callConfigGet, callConfigPost, callConfigPut, callConfigDelete } from "./RequestOption";
// import {PrivateRestApiPath} from "shared/dist/path/PrivateRestApiPath";
// import {PublicRestApiPath} from "shared/dist/path/PublicRestApiPath";

const adminPathPrefix = "/api/admin/";
const privatePathPrefix = "/api/private/";
const publicPathPrefix = "/api/public/";

// ?-- ADMIN

export async function uploadParkingArealCsv(file: File) {
  const data = new FormData();
  data.set("file", file, file.name);
  return axios(
    await callConfigPut(adminPathPrefix + AdminRestApiPath.createTablePath, { dbPath: "/parking_areal" })
  ).then(async (value) =>
    axios(
      await callConfigPost(
        adminPathPrefix + AdminRestApiPath.importParkingArealPath,
        data,
        "multipart/form-data"
      )
    )
  );
}

export async function findParkingAreal(type?: string, searchTerm?: string, skip: number = 0) {
  let filter = type && searchTerm ? "&type=" + type + "&searchTerm=" + searchTerm : "";
  let callConfig = await callConfigGet(
    adminPathPrefix + AdminRestApiPath.parkingArealsPaginated + "?skip=" + skip + filter
  );
  return axios(callConfig);
}

export async function updateParkingAreal(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.updateParkingAreal, data);
  return axios(callConfig);
}

export async function updateParkingArealPartial(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.updateParkingArealPartial, data);
  return axios(callConfig);
}

export async function deleteSvgAndParkingspots(parkingArealId?: string) {
  let filter = parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.deleteSvg + filter);
  return axios(callConfig);
}

export async function findUser(value?: string, searchTerm?: string) {
  let filter = value && searchTerm ? "?value=" + value + "&searchTerm=" + searchTerm : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.adminUserCouch + filter);
  return axios(callConfig);
}

export async function updateUser(data?: any) {
  let id = data.id;
  let callConfig = await callConfigPost(
    adminPathPrefix + AdminRestApiPath.adminUserCouch + "?id=" + id,
    data
  );
  return axios(callConfig);
}

export async function getComment(id?: string, type?: string) {
  let filter = id && type ? "?id=" + id + "&type=" + type : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.adminCommentCouch + filter);
  return axios(callConfig);
}

export async function deleteComment(id?: string) {
  let filter = id ? "?commentid=" + id : "";
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.adminCommentCouch + filter);
  return axios(callConfig);
}

export async function getBooking(
  id: string,
  type: string,
  startDate: string,
  endDate: string,
  filterBy: string,
  numberPlate?: string
) {
  let filter =
    id && type && startDate && endDate && filterBy
      ? "?id=" +
        id +
        "&type=" +
        type +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&filterBy=" +
        filterBy +
        "&numberPlate=" +
        numberPlate
      : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.adminBooking + filter);
  return axios(callConfig);
}

export async function getImages() {
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.adminImage);
  return axios(callConfig);
}

export async function getProcessedImages(id?: string) {
  let filter = id ? "?parkingArealId=" + id : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.adminImage + filter);
  return axios(callConfig);
}

export async function getParkingSpots(startDate: string, endDate: string, userId: string, id: string) {
  let filter = "?start=" + startDate + "&end=" + endDate + "&userId=" + userId + "&parkingArealId=" + id;
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.parkingSpotsSvg + filter);
  return axios(callConfig);
}

export async function createBooking(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.adminBooking, data);
  return axios(callConfig);
}

export async function deleteBooking(id?: string) {
  let filter = id ? "?bookingId=" + id : "";
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.adminBooking + filter);
  return axios(callConfig);
}

export async function getIsBookingMade(parkingArealId: string) {
  let filter = parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.adminBookingCount + filter);
  return axios(callConfig);
}

export async function uploadParkingArealSVG(file: File, parkingArealId: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("parkingArealId", parkingArealId);
  return axios(
    await callConfigPost(adminPathPrefix + AdminRestApiPath.uploadSvg, data, "multipart/form-data")
  );
}

export async function approveImage(id: string, status: string) {
  let filter = id && status ? "?id=" + id + "&status=" + status : "";
  // console.log(filter);
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.adminImage + filter);
  return axios(callConfig);
}

export async function commentStatusChange(id: string, status: any) {
  let filter = id ? "?id=" + id + "&status=" + status : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.adminCommentCouch + filter);
  return axios(callConfig);
}

export async function createNewParkingAreal(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.createParkingAreal, data);
  return axios(callConfig);
}

export async function createNewParkingSpot(iter: any, id: any, ind: any) {
  let filter = id && iter && ind ? "?iter=" + iter + "&id=" + id + "&ind=" + ind : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.parkingSpot + filter);
  return axios(callConfig);
}

export async function getParkingSpot(parkingArealId?: any) {
  let filter = parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.parkingSpot + filter);
  return axios(callConfig);
}

export async function updateSpecificParkingSpot(data?: any) {
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.parkingSpot, data);
  return axios(callConfig);
}

export async function adminCreateBooking(userId: any) {
  let filter = userId ? "?userId=" + userId : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.bookingCreate + filter);
  return axios(callConfig);
}

export async function addRole(userId: any, parkArealId: any, type?: any) {
  // let filter = userId && parkArealId ? "?userId=" + userId + "&parkArealId=" + parkArealId: "";
  let filter =
    userId && parkArealId
      ? "?userId=" + userId + "&parkArealId=" + parkArealId
      : "?userId=" + userId + "&type=" + type;
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.role + filter);
  return axios(callConfig);
}

export async function addRemovePoint(parkArealId: any, point: any, plateNumber: any, process: String) {
  let filter =
    point && parkArealId && plateNumber && process
      ? "?point=" +
        point +
        "&parkingArealId=" +
        parkArealId +
        "&plateNumber=" +
        plateNumber +
        "&process=" +
        process
      : null;
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.point + filter);
  return axios(callConfig);
}

export async function getPoint(parkId: any) {
  let filter = "?parkingArealId=" + parkId;
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.point + filter);
  return axios(callConfig);
}

export async function removePoint(parkArealId: any, numberPlate: any) {
  let filter =
    numberPlate && parkArealId ? "?plateNumber=" + numberPlate + "&parkingArealId=" + parkArealId : null;
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.point + filter);
  return axios(callConfig);
}

export async function getPermanentEntrance(parkId: any, numberPlate?: any) {
  let filter =
    parkId && numberPlate
      ? "?parkingArealId=" + parkId + "&numberPlate=" + numberPlate
      : parkId
      ? "?parkingArealId=" + parkId
      : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.permanentEntrance + filter);
  return axios(callConfig);
}

export async function removePermanentEntrance(parkArealId: any, id: any) {
  let filter = id && parkArealId ? "?id=" + id + "&parkingArealId=" + parkArealId : null;
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.permanentEntrance + filter);
  return axios(callConfig);
}

export async function addPermanentEntrance(parkArealId: any, plateNumber: any) {
  let filter =
    parkArealId && plateNumber ? "?parkingArealId=" + parkArealId + "&numberPlate=" + plateNumber : null;
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.permanentEntrance + filter);
  return axios(callConfig);
}

export async function getRole(parkId: any, type: any = false) {
  let filter = parkId ? "?parkArealId=" + parkId : "?type=" + type;
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.role + filter);
  return axios(callConfig);
}

export async function getParkingArealReceipt(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.receipt + filter);
  return axios(callConfig);
}

export async function getParkingArealForm(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.forms + filter);
  return axios(callConfig);
}

export async function getParkingArealXMLForm(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.xmlforms + filter);
  return axios(callConfig);
}

export async function removeRole(userId: any, parkArealId: any, type?: string) {
  let filter =
    userId && parkArealId
      ? "?userId=" + userId + "&parkArealId=" + parkArealId
      : "?userId=" + userId + "&type=" + type;
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.role + filter);
  return axios(callConfig);
}

export async function getConfig(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.config + filter);
  return axios(callConfig);
}

export async function addConfig(data: any, parkingArealId: any) {
  let filter = data && parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.config + filter, data);
  return axios(callConfig);
}

export async function getPillars(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.pillars + filter);
  return axios(callConfig);
}

export async function updatePillars(data: any, parkingArealId: any) {
  let filter = data && parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.pillars + filter, data);
  return axios(callConfig);
}

export async function getPillarSpots(parkId: any) {
  let filter = parkId ? "?parkingArealId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.pillarSpots + filter);
  return axios(callConfig);
}

export async function updatePillarSpots(data: any, parkingArealId: any) {
  let filter = data && parkingArealId ? "?parkingArealId=" + parkingArealId : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.pillarSpots + filter, data);
  return axios(callConfig);
}

export async function travellerStatus(userId: any, index: any, status: any) {
  // console.log(userId)
  let filter = userId && index && status ? "?id=" + userId + "&index=" + index + "&status=" + status : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.adminUserTravellerStatus + filter);
  return axios(callConfig);
}

export async function petStatus(userId: any, pet: any) {
  let filter = userId && pet ? "?id=" + userId + "&pet=" + pet.toString() : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.adminUserPetStatus + filter);
  return axios(callConfig);
}

export async function getCrmLogs(id: string | boolean, type: string) {
  let filter = id && type ? "?id=" + id + "&type=" + type : "?type=" + type;
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.crmLog + filter);
  return axios(callConfig);
}

export async function createCrmLog(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.crmLog, data);
  return axios(callConfig);
}

export async function updateCrmLog(data: any, id: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.crmLog + filter, data);
  return axios(callConfig);
}

export async function deleteCrmLog(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.crmLog + filter);
  return axios(callConfig);
}

export async function createContact(data?: any) {
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.contact, data);
  return axios(callConfig);
}

export async function updateContact(data?: any) {
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.contact, data);
  return axios(callConfig);
}

export async function getContacts(type: any, id?: string | boolean, searchTerm?: any) {
  let filter =
    type && id && searchTerm
      ? "?type=" + type + "&parkId=" + id + "&searchTerm=" + searchTerm
      : type && id
      ? "?type=" + type + "&parkId=" + id
      : type && searchTerm
      ? "?type=" + type + "&searchTerm=" + searchTerm
      : type
      ? "?type=" + type
      : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.contact + filter);
  return axios(callConfig);
}

export async function getSalers() {
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.saler);
  return axios(callConfig);
}

export async function uploadCRMParkingArealFile(file: File, parkingArealId: string, name: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "file");
  data.set("parkingArealId", parkingArealId);
  data.set("name", name);
  return axios(await callConfigPost(adminPathPrefix + AdminRestApiPath.crmFile, data, "multipart/form-data"));
}

export async function getFiles(parkId: any) {
  let filter = parkId ? "?parkId=" + parkId : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.crmFile + filter);
  return axios(callConfig);
}

export async function uploadCRMContactImage(file: File, id: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("id", id);
  return axios(
    await callConfigPost(adminPathPrefix + AdminRestApiPath.contactImage, data, "multipart/form-data")
  );
}

export async function updateCrmSettings(email: any) {
  let data = email ? "?email=" + email : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.crmSettings + data);
  return axios(callConfig);
}

export async function getCrmSettings() {
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.crmSettings);
  return axios(callConfig);
}

export async function getAdminParkingSpotBookingPricesByDate(
  parkingArealId: any,
  parkingSpotId: any,
  startDate: any,
  endDate: any,
  userId: any
) {
  let filter =
    parkingArealId && parkingSpotId && startDate && endDate && userId
      ? "?parkingArealId=" +
        parkingArealId +
        "&parkingSpotId=" +
        parkingSpotId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&userId=" +
        userId
      : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.bookingPrice + filter);
  return axios(callConfig);
}

// Admin user edit

export async function getUserRoleAdmin(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userRole + filter);
  return axios(callConfig);
}

export async function getUserTravellersAdmin(id: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userTraveller + filter);
  // console.log(callConfig);
  return axios(callConfig);
}

export async function updateTravellerAdmin(data?: any, idx?: any) {
  let id = data.id;
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.userTravellerId + "/" + id, data);
  return axios(callConfig);
}

export async function createTravellerAdmin(data?: any, id?: any) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.userTraveller + filter, data);
  return axios(callConfig);
}

export async function deleteUserTravellerAdmin(id?: any, idx?: any) {
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.userTraveller + "/" + id + idx);
  return axios(callConfig);
}

export async function getVehicleAdmin(id?: any) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userVehicle + filter);
  return axios(callConfig);
}

export async function getUserProfileAdmin(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userProfile + filter);
  // console.log(callConfig);
  return axios(callConfig);
}

export async function createUserAdmin(email: string, password: string) {
  let filter = email && password ? "?email=" + email + "&password=" + password : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userCreate + filter);
  // console.log(callConfig);
  return axios(callConfig);
}

export async function updateUserProfileAdmin(data?: any, id?: string, category?: string, update?: string) {
  let filter = id && category ? "?id=" + id + "&category=" + category : "";

  if (update === "update") {
    filter = filter + "&update=true";
  }
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.userProfile + filter, data);
  return axios(callConfig);
}

export async function deleteVehicleAdmin(id?: any) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigDelete(adminPathPrefix + AdminRestApiPath.userVehicle + filter);
  return axios(callConfig);
}

export async function createVehicleAdmin(data?: any, id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPost(adminPathPrefix + AdminRestApiPath.userVehicle + filter, data);
  return axios(callConfig);
}

export async function updateVehicleAdmin(data?: any, id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPut(adminPathPrefix + AdminRestApiPath.userVehicle + filter, data);
  return axios(callConfig);
}

export async function getUserCategory(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(adminPathPrefix + AdminRestApiPath.userCategory + filter);
  return axios(callConfig);
}

// ?-- PUBLIC

export async function getParkingArealById(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.parkingAreal + filter);
  return axios(callConfig);
}

export async function fairMail(name: any, email: any) {
  let filter = name && email ? "?name=" + name + "&email=" + email : "";
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.mail + filter);
  return axios(callConfig);
}

export async function payBack(orderid: any, payerid: any) {
  let filter = orderid && payerid ? "?orderid=" + orderid + "&payerid=" + payerid : "";
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.payBack + filter);
  return axios(callConfig);
}

export async function getPublicSearch(params?: any) {
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.parkingArealSearch, params);
  return axios(callConfig);
}

export async function adminRegister(data?: any) {
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.user, data);
  return axios(callConfig);
}

export async function getCommentsOfParkingAreal(id?: string) {
  let callConfig = await callConfigGet(publicPathPrefix + `/parkingAreal/${id}/comments`);
  return axios(callConfig);
}

// comments duplicate check ???
export async function getCommentsParkingAreal(id: any) {
  let callConfig = await callConfigGet(
    publicPathPrefix + PublicRestApiPath.parkingAreal + "/" + id + "/comments"
  );
  return axios(callConfig);
}

export async function getCommentUser(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(
    publicPathPrefix + PublicRestApiPath.parkingArealCommentsUserCouch + filter
  );
  return axios(callConfig);
}

// ?-- PRIVATE

export async function createTraveller(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userTraveller, data);
  return axios(callConfig);
}

export async function deleteUserTraveller(id?: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userTraveller + "/" + id);
  return axios(callConfig);
}

export async function updateTraveller(data?: any) {
  let id = data.id;
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userTraveller + "/" + id, data);
  return axios(callConfig);
}

export async function updateUserProfile(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userProfile, data);
  return axios(callConfig);
}

export async function getVerifyCode(code: string) {
  let filter = code ? "/" + code : "";
  let callConfig = await callConfigGet(privatePathPrefix + "/user/sync/verify" + filter);
  return axios(callConfig);
}

export async function postVerifySync(code: string, bookingId: string) {
  let filter = code && bookingId ? "/" + code + "/" + bookingId : "";
  let callConfig = await callConfigPost(privatePathPrefix + "/user/sync/done/verify" + filter);
  return axios(callConfig);
}

export async function getParkingSpotsSVG(startDate: string, endDate: string, id: string) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  let filter = "?start=" + startDate + "&end=" + endDate + "&parkingArealId=" + id;
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.svgBooking + filter);
  return axios(callConfig);
}

export async function getParkingSpotsSVGMobile() {
  let callConfig = await callConfigGet(privatePathPrefix + "booking/reservation/default");
  return axios(callConfig);
}

export async function userCreateBooking(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.booking, data);
  return axios(callConfig);
}

export async function getUserRole(id?: string) {
  let filter = id ? "?userId=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userRole + filter);
  console.log(callConfig);
  return axios(callConfig);
}

export async function getUserProfile(id?: string) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userProfile);
  console.log(callConfig);
  return axios(callConfig);
}

export async function getUserTravellers() {
  // let filter = id  ? "?userId=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userTraveller);
  console.log(callConfig);
  return axios(callConfig);
}

export async function getPrivateParkingArealById(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.parkingAreal + filter);
  return axios(callConfig);
}

export async function createCommentUser(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.commentCouch, data);
  return axios(callConfig);
}

export async function uploadParkingArealImage(file: File, parkingArealId: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "parkingAreal");
  data.set("id", parkingArealId);
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadUserImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "user");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadTravellerImage(file: File, travellerId: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "travel");
  data.set("id", travellerId);
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadInfoImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "info");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadAdminImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "admin");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function getBookmarks() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarks);
  return axios(callConfig);
}

export async function deleteBookmarks(folderName: string, ParkingArealId: string) {
  let filter =
    folderName && ParkingArealId ? "?folderName=" + folderName + "&ParkingArealId=" + ParkingArealId : "";
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userBookmarks + filter);
  return axios(callConfig);
}

export async function changeBookmarksFolder(
  firstFolderName: string,
  ParkingArealId: string,
  secondFolderName: string
) {
  let filter =
    firstFolderName && ParkingArealId && secondFolderName
      ? "?firstFolderName=" +
        firstFolderName +
        "&ParkingArealId=" +
        ParkingArealId +
        "&secondFolderName=" +
        secondFolderName
      : "";
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmarks + filter);
  return axios(callConfig);
}

export async function deleteBookmarksFolder(folderName: string) {
  let filter = folderName ? "?folderName=" + folderName : "";
  let callConfig = await callConfigDelete(
    privatePathPrefix + PrivateRestApiPath.userBookmarksFolder + filter
  );
  return axios(callConfig);
}

export async function createBookmarksFolder(folderName: string) {
  let filter = folderName ? "?folderName=" + folderName : "";
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userBookmarksFolder + filter);
  return axios(callConfig);
}

export async function getCommentOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userCommentsCouch);
  return axios(callConfig);
}

export async function getBookingOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.booking);
  return axios(callConfig);
}

export async function getReceiptsOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userReceipt);
  return axios(callConfig);
}

export async function getBookingHistoryOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.bookingHistory);
  return axios(callConfig);
}

export async function bookingCommentUpdate(id: any) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.booking + filter);
  return axios(callConfig);
}

export async function getParkingSpotBookingPricesByDate(
  parkingArealId: any,
  parkingSpotId: any,
  startDate: any,
  endDate: any
) {
  let filter =
    parkingArealId && parkingSpotId && startDate && endDate
      ? "?parkingArealId=" +
        parkingArealId +
        "&parkingSpotId=" +
        parkingSpotId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
      : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.bookingPrice + filter);
  return axios(callConfig);
}

export async function getUserReceipt(bookingId: any) {
  let callConfig = await callConfigGet(privatePathPrefix + "booking/" + bookingId + "/receipt");
  return axios(callConfig);
}

export async function getVehicle(id?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userVehicle);
  return axios(callConfig);
}

export async function deleteVehicle(id?: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userVehicle);
  return axios(callConfig);
}

export async function createVehicle(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userVehicle, data);
  return axios(callConfig);
}

export async function updateVehicle(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userVehicle, data);
  return axios(callConfig);
}

export async function getBookmarksAll() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarkAll + "?web=true");
  return axios(callConfig);
}

export async function createBookmarkFolder(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmarkFolder, data);
  return axios(callConfig);
}

export async function deleteBookmarkFolder(id: any) {
  let callConfig = await callConfigDelete(
    privatePathPrefix + PrivateRestApiPath.userBookmarkFolder + "/" + id
  );
  return axios(callConfig);
}

export async function deleteBookmark(id: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userBookmark + "/" + id);
  return axios(callConfig);
}

export async function changeBookmarkFolder(id: any, data: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userBookmark + "/" + id, data);
  return axios(callConfig);
}

export async function getBookmarkFolderAll() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarkFolder);
  return axios(callConfig);
}

export async function createBookmark(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmark, data);
  return axios(callConfig);
}

export async function getUserFilters() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userFilter);
  return axios(callConfig);
}

export async function updateUserFilters(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userFilter, data);
  return axios(callConfig);
}

export async function getPrivateSearch(params?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.parkingArealSearch, params);
  return axios(callConfig);
}

export async function getUserOwn(params?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userOwn);
  return axios(callConfig);
}

export async function createComment(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userComment, data);
  return axios(callConfig);
}

export async function getCommentsUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userComment);
  return axios(callConfig);
}

export async function deleteCommentsUser(id: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userComment + "/id");
  return axios(callConfig);
}
