import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation } from "react-router";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useCreatePayBack } from "rest";
import useStyles from "./style";

const ReturnPage: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:959px)");

  const { mutate: createPayBack } = useCreatePayBack();

  useEffect(() => {
    let query = queryString.parse(location.search);

    createPayBack({ orderid: query.token, payerid: query.PayerID });
  }, []);

  return (
    <>
      <div className={matches ? classes.search : classes.searchMobile}>
        <Grid container spacing={5} className={matches ? classes.mainTitle : classes.mainTitleMobile}>
          <Grid item xs={12} md={12}>
            <Container>
              <div className={classes.searchForm}>
                <div className={matches ? classes.form : classes.formMobile}>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Typography variant="h5" color="primary">
                        Ihre Zahlung ist eingetroffen, danke!
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const Return = withTranslation()(ReturnPage);
