import type { CancelToken } from "axios";

import type {
  IUpdateBookingCommentParams,
  ICreateUserBookingParams,
  IFetchParkingSpotsSvgParams,
  IFetchParkingSpotBookingPricesByDateParams,
  IFetchUserReceiptParams,
  IDeleteUserBookingParams,
} from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  booking: "/private/booking",
  bookingHistory: "/private/booking/history",
  svgBooking: "/private/svgBooking",
  bookingPrice: "/private/bookingPrice",
  receipt: "/private/booking/:bookingId/receipt",
  userReceipt: "/private/booking/userReceipt",
  parkingSpotsSVGMobile: "/private/booking/reservation/default",
  adminDeleteBooking: "/admin/booking",
};

export class BookingApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api`) {
    super(baseURL);
  }

  async getBookingOfUser(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.booking, { cancelToken });

    return data;
  }

  async createUserBooking(params: ICreateUserBookingParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.booking, params, { cancelToken });

    return data;
  }

  async deleteUserBooking(params: IDeleteUserBookingParams, cancelToken?: CancelToken) {
    const filter = params.id ? "?bookingId=" + params.id : "";
    const { data } = await this.http.delete(URLS.adminDeleteBooking + filter, { cancelToken });

    return data;
  }

  async updateBookingComment(params: IUpdateBookingCommentParams, cancelToken?: CancelToken) {
    const filter = params.id ? "?id=" + params.id : "";
    const { data } = await this.http.post(URLS.booking + filter, { cancelToken });

    return data;
  }

  async getBookingHistoryOfUser(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.bookingHistory, { cancelToken });

    return data;
  }

  async getParkingSpotsSVG(params: IFetchParkingSpotsSvgParams, cancelToken?: CancelToken) {
    const filter = "?start=" + params.startDate + "&end=" + params.endDate + "&parkingArealId=" + params.id;
    const { data } = await this.http.get(URLS.svgBooking + filter, { cancelToken });

    return data;
  }

  async getParkingSpotBookingPricesByDate(
    params: IFetchParkingSpotBookingPricesByDateParams,
    cancelToken?: CancelToken
  ) {
    const { parkingArealId, parkingSpotId, startDate, endDate } = params;
    const filter =
      parkingArealId && parkingSpotId && startDate && endDate
        ? "?parkingArealId=" +
          parkingArealId +
          "&parkingSpotId=" +
          parkingSpotId +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
        : "";

    const { data } = await this.http.get(URLS.bookingPrice + filter, { cancelToken });

    return data;
  }

  async getUserReceipt(params: IFetchUserReceiptParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get("booking/" + params.bookingId + "/receipt", { cancelToken });

    return data;
  }

  async getReceiptsOfUser(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userReceipt, { cancelToken });

    return data;
  }

  async getParkingSpotsSVGMobile(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.parkingSpotsSVGMobile, { cancelToken });

    return data;
  }
}
