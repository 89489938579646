import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import {
  CssBaseline,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Collapse,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  CardHeader,
  Backdrop,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { IComment } from "interfaces/user";
import { IBooking } from "interfaces/booking";
import { CommentRow, BookingCommentRow } from "components/user/reviews";
import { deleteComment, getBookingOfUser, getCommentOfUser } from "rest/AdminRestClient";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    booking: {
      backgroundColor: "#DCE6A0",
      borderBottom: "5px solid white",
    },
    red: {
      backgroundColor: "#fad0c5",
      borderBottom: "5px solid white",
    },
    titlex: {
      backgroundColor: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserReviews: React.FC = () => {
  const classes = useStyles();

  const [getComments, setComments] = useState<IComment[]>([]);
  const [getBookings, setBookings] = useState<IBooking[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [RID, setRID] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedE, setCheckedE] = useState(false);

  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [getStars, setStars] = useState<any>([]);

  const d = new Date();
  const dx = d.setMonth(d.getMonth() - 3);

  const handleImageBackdropClose = () => {
    setOpenImageBackdrop(false);
  };
  const handleImageBackdropOpen = () => {
    setOpenImageBackdrop(true);
  };

  useEffect(() => {
    handleImageBackdropOpen();
    getCommentOfUser()
      .then((results) => {
        setComments(results.data);
      })
      .catch((error) => console.error(error));

    getBookingOfUser()
      .then((results: { data: IBooking[] }) => {
        const books = results.data
          .filter((item: any) => new Date(item.endDate).getTime() - new Date().getTime() < 0)
          .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
        setBookings(books);
        let arrResult: any = [];
        books.forEach(function (value) {
          arrResult[value.id] = 3;
        });
        setStars(arrResult);
        handleImageBackdropClose();
      })
      .catch((error: any) => console.error(error));
  }, []);

  const cDelete = (item: any) => {
    setDialogOpen(true);
    setRID(item.id);
  };

  const handleDelete = () => {
    setDialogOpen(false);
    deleteComment(RID)
      .then(() => {
        setChecked(true);
        setTimeout(() => {
          setChecked(false);
        }, 5000);
        getCommentOfUser()
          .then((results) => {
            setComments(results.data);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => {
        setCheckedE(true);
        setTimeout(() => setCheckedE(false), 5000);
      });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (getBookings != null && getComments != null) {
    return (
      <Fade in={true}>
        <>
          <CssBaseline />

          <Collapse in={checked}>
            <Alert severity="success">Your review has been deleted successfully</Alert>
          </Collapse>
          <Collapse in={checkedE}>
            <Alert severity="error">Your review has not been deleted successfully</Alert>
          </Collapse>

          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Do you want to delete your review?"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Card
            style={{ marginBottom: 18, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}
            id={"bookingpast"}
          >
            <CardHeader style={{ textAlign: "center" }} title="Ihre Meinung ist uns wichtig" />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%", paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "5%", paddingTop: "0px", paddingBottom: "0px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getBookings.map(
                    (item) =>
                      new Date(item.endDate).getTime() - new Date(dx).getTime() > 0 && (
                        <BookingCommentRow
                          key={item.id}
                          item={item}
                          getStars={getStars}
                          setStars={setStars}
                          setComments={setComments}
                          setBookings={setBookings}
                        />
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          <Card
            style={{ marginBottom: 18, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}
            id={"bookingpast"}
          >
            <CardHeader style={{ textAlign: "center" }} title="Ihre Bewertungen" />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%", paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "5%", paddingTop: "0px", paddingBottom: "0px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getComments.map((item) => (
                    <CommentRow item={item} key={item.id} cDelete={cDelete} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={openImageBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const Reviews = withTranslation()(UserReviews);
