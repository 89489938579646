/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Alert } from "@material-ui/lab";

import { FirebaseAuth } from "../../../services/firebase/Firebase";
import { AuthContext } from "../../../context/Auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBarLogo: {
    height: 56,
    marginBottom: "20px",
    marginTop: "10px",
  },
}));

const SignupPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerAlert, setRegisterAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [reason, setReason] = useState("");
  const { currentUser }: any = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, []);

  const registerAccount = () => {
    FirebaseAuth.createUserWithEmailAndPassword(email, password)
      .then((value) => {
        setRegisterAlert(true);
        setTimeout(() => {
          setRegisterAlert(false);
          history.push("/user/verify");
        }, 5000);

        return FirebaseAuth.currentUser!.sendEmailVerification();
      })
      .then((value) => console.log(value))
      .catch((reason) => {
        setReason(reason.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);

        if ("auth_email_already_in_use" === reason.code.replaceAll(/[\/-]/gi, "_")) {
          setTimeout(() => {
            history.goBack();
          }, 2000);
        }
        setTimeout(() => {
          setErrorAlert(false);
        }, 5000);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {registerAlert && (
          <Alert variant="filled" severity="success">
            {t("register_success")}
          </Alert>
        )}
        {errorAlert && (
          <Alert variant="filled" severity="warning">
            {t(reason)}
          </Alert>
        )}

        <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
        <form className={classes.form} noValidate>
          <TextField
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("label_email")}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("label_password")}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            onClick={registerAccount!}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("label_registration")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                {t("label_cancel")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export const SignUp = withTranslation()(SignupPage);
