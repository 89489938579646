import { Dialog, DialogContent, Typography, Button, DialogActions, useMediaQuery, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import SpotCard from "../SpotCard";
import { useParkingAreaDetailState } from "context/ParkingAreaDetail";

const useStyles = makeStyles(() =>
  createStyles({
    closestBakeryButtons: {
      marginBottom: 24,
      display: "flex",
      justifyContent: "space-evenly",
    },
  })
);

function TouristInfoModal({ item }) {
  const { closestBakery, setClosestBakery, infos, touristInfoTab, setInfos, setTouristInfoTab } =
    useParkingAreaDetailState();

  const classes = useStyles();
  const lighter = useMediaQuery("(min-width:630px)");

  const closeClosestBakery = () => {
    setClosestBakery(false);
  };

  const selected = (e) => {
    setInfos(
      item.info.filter(function (el) {
        return el.mode === e;
      })
    );

    setTouristInfoTab(e);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={closeClosestBakery}
      aria-labelledby="customized-dialog-title"
      open={closestBakery}
    >
      <DialogContent style={{ minHeight: "80vh", height: "80vh" }}>
        <Typography variant="h4" style={{ paddingBottom: "20px", color: "#0b429c" }}>
          Tourist-Info
        </Typography>

        {lighter ? (
          <div className={classes.closestBakeryButtons}>
            <Button
              variant="contained"
              color={touristInfoTab !== 1 ? "secondary" : "primary"}
              onClick={(e) => selected("1")}
            >
              Einkaufen
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 2 ? "secondary" : "primary"}
              onClick={(e) => selected("2")}
            >
              Sonstiges
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 3 ? "secondary" : "primary"}
              onClick={(e) => selected("3")}
            >
              Aktuelles
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 4 ? "secondary" : "primary"}
              onClick={(e) => selected("4")}
            >
              Restaurant
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 5 ? "secondary" : "primary"}
              onClick={(e) => selected("5")}
            >
              Gesundheit
            </Button>
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.closestBakeryButtons}>
              <Button
                variant="contained"
                color={touristInfoTab !== 1 ? "secondary" : "primary"}
                onClick={(e) => selected("1")}
              >
                Einkaufen
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 2 ? "secondary" : "primary"}
                onClick={(e) => selected("2")}
              >
                Sonstiges
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 3 ? "secondary" : "primary"}
                onClick={(e) => selected("3")}
              >
                Aktuelles
              </Button>
            </div>
            <div className={classes.closestBakeryButtons}>
              <Button
                variant="contained"
                color={touristInfoTab !== 4 ? "secondary" : "primary"}
                onClick={(e) => selected("4")}
              >
                Restaurant
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 5 ? "secondary" : "primary"}
                onClick={(e) => selected("5")}
              >
                Gesundheit
              </Button>
            </div>
          </React.Fragment>
        )}

        {item && infos.length > 0 ? (
          infos.map((item: any, index: number) => (
            <React.Fragment key={index + "b"}>
              <SpotCard item={item} />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <Typography component="h6" variant="h6">
              No Info Found in This Category
            </Typography>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeClosestBakery} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TouristInfoModal;
