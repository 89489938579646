/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import firebase from "firebase";
import { Button, SvgIcon, TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Alert } from "@material-ui/lab";

import { FirebaseAuth } from "../../../services/firebase/Firebase";
import { AuthContext } from "../../../context/Auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBarLogo: {
    height: 56,
    marginBottom: "20px",
    marginTop: "10px",
  },
}));

const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="googleIconA"
        d="M19.64,10.26a11.69,11.69,0,0,0-.16-1.9H10v3.77h5.41a4.62,4.62,0,0,1-2,3v2.51h3.23A9.81,9.81,0,0,0,19.64,10.26Z"
      />
      <path
        className="googleIconB"
        d="M10,20.06a9.54,9.54,0,0,0,6.63-2.43l-3.23-2.51a6,6,0,0,1-9-3.17H1.08v2.58A10,10,0,0,0,10,20.06Z"
      />
      <path
        className="googleIconC"
        d="M4.41,12A5.86,5.86,0,0,1,4.09,10a6,6,0,0,1,.32-1.91V5.53H1.08a9.91,9.91,0,0,0,0,9Z"
      />
      <path
        className="googleIconD"
        d="M10,4a5.44,5.44,0,0,1,3.85,1.51l2.86-2.86A9.57,9.57,0,0,0,10,0,10,10,0,0,0,1.08,5.53L4.41,8.12A6,6,0,0,1,10,4Z"
      />
    </SvgIcon>
  );
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Camp24CheckIn "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const LoginPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const { currentUser }: any = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        signIn();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  const signIn = () => {
    FirebaseAuth.signInWithEmailAndPassword(email, password)
      .then((_) => {
        localStorage.removeItem("ParkingArealSearchLocalStorage");
        localStorage.removeItem("ParkingArealSearchFilterLocalStorage");

        history.goBack();
      })
      .catch((reason) => {
        setReason(reason.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  const googleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    FirebaseAuth.signInWithPopup(provider)
      .then((result) => {
        localStorage.removeItem("ParkingArealSearchLocalStorage");
        localStorage.removeItem("ParkingArealSearchFilterLocalStorage");
        history.goBack();
      })
      .catch((error) => {
        setReason(error.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {errorAlert && (
          <Alert variant="filled" severity="error">
            {t(reason)}
          </Alert>
        )}
        <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
        <form className={classes.form} noValidate>
          <TextField
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("label_email")}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("label_password")}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button onClick={signIn!} fullWidth variant="contained" color="primary" className={classes.submit}>
            {t("label_login")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login/forget" variant="body2">
                {t("label_forgot_password")}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/login/signup" variant="body2">
                {t("label_registration")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <br />
      <Grid container justifyContent="center">
        <Grid item>
          <Button
            variant="outlined"
            component="span"
            disableElevation
            onClick={() => googleSignIn()}
            startIcon={<GoogleIcon />}
          >
            Sign In with Google
          </Button>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export const Login = withTranslation()(LoginPage);
