import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useVerifyCode } from "rest";
import { useProfile } from "context";

interface IFormInput {
  code: String;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContents: {
      width: "-webkit-fill-available",
      paddingBottom: "11px",
    },
  })
);

export const VerifyCodeDialog = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, getValues } = useForm<IFormInput>();

  const {
    setVerifyData,
    setActiveStep,
    setVerifyCode,
    verifyCodeStatus,
    setVerifyCodeStatus,
    setFullScreen,
    setChecked,
  } = useProfile();

  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState("");

  const { refetch: getVerifyCode } = useVerifyCode(
    {
      code: getValues("code") as string,
    },
    {
      enabled: false,
      onSuccess: (results) => {
        checkMessage(results);
        setVerifyData(results);
        if (results.vehicleMatch) {
          setActiveStep(1);
        }
        setVerifyCode(getValues("code") as string);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const onSubmitX: SubmitHandler<IFormInput> = () => {
    getVerifyCode();
  };

  const checkMessage = (data) => {
    switch (data.message) {
      case "Success!":
        setErrorText("Erfolg");
        setVerifyCodeStatus(false);
        setChecked(true);
        setTimeout(() => {
          setChecked(false);
        }, 5000);
        break;
      case "Error! Vehicle platenumber does not match":
        setErrorText("Fehler! Fahrzeugplattennummer stimmt nicht überein");
        setVerifyCodeStatus(false);
        setOpen(true);
        break;
      case "Error! No vehicle found":
        setErrorText("Fehler! Kein Fahrzeug gefunden");
        setOpen(true);
        setVerifyCodeStatus(false);
        break;
      case "Error! Phone Number belongs to another account.":
        setErrorText("Fehler! Telefonnummer gehört zu einem anderen Konto.");
        setOpen(true);
        setVerifyCodeStatus(false);
        break;
      case "Error! Invalid Code":
        setErrorText("Fehler! Ungültiger Code");
        setOpen(true);
        break;
      case "":
        setVerifyCodeStatus(false);
        setFullScreen(true);
        break;
      case "Error! There is a problem with travelers":
        setVerifyCodeStatus(false);
        setErrorText("Fehler! Kein Fahrzeug gefunden");
        setFullScreen(true);
        break;

      default:
        setErrorText("Fehler! Synchronisierung fehlgeschlagen");
        setOpen(true);
        setVerifyCodeStatus(false);
        break;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Achtung"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={verifyCodeStatus}
        onClose={() => setVerifyCodeStatus(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mobile Nummer überprüfen</DialogTitle>

        <form onSubmit={handleSubmit(onSubmitX)}>
          <DialogContentText style={{ marginLeft: "25px" }}>
            für die Synchronisierung der Buchung, die Sie gerade im Checkpoint haben, geben Sie bitte den Code
            ein, den Sie per SMS auf Ihr Mobiltelefon erhalten haben
          </DialogContentText>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  name="code"
                  variant="outlined"
                  label="Code"
                  required
                  error={errors.code ? true : false}
                  inputRef={register({
                    required: true,
                    pattern: /^[0-9]*$/i,
                    minLength: {
                      value: 6,
                      message: "min length is 6",
                    },
                  })}
                  className={classes.formContents}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVerifyCodeStatus(false)} color="primary">
              Abbrechen
            </Button>
            <Button variant="contained" color="primary" disableElevation type="submit">
              Überprüfen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
