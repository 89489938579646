import * as firebase from "firebase/app";

import "firebase/analytics";

import "firebase/auth";
import "firebase/firestore";

// const prod = false;
const prod = false;

const firebaseConfig = {
    apiKey: "AIzaSyD1pXHXMW01Ib9vzbn8ax86cMujTIcC6_U",
    authDomain: "camp24-5211b.firebaseapp.com",
    databaseURL: "https://camp24-5211b.firebaseio.com",
    projectId: "camp24-5211b",
    storageBucket: "camp24-5211b.appspot.com",
    messagingSenderId: "817524905002",
    appId: "1:817524905002:web:fdc41401efd43fdf7cdf53",
    measurementId: "G-2TZ2G8HBRK"
};

const firebaseConfigDev = {
    apiKey: "AIzaSyAqAcJ84-EQ4syUG8eWJfsNxmIzQGzmWgk",
    authDomain: "camp24-dev.firebaseapp.com",
    databaseURL: "https://camp24-dev.firebaseio.com",
    projectId: "camp24-dev",
    storageBucket: "camp24-dev.appspot.com",
    messagingSenderId: "873377620150",
    appId: "1:873377620150:web:9353b943189771e56dc617",
    measurementId: "G-4X01CCBE39"
};


if(prod) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.initializeApp(firebaseConfigDev);
}


export const FireStore = firebase.firestore();
export const FirebaseAuth = firebase.auth();