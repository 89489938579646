import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Grid,
  Chip,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { campCardServices, campCardActivities } from "constants/campCardItems";

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      minWidth: 320,
      height: 218,
    },
    facilitiesList: {
      marginTop: 8,
    },
    facilities: {
      marginRight: 4,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
    },
  })
);

export const MarkerCampCard = ({ item }) => {
  const classes = useStyles();
  return (
    <Card style={{ maxWidth: 350 }}>
      <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.id}`, state: item }}>
        <CardMedia
          className={classes.media}
          image={`/assets/images/placeholders/${item.lat.toString().slice(-1)}.jpg`}
          title={item.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Stadt: {item.address.area}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Anzahl Stellplätze: {item.generalInformation.amountOfSpots}
          </Typography>
          <div className={classes.facilitiesList}>
            {campCardServices.map((service) => {
              const hasProperty = item.properties[service.name];
              const src = service.source || service.name;

              return (
                !!hasProperty && (
                  <Tooltip title={service.title} placement="top" arrow>
                    <img
                      src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                      className={classes.facilities}
                      alt={service.title}
                    />
                  </Tooltip>
                )
              );
            })}

            <br />

            {campCardActivities.map((service) => {
              if (service.name === "br") {
                return <br />;
              }
              const hasProperty = item.properties[service.name];
              const src = service.source || service.name;

              return (
                <Tooltip title={service.title || service.name} placement="top" arrow>
                  <img
                    src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                    className={classes.facilities}
                    alt={service.title}
                  />
                </Tooltip>
              );
            })}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item sm={6}>
            <Chip
              variant="outlined"
              className={classes.chip}
              label={
                <section>
                  <div color="textSecondary">
                    PREIS: <span style={{ color: "#7ED321" }}>{item.priceInformation.priceForTwoAdults}</span>
                  </div>
                </section>
              }
            />
          </Grid>
          <Grid item sm={4} />
          <Grid item sm={2}></Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
