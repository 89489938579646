import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { InfoWindow, GoogleMap, Marker } from "@react-google-maps/api";

import {
  CssBaseline,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  makeStyles,
} from "@material-ui/core";

import { useBookingOfUser } from "rest/booking-service";
import { IBooking } from "interfaces/booking";
import { RowItem, MapCard } from "components/user/bookings";
import { useBookings } from "context";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  booking: {
    backgroundColor: "#DCE6A0",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
});

const UserBookings: React.FC<{ bookingPast?: boolean }> = ({ bookingPast }) => {
  const classes = useStyles();

  const [getBookings, setBookings] = useState<IBooking[]>([]);
  const [fade, setFade] = useState(false);
  const [close, setClose] = useState(false);
  const [markerMap, setMarkerMap] = useState<any>({});
  const [mapRef, setMapRef] = useState(null);

  const {
    zoom,
    setZoom,
    selectedPlace,
    setSelectedPlace,
    infoOpen,
    setInfoOpen,
    centerlng,
    setCenterlng,
    centerlat,
    setCenterlat,
  } = useBookings();

  useBookingOfUser({
    onSuccess: (results: Array<any>) => {
      const books = results
        ?.filter((item: any) => {
          if (bookingPast) {
            return new Date(item.endDate).getTime() - new Date().getTime() < 0;
          }
          return new Date(item.endDate).getTime() - new Date().getTime() > 0;
        })
        .sort((a, b) => {
          if (bookingPast) {
            return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
          }
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        });
      setBookings(books || []);
      setFade(true);
    },
    onError: (error: any) => console.error(error),
  });

  const loadHandler = (map: any) => {
    setMapRef(map);
    setZoom(5);
  };

  const markerLoadHandler = (marker: any, item: any) => {
    console.log("markerMap: ", marker);
    return setMarkerMap((prevState) => {
      return { ...prevState, [item.id]: marker };
    });
  };

  const centerChanged = (mapRef: any) => {
    setCenterlat(mapRef.getCenter().toJSON().lat);
    setCenterlng(mapRef.getCenter().toJSON().lng);
  };

  const markerClickHandler = (event: any, item: any) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    if (zoom < 13) {
      setZoom(10);
    }

    setCenterlat(item.lat);
    setCenterlng(item.lng);
  };

  useEffect(() => {
    close
      ? setInfoOpen((previousValue) => (previousValue === false ? !previousValue : previousValue))
      : setClose(!close);
  }, [infoOpen]);

  return (
    <>
      <CssBaseline />
      <Card style={{ marginBottom: 18 }} id={"bookingpast"}>
        <GoogleMap
          onLoad={loadHandler}
          onCenterChanged={() => centerChanged(mapRef)}
          center={{ lat: centerlat, lng: centerlng }}
          zoom={zoom}
          mapContainerStyle={{
            height: "70vh",
            width: "100%",
          }}
        >
          {getBookings?.map((item: any) => (
            <Marker
              key={item.id}
              position={{ lat: item.lat, lng: item.lon }}
              onLoad={(marker) => markerLoadHandler(marker, item)}
              onClick={(event) => markerClickHandler(event, item)}
              icon="/assets/images/marker/buchungen.png"
            />
          ))}
          {infoOpen && selectedPlace && (
            <InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setClose(true)}>
              <MapCard />
            </InfoWindow>
          )}
        </GoogleMap>
      </Card>
      <Fade in={fade}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell align="left">Stellplatz</TableCell>
                <TableCell align="right">Anreise</TableCell>
                <TableCell align="right">Abreise</TableCell>
                <TableCell align="right">Platz Nr</TableCell>
                <TableCell align="right">PIN</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getBookings?.map((item: any, index: number) =>
                bookingPast
                  ? new Date(item.endDate).getTime() - new Date().getTime() < 0 && (
                      <RowItem key={index} item={item} />
                    )
                  : new Date(item.endDate).getTime() - new Date().getTime() > 0 && (
                      <RowItem key={index} item={item} />
                    )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Fade>
    </>
  );
};

export const Bookings = withTranslation()(UserBookings);
