import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    label_email: "Email",
                    label_login: "Login",
                    label_registration: "Register",
                    label_forgot_password: "Forgot password?",
                    label_password: "Password",
                    label_search_term: "Search term",
                    label_search_by: "Search by",
                    label_street_and_house_number: "Street and house number",
                    label_country_code: "Country code",
                    label_amount_of_spots: "Amount of spots",
                    label_maximum_stay: "Maximum stay",
                    label_season: "Season",
                    label_name: "Name",
                    label_search: "Search",
                    label_area: "Area",
                    label_area_code: "Area code",
                    label_address: "Address",
                    label_resend_email_verification: "Resend verification email",
                    label_resend_text: "We have sent a confirmation link to your email address. To complete the process, just click the link in that email.",
                    label_admin_menu_parking_areal_list: "Parking areal",
                    label_admin_menu_home: "Home",
                    label_admin_menu_parking_areal_upload: "Upload",
                    label_admin_menu_comments_list: "Comments",
                    label_admin_menu_images_list: "Images",
                    label_admin_menu_users_list: "Users",
                    label_admin_menu_bookings_list: "Bookings",
                    label_properties: "Properties",
                    label_chemistry_wc: "Chemical toilet",
                    label_disposal: "Greywater disposal",
                    label_electricity: "Electricity",
                    label_pets: "Pets",
                    label_shower: "Shower",
                    label_water: "Water",
                    label_wifi: "Wifi",
                    label_price_information: "Price information",
                    label_electricity_costs: "Electricity cost",
                    label_pet_cost: "Pet cost",
                    label_price_child: "Price child",
                    label_price_extra_person: "Price extra person",
                    label_price_extra_child: "Price extra child",
                    label_price_two_adults: "Price two adults",
                    label_shower_costs: "Shower costs",
                    label_visitor_tax: "Visitor tax",
                    label_water_cost: "Water cost",
                    label_wifi_cost: "Wifi cost",
                    label_general_information: "General information",
                    label_detail_information: "Details",
                    label_lat: "Latitude",
                    label_lon: "Longitude",
                    label_phone: "Phone",
                    label_homepage: "Homepage",
                    add_new: "Add New",
                    label_cancel: "Cancel",
                    label_show_filters : "Show Filters",
                    label_show_map: "Show Map",
                    label_price_ascending: "Price: Ascending",
                    label_price_descending: "Price: Descending",
                    label_sort_distance: "Distance",
                    label_sort_by: "Sort By",
                    label_grad: "Grad",
                    label_minute: "Minute" ,
                    label_sekunden: "Sekunden",
                    label_toilet_cost: "WC:",
                    label_visitor_tax_kind: "Child Tax:",
                    label_visitor_tax_hund: "Dog Tax:",
                    label_chemistryWC: "Chemical Toilet:" ,
                    label_automat: "Auto Chemical Toilet:",
                    label_disposal_cost: "Disposal:",
                    label_rubbish: "Garbage:",
                    label_areaCode: "Area Code",
                    label_countryCode: "Country",
                    label_search_firstname: "First Namme",
                    label_search_lastname: "Last Name",
                    label_search_email: "Email",
                    label_search_phone: "Phone",
                    label_search_pl: "Search",
                    label_search_by_value: "Value",
                    label_booking_saved: 'Booking Has Been Saved Successfully',
                    label_booking_customer: 'Select Customer' ,
                    label_booking_select_dates: 'Select Dates and Size of Vehicle',
                    label_booking_select_spot: 'Select Parking Spot',
                    label_save_filters: 'Save Filters',
                    label_reset_filters: 'Reset Filters',
                    label_booking_info : 'Booking Info',
                    label_next : 'Next',
                    label_back : 'Back',
                    label_save : 'Reserve',
                    label_travellers_submitted: 'Travellers has been submitted successfully',
                    label_travellers_not_uploaded:'Travellers has not been uploaded successfully',
                    label_check_required:'Please check required fields.',
                    label_review_submitted: 'Your review has been submitted successfully',
                    label_my_account: 'My Account',
                    label_log_out: 'Log Out',
                    label_admin_menu_settings: 'Settings',
                    label_admin_menu_crm : 'CRM',
                    label_firstname :'First Name',
                    label_lastname :'Last Name',
                    label_parking_areal_name :'Parking Areal Name',
                    login_success: 'You have registered succesfully. Please Check your email and validate.',
                    register_success: 'You have registered succesfully. Please Check your email and validate. You can login now.',
                    auth_user_not_found: 'No user corresponding to this email',
                    auth_email_already_in_use: 'The email address is already in use',
                    auth_email_already_exists:  'The provided email is already in use by an existing user. Each user must have a unique email.',
                    auth_invalid_email: 'The provided value for the email user property is invalid. It must be a string email address.',
                    auth_weak_password : "Password should be at least 6 characters",
                    auth_wrong_password : 'Wrong password',
                    login_forget_password_success: 'Password reset link has been sent to your email.',
                    label_wrong_image_format_size_title : 'Wrong Image Format or Size',
                    label_wrong_image_format_or_size: 'Please check your image format and size. Only PNG and JPG files which are below 10 MB are supported.',
                    label_file_upload_success: 'The file has been uploaded successfully',
                    label_file_upload_fail: 'The file has not been uploaded successfully',
                    label_wrong_code: "Wrong verification code",
                    verification_code: "Verification Code",
                    please_register: "Please create account via web or mobile application for getting guest cards",
                    label_startDate: "Start Date",
                    label_endDate: "End Date",
                    label_canceled: "Canceled",
                    label_numberPlate: "Number Plate",
                    label_filter_by: "Filter By",
                    insufficient_point: "Dear customer, unfortunately there is currently a malfunction in the payment system. We are working with high pressure on the elimination of the problem. Until then, you must please buy points to operator of the pitch. Thank you for your understanding and have a nice Aufenhlt."
                }
            },
            de: {
                translations: {
                    label_email: "E-Mail",
                    label_login: "Anmelden",
                    label_registration: "Registrieren",
                    label_forgot_password: "Passwort vergessen?",
                    label_password: "Passwort",
                    label_search_term: "Suchbegriff",
                    label_search_by: "Suche nach",
                    label_address: "Adresse",
                    label_name: "Name",
                    label_amount_of_spots: "Anzal der Stellplätze",
                    label_maximum_stay: "Maximaler Aufenthalt",
                    label_season: "Saison",
                    label_street_and_house_number: "Strasse und Hausnummer",
                    label_country_code: "Land",
                    label_search: "Suche",
                    label_area: "Ort",
                    label_area_code: "PLZ",
                    label_properties: "Eigenschaften",
                    label_chemistry_wc: "Chemie Toiletten Entsorgung",
                    label_disposal: "Grauwasser_Entsorgung",
                    label_electricity: "Strom",
                    label_pets: "Haustiere",
                    label_shower: "Duschen",
                    label_water: "Wasser",
                    label_wifi: "Wifi",
                    label_price_information: "Preisinformationen",
                    label_electricity_costs: "Strom:",
                    label_pet_cost: "pro Hund:",
                    label_price_child: "pro Kind:",
                    label_price_extra_person: "weitere Personen:",
                    label_price_extra_child: "weitere Kinder:",
                    label_price_two_adults: "für 2 Personen:",
                    label_shower_costs: "Dusche:",
                    label_visitor_tax: "Kurtaxe pro Personen:",
                    label_water_cost: "Wasser:",
                    label_wifi_cost: "Wlan:",
                    label_detail_information: "Details",
                    label_lat: "Breitengrad",
                    label_lon: "Längengrad",
                    label_phone: "Telefon",
                    label_homepage: "Homepage",
                    label_general_information: "Generelle Informationen",
                    label_resend_email_verification: "Bestätigungsemail erneut versenden",
                    label_resend_text: "Wir haben einen Bestätigungslink an Ihre E-Mail-Adresse gesendet. Um den Vorgang abzuschließen, klicken Sie einfach auf den Link in dieser E-Mail.",
                    label_admin_menu_parking_areal_list: "Parkgebiete",
                    label_admin_menu_parking_areal_upload: "Hochladen",
                    label_cancel: "Abbrechen",
                    label_show_filters : "Filter anzeigen",
                    label_show_map: "Karte anzeigen",
                    label_price_ascending: "Preis aufsteigend",
                    label_price_descending: "Preis absteigend",
                    label_sort_distance: "Entfernung",
                    label_sort_by: "Sortieren",
                    label_grad: "Grad",
                    label_minute: "Minute" ,
                    label_sekunden: "Sekunden",
                    label_toilet_cost: "WC:",
                    label_visitor_tax_kind: "Kurtaxe pro Kind:",
                    label_visitor_tax_hund: "Kurtaxe pro Hund:",
                    label_chemistryWC: "Entsorgung:" ,
                    label_automat: "Entsorgungsautomat:",
                    label_disposal_cost: "Bodenentsorgung:",
                    label_rubbish: "Müllentsorgung:",
                    add_new: "Neu hinzufügen",
                    label_areaCode: "PLZ",
                    label_countryCode: "Land",
                    label_search_firstname: "Vorname",
                    label_search_lastname: "Nachname",
                    label_search_email: "E-Mail",
                    label_search_phone: "Mobilnummer",
                    label_search_pl: "Suchen",
                    label_search_by_value: "Suchbegriff eingeben",
                    label_booking_saved: 'Buchung wurde erfolgreich gespeichert!',
                    label_booking_customer: 'Benutzer/in wählen' ,
                    label_booking_select_dates: 'Datum und Fahrzeuglänge wählen',
                    label_booking_select_spot: 'Platz Nr. wählen',
                    label_save_filters: 'Filter Speichern',
                    label_reset_filters: 'Filter löschen',
                    label_booking_info : 'Buchungsinfo',
                    label_next : 'Weiter',
                    label_back : 'Zurück',
                    label_save : 'Reservieren',
                    label_travellers_submitted: 'Reisende wurden erfolgreich eingereicht',
                    label_travellers_not_uploaded:'Travellers has not been uploaded successfully',
                    label_check_required:'Bitte überprüfen Sie die Pflichtfelder.',
                    label_review_submitted:'Ihre Bewertung wurde erfolgreich eingereicht',
                    label_my_account: 'Mein Konto',
                    label_log_out: 'Abmelden',
                    label_admin_menu_settings: 'Einstellungen',
                    label_admin_menu_crm :'CRM',
                    label_firstname :'Vorname',
                    label_lastname :'Nachname',
                    label_parking_areal_name :'Stellplatzname',
                    login_success: 'Sie haben sich erfolgreich registriert. Bitte prüfen Sie Ihre E-Mail und bestätigen Sie.',
                    register_success: 'Sie haben sich erfolgreich registriert. Bitte prüfen Sie Ihre E-Mail und bestätigen Sie. Sie können sich jetzt anmelden.',
                    auth_user_not_found: 'Kein Benutzer, der dieser E-Mail entspricht',
                    auth_email_already_in_use: 'Die E-Mail-Adresse ist bereits in Gebrauch',
                    auth_email_already_exists:  'Die angegebene E-Mail wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine eindeutige E-Mail haben',
                    auth_invalid_email: 'Der angegebene Wert für die Benutzereigenschaft email ist ungültig. Es muss eine String-E-Mail-Adresse sein.',
                    auth_weak_password : "Das Passwort sollte mindestens 6 Zeichen lang sein",
                    auth_wrong_password : 'Falsches Passwort',
                    login_forget_password_success: 'Der Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet.',
                    label_file_upload_success: 'Das Bild wurde erfolgreich hochgeladen',
                    label_file_upload_fail: 'Das Bild wurde nicht erfolgreich hochgeladen',
                    label_wrong_image_format_size_title : 'Falsches Bildformat oder falsche Größe',
                    label_wrong_image_format_or_size: ' Bitte überprüfen Sie Ihr Bildformat und -größe. Es werden nur PNG- und JPG-Dateien unterstützt, die weniger als 10 MB groß sind.',
                    label_wrong_code: "falscher Verifizierungscode",
                    verification_code: "Verifizierungscode",
                    please_register: "Bitte erstellen Sie ein Konto über das Internet oder die mobile Anwendung, um Gästekarten zu erhalten.",
                    label_startDate: "Anreise",
                    label_endDate: "Abreise",
                    label_canceled: "Storniert",
                    label_numberPlate: "Kennzeichen",
                    label_filter_by: "filtern nach",
                    insufficient_point: "Lieber Kunde leider gibt es aktuell eine Störung am Zahlsystem. Wir arbeiten mit hochdruck an der Behebung des Problems. Bis dahin müssen Sie bitte Punkte kaufen bis Betreiber des Stellplatzes. Vielen Dank für Ihr Verständnis und schönen Aufenhlt."
                }
            }
        },
        lng: "de",
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;