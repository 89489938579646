import { SvgIcon } from "@material-ui/core";

export const PdfIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.3,11c-1.2-0.3-2.3-0.4-3.3-0.4C10.3,10,9.5,9.1,8.9,7.9C9.5,6,9.6,4.6,9.2,3.7C8.9,3.1,8.5,3,8.4,2.9l0,0l0,0
              C7.9,2.9,7.6,3,7.5,3.2C6.8,3.9,7.1,5.6,7.1,6l0,0c0.2,0.7,0.5,1.4,0.8,2c-0.5,1.3-1,2.4-1.6,3.4C5,12,4.1,12.5,4.1,12.5
              c-0.2,0.1-1.5,1.1-1.7,2.1c0,0.3,0.1,0.6,0.3,0.9C3,15.9,3.4,16,3.6,16c0,0,0,0,0,0c0.7,0,1.3-0.7,1.3-0.7c0.1-0.1,1-1.3,2-3.1
              c0.1,0,0.2-0.1,0.3-0.1c0.9-0.3,2-0.5,3.4-0.6c1.3,1.2,2.5,1.7,2.6,1.7l0,0c0.1,0,0.9,0.3,1.5,0c0.3-0.1,0.5-0.4,0.5-0.7
              c0.1-0.4,0-0.7-0.1-0.9C15,11.2,14.4,11,14.3,11z M4.3,14.7c-0.1,0.1-0.4,0.4-0.7,0.4c-0.1,0-0.1,0-0.3-0.1
              c-0.1-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.8-1.2,1.3-1.5c0,0,0.4-0.3,1.1-0.6C4.9,13.9,4.3,14.7,4.3,14.7z M8.1,3.8
              C8.1,3.8,8.1,3.8,8.1,3.8c0.4,0.1,0.8,0.9,0.2,3C8.2,6.5,8.1,6.1,8,5.8C7.9,5.1,7.8,4.1,8.1,3.8z M7.5,11.1c0.3-0.6,0.7-1.4,1-2.1
              c0.4,0.7,0.9,1.2,1.3,1.7C9,10.8,8.2,10.9,7.5,11.1z M14.5,12.3c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.1-0.6,0-0.8,0
              c-0.1-0.1-0.7-0.4-1.5-0.9c0.6,0,1.3,0.1,2.1,0.3c0.1,0,0.3,0.1,0.4,0.2C14.5,12.1,14.6,12.2,14.5,12.3z"
      />
      <path
        d="M16.8,5.3c-0.2-0.2-3.4-3.5-4.2-4.2c-0.9-0.8-2.5-0.8-2.7-0.8H2c-0.7,0-1.2,0.2-1.5,0.6C0,1.3,0,1.9,0,2v20.5
              c0,0.6,0.3,1,0.5,1.2c0.4,0.4,1,0.4,1.3,0.4c0,0,0.1,0,0.1,0h14.6c0.4,0,0.8-0.1,1.1-0.4c0.5-0.6,0.5-1.4,0.5-1.6
              c0-0.3,0-12.2,0-13.9C18,6.4,16.9,5.4,16.8,5.3z M12,1.6C12,1.6,12,1.6,12,1.6c0.8,0.7,4.2,4.2,4.2,4.2l0,0c0,0,0.1,0.1,0.2,0.3
              h-3.6c-0.7,0-0.8-0.7-0.8-0.9V1.6z M17.1,22.2c0,0.2,0,0.7-0.3,0.9c-0.1,0.1-0.2,0.2-0.4,0.2H1.8l0,0c0,0-0.4,0-0.7-0.2
              c-0.1-0.1-0.2-0.3-0.2-0.6V2l0,0l0,0c0,0,0-0.4,0.3-0.7C1.3,1.2,1.6,1.1,2,1.1h7.9l0,0c0.2,0,0.7,0,1.2,0.1v4.1l0,0
              c0,0,0,0.4,0.2,0.8C11.7,6.7,12.2,7,12.8,7h4.1c0.1,0.4,0.2,0.8,0.2,1.3C17.2,10.1,17.1,22.1,17.1,22.2L17.1,22.2z"
      />
      <path
        d="M6.1,17.2c0,0-1.2,0-1.7,0c-0.4,0-0.4,0.3-0.4,0.3s0,3,0,3.4c0,0.4,0.4,0.4,0.4,0.4c0.4,0,0.4-0.3,0.4-0.3v-1.3h1.1
              c0,0,1.2,0,1.2-1.2C7.1,17.3,6.1,17.2,6.1,17.2z M5.9,19l-1,0l0-1.2h1c0,0,0.4,0,0.4,0.6S5.9,19,5.9,19z"
      />
      <path
        d="M9.7,17.2c0,0-1.2,0-1.6,0s-0.4,0.4-0.4,0.4s0,3,0,3.3s0.3,0.3,0.3,0.3h1.7c0,0,1.3,0,1.3-2C11.2,17.2,9.7,17.2,9.7,17.2z
               M9.7,20.6H8.6v-2.7l1,0c0,0,0.8,0.1,0.8,1.4S9.7,20.6,9.7,20.6z"
      />
      <path
        d="M14.4,17.2c0,0-1.8,0-2.2,0s-0.3,0.4-0.3,0.4l0,3.3c0,0,0,0.3,0.4,0.3s0.4-0.3,0.4-0.3v-1.5h1.5c0,0,0.2,0,0.2-0.3
              s-0.2-0.3-0.2-0.3h-1.5v-1.1h1.8c0,0,0.3,0,0.3-0.3S14.4,17.2,14.4,17.2z"
      />
      <path
        d="M24,3.6c-0.1-0.1-0.2-0.2-0.3-0.2l-1.1,0V0.6c0-0.2-0.2-0.4-0.4-0.4h-3.1c-0.2,0-0.4,0.2-0.4,0.4v2.9l-1.1,0
              c-0.1,0-0.3,0.1-0.3,0.2s0,0.3,0.1,0.4l3,3.3c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l3-3.3C24,3.9,24,3.8,24,3.6z M20.7,6.6
              l-2.2-2.5l0.6,0c0.2,0,0.3-0.2,0.3-0.4V0.9h2.4v2.8c0,0.2,0.2,0.3,0.3,0.4l0.6,0L20.7,6.6z"
      />
    </SvgIcon>
  );
};
