import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Grid,
  Chip,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import { Link } from "react-router-dom";
import { campCardActivities, campCardServices } from "constants/campCardItems";
import { useHomeState } from "context/Home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 220,
    },
    facilitiesList: {
      marginTop: 8,
    },
    facilities: {
      marginRight: 2,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
      backgroundColor: "#F7F9FB",
      borderRadius: "4px",
      border: "1px   dotted #e4e4e4",
    },
  })
);

export function CampCard({ item, openMapAndCard }) {
  const classes = useStyles();
  const {getStartDate, getEndDate} = useHomeState();
  return (
    <Card>
      <CardActionArea
        component={Link}
        to={{
          pathname: `/parkingAreal/${item.id}`,
          state: {
            ...item,
            ...{
              startDate: getStartDate != null ? getStartDate.toISOString() : null,
              endDate: getEndDate != null ? getEndDate.toISOString() : null,
            },
          },
        }}
      >
        <CardMedia
          className={classes.media}
          image={
            item.image
              ? item.image.length !== ""
                ? item.image
                : `/assets/images/placeholders/${item.id.charCodeAt(6) % 18}.jpg`
              : `/assets/images/placeholders/${item.id.charCodeAt(6) % 18}.jpg`
          }
          title={item.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={{ height: "58px" }}>
            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Entfernung: {item.distance} km
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Stadt: {item.area}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Anzahl Stellplätze: {item.amountOfSpots}
          </Typography>
          <div className={classes.facilitiesList}>
            {campCardServices.map((service, index) => {
              const hasProperty = item[service.name];
              const src = service.source || service.name;

              return (
                !!hasProperty && (
                  <Tooltip key={index} title={service.title} placement="top" arrow>
                    <img
                      src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                      className={classes.facilities}
                      alt={service.title}
                    />
                  </Tooltip>
                )
              );
            })}

            <br />

            {campCardActivities.map((service, index) => {
              if (service.name === "br") {
                return <br key={index} />;
              }
              const hasProperty = item[service.name];
              const src = service.source || service.name;

              return (
                <Tooltip key={index} title={service.title || service.name} placement="top" arrow>
                  <img
                    src={`/assets/images/facilities24/${src}${hasProperty ? "-green" : ""}.png`}
                    className={classes.facilities}
                    alt={service.title}
                  />
                </Tooltip>
              );
            })}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item xs={6} sm={6}>
            <Chip
              variant="outlined"
              className={classes.chip}
              label={
                <section>
                  <div color="textSecondary">
                    PREIS:{" "}
                    <span style={{ color: "#d32121" }}>
                      <span>ab </span>
                      {`${parseFloat(item.priceUI).toFixed(2).replace(".", ",")} €`}
                    </span>
                  </div>
                </section>
              }
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Rating
              size="medium"
              name="mainItem"
              value={item.ratio ? item.ratio : 0}
              readOnly
              precision={0.5}
              style={{ paddingTop: "6px", marginLeft: "-13px" }}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <Tooltip title="Auf Der Karte" placement="bottom" arrow>
              <Chip
                variant="outlined"
                className={classes.chip}
                label={
                  <section>
                    <IconButton
                      color="primary"
                      onClick={(event) => {
                        openMapAndCard(event, item);
                      }}
                      aria-label="add to shopping cart"
                      style={{ padding: "inherit" }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </section>
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

