/***
 * Endpoint paths of the public rest api
 */
export abstract class PrivateRestApiPath {
    //Comment
    public static commentWithId = "/comment/:commentId";
    public static commentCouch = "/commentCouch";
    public static commentWithIdCouch = "/commentCouch/:commentIdCouch";
    public static userCommentsCouch = "/user/commentsCouch";
    public static userComment = "/user/comment";
    public static userCommentWithId = "/user/comment/:commentId";
    // public static comment = "/commentCouch";
    //User
    public static user = "/user";
    public static userProfile = "/user/profile";
    public static userPetStatus = "/user/pet";
    public static userRole = "/userRole";
    public static userOwn = "/user/own";
    public static userVerify = "/user/sync/verify/:code";
    public static userVerifyDone = "/user/sync/done/:code/:bookingId";


    //Booking
    public static booking = "/booking";
    public static bookingHistory = "/booking/history";
    public static svgBooking = "/svgBooking";
    public static svgMobileBooking = "/booking/svg";
    public static svgMobileBookingSelected = "/booking/svg/:parkingSpotId";
    public static bookingPriceMobile = "/booking/price";
    public static bookingMobile = "/booking/reserve";
    public static svgMobileBookingURL = "/booking/reservation/:parkingSpotId";
    public static bookingPrice = "/bookingPrice";
    public static checkout = "/booking/:bookingId/checkout";
    public static receipt = "/booking/:bookingId/receipt";
    public static userReceipt = "/booking/userReceipt";
    //Upload
    public static upload = "/upload";
    //Search
    public static parkingArealSearch = "/parkingArealSearch";
    public static parkingAreal = "/parkingAreal";
    //Bookmarks - old
    public static userBookmarks = "/user/bookmarks";
    public static userBookmarksFolder = "/user/bookmarks/folder";
    //Vehicle
    public static userVehicle = "/user/vehicle";
    public static userPendant = "/user/pendant";
    //Traveller
    public static userTraveller = "/user/travellers";
    public static userTravellerId = "/user/travellers/:travellerId";
    public static userTravellerStatus = "/user/traveller";
    //Bookmark
    public static userBookmark = "/user/bookmark";
    public static userBookmarkId = "/user/bookmark/:bookmarkId";
    public static userBookmarkAll = "/user/bookmark/all";
    public static userBookmarkFolder = "/user/bookmark/folder";
    public static userBookmarkFolderId = "/user/bookmark/folder/:bookmarkFolderId";
    //Filter
    public static userFilter = "/user/filter";

    //idle ?
    public static parkingSpots = "/parkingSpots";
    public static firebaseRegister = "/firebaseRegister";
    public static uservehiclePendant = "/user/vehiclePendant";
    public static userVehicleId = "/user/vehicle/:vehicleId";
}