import React from "react";
import { Card, List, ListSubheader, CardContent, Button, Typography } from "@material-ui/core";

import { useUploadParkingArealImage } from "rest";
import { useParkingAreaDetailState } from "context/ParkingAreaDetail";

function UploadImage({ id, setDialogOpenPhoto, setOpenImageBackdrop }) {
  const { setUploadSuccess, setUploadError } = useParkingAreaDetailState();

  const { mutate: uploadParkingArealImage } = useUploadParkingArealImage({
    onSuccess: () => {
      setOpenImageBackdrop(false);

      setUploadSuccess(true);
      setTimeout(() => {
        setUploadSuccess(false);
      }, 5000);
    },
    onError: (error) => {
      setOpenImageBackdrop(false);
      console.error(error);
      setUploadError(true);
      setTimeout(() => {
        setUploadError(false);
      }, 5000);
    },
  });

  const handleChange = (files: FileList | null) => {
    //@ts-ignore
    if (files && files.item(0)) {
      //@ts-ignore
      const type = files.item(0).type;
      const arr = ["image/png", "image/jpg", "image/jpeg"];
      //@ts-ignore
      if (!arr.includes(type)) {
        setDialogOpenPhoto(true);
        //@ts-ignore
      } else if (files.item(0).size > 10222181) {
        setDialogOpenPhoto(true);
      } else {
        setOpenImageBackdrop(true);
        uploadParkingArealImage({
          parkingArealId: id,
          file: files.item(0) as File,
        });
      }
    }
  };

  return (
    <Card>
      <List dense={true} subheader={<ListSubheader color="primary">Bilder hochladen</ListSubheader>}></List>
      <CardContent>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={(event: any) => handleChange(event.target.files)}
        />
        <Typography gutterBottom variant="h6" component="h4" color="primary" align="center">
          Sie waren hier schon? Super, wenn Sie auch ein Bild haben können Sie es hier hochladen.
        </Typography>

        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disableElevation
            style={{ width: "100%" }}
          >
            hier hochladen
          </Button>
        </label>
      </CardContent>
    </Card>
  );
}

export default UploadImage;
