import type { CancelToken } from "axios";

import type { ICreateCommentUserParams } from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  commentCouch: "/commentCouch",
  userCommentsCouch: "/user/commentsCouch",
};

export class CommentApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private`) {
    super(baseURL);
  }

  async getCommentOfUser(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userCommentsCouch, { cancelToken });

    return data;
  }

  async createCommentUser(params: ICreateCommentUserParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.commentCouch, params, { cancelToken });

    return data;
  }
}
