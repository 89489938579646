import { Card, CardMedia, CardContent, Typography, createStyles, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closestBakery: {
      width: "100%",
      height: 200,
      marginBottom: 24,
      display: "flex",
    },
    closestBakeryDetails: {
      display: "flex",
      flexDirection: "column",
    },
    closestBakeryContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    closestBakeryCover: {
      minWidth: 300,
      objectFit: "cover",
    },
  })
);

function SpotCard({ item }) {
  const lighter = useMediaQuery("(min-width:630px)");
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.closestBakery} elevation={lighter ? 0 : 1}>
        {lighter && (
          <CardMedia className={classes.closestBakeryCover} image={item.data.url} title={item.data.name} />
        )}
        <div className={classes.closestBakeryDetails}>
          <CardContent className={classes.closestBakeryContent}>
            <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: "-12px" }}>
              {item.data.hours}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: "-8px" }}>
              Adresse: {" " + item.data.address}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: "-9px" }}>
              Entfernung: {" " + item.data.distance}
            </Typography>
            <Typography component="h5" variant="h5" style={{ marginBottom: "6px" }}>
              {item.data.name}
            </Typography>
            <Typography variant="body2" component="p" style={{ marginTop: "-4px" }}>
              {item.data.description}
            </Typography>
          </CardContent>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default SpotCard;
