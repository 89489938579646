import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";

import {
  CssBaseline,
  Avatar,
  Button,
  CircularProgress,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IBooking } from "interfaces/booking";
import { useReceiptsOfUser, useUserReceipt } from "rest/booking-service";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  grey: {
    backgroundColor: "#ededed",
    borderBottom: "5px solid white",
  },
  greysecond: {
    backgroundColor: "#f6f6f6",
    borderBottom: "5px solid white",
  },
  pdf: {
    height: "36px",
    width: "36px",
  },
});

const RechnungenImpl: React.FC = () => {
  const classes = useStyles();
  const [pdf, setPdf] = useState("");
  const [getBookings, setBookings] = useState<IBooking[]>([]);
  const [fade, setFade] = useState(false);

  useReceiptsOfUser({
    onSuccess: (results: IBooking[]) => {
      const books = results?.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());
      const filteredBooks = books.filter((item) => item.paymentStatus === "paid");
      setBookings(filteredBooks);
      setFade(true);
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  const { refetch: getUserReceipt } = useUserReceipt(
    {
      bookingId: "",
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        setPdf(data);
      },
    }
  );

  const getPdf = (bookingId: any) => {
    getUserReceipt(bookingId);
  };

  const row = (item: any, index: number) => {
    return (
      <TableRow key={index} className={index % 2 === 0 ? classes.grey : classes.greysecond}>
        <TableCell component="th" scope="row">
          <Avatar variant="square" alt={"pdf"} src={`/assets/images/pdf36.png`} className={classes.pdf} />
        </TableCell>
        <TableCell align="left">{new Date(item.startDate).toLocaleDateString("de-DE")}</TableCell>
        <TableCell align="left">
          <Button variant="contained" disableElevation onClick={() => getPdf(item.id)}>
            Rechnungen
          </Button>
        </TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <CssBaseline />
      <Fade in={fade}>
        <Paper elevation={0} className={classes.root}>
          <div style={{ padding: 20 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>{getBookings.map((item: any, index: number) => row(item, index))}</TableBody>
            </Table>
          </div>
          {pdf !== "" && (
            <Document
              file={`data:application/pdf;base64,${pdf}`}
              options={{
                cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                cMapPacked: true,
              }}
              loading={<CircularProgress />}
            >
              <Page pageNumber={1} />
            </Document>
          )}
        </Paper>
      </Fade>
    </>
  );
};

export const Rechnungen = withTranslation()(RechnungenImpl);
